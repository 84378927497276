import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { eventsAtom } from "../../Jotai/index";
import * as XLSX from 'xlsx';

const EventsFetcher = () => {
    const [, setEvents] = useAtom(eventsAtom);

    const parseDuration = (durationString) => {
        const match = durationString.match(/^(\d+)\s+Hour/i);
        return match ? parseInt(match[1], 10) : 0; // Returns duration in hours
    };

    const getCategoryColor = (category) => {
        const categoryColors = {
            'Student - Music': '#9D7538',
            'Music Rotation': '#4E90F4',
            'Student - Talk': '#B3370B',
            'Student - Talk - Sports': '#568476',
        };
        return categoryColors[category] || '#054436'; // Default color if category not found
    };

    const getRemainingMonthDateRange = () => {
        const currentDate = new Date();
        var first = currentDate.getDate() - currentDate.getDay(); // First day is the day of the month - the day of the week
        var last = first + 14; 
        var startOfWeek = new Date(currentDate.setDate(first)).toUTCString();
        var endOfMonth = new Date(currentDate.setDate(last)).toUTCString();
        const startOfWeek2 = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() - 6)); // Adjusted for Saturday as the start of the week
console.log("11111",startOfWeek,endOfMonth)
        // const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        return { startOfWeek, endOfMonth };
    };

    useEffect(() => {
        const readExcelFile = async () => {
            const filePath = '/Online Schedule For Website.xlsx';
            const { startOfWeek, endOfMonth } = getRemainingMonthDateRange();
           var startOfWeek2 = new Date(startOfWeek);
            
            fetch(filePath)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.arrayBuffer();
                })
                .then(buffer => {
                    const workbook = XLSX.read(buffer, { type: 'buffer' });
                    const eventsTemp = [];
                    console.log('qqqqq', startOfWeek2.getDate());
                    for (var day = startOfWeek2.getDate(); day <  startOfWeek2.getDate()+14; day++) {
                        const baseDate = new Date(startOfWeek2.getFullYear(), startOfWeek2.getMonth(), day);
                        console.log("baseDate",baseDate);
                        const dayOfWeek = baseDate.getDay();
                        const sheetName = workbook.SheetNames[dayOfWeek];
                        const worksheet = workbook.Sheets[sheetName];
                        const data = XLSX.utils.sheet_to_json(worksheet);
                        let savePreviousEvent = ''
                        data.forEach((row, eventIndex) => {
                            if (!row['Duration:']) {
                                return;
                            }
                            const eventBaseDate = new Date(baseDate);
                            eventBaseDate.setHours(eventIndex, 0, 0, 0);

                            const durationHours = row['Duration:'] ? parseDuration(row['Duration:']) : 1;
                            const endTime = new Date(eventBaseDate.getTime() + durationHours * 60 * 60 * 1000);

                            // Check if the event is continuous with the previous event
                            // const lastEvent = eventsTemp[eventsTemp.length - 1];
                            if (savePreviousEvent === row['Show Name:']) {
                                // Skipping this as we dont want duplicate events
                            } else {
                                // Create a new event
                                eventsTemp.push({
                                    event_id: eventsTemp.length + 1,
                                    title: row['Show Name:'] || 'Untitled Event',
                                    start: eventBaseDate,
                                    end: endTime,
                                    draggable: false,
                                    editable: false,
                                    deletable: false,
                                    color: getCategoryColor(row['Show Type:']),
                                    showType: row['Show Type:'] || 'Unknown',
                                    hosts: row['Hosts: '] || 'Unknown',
                                    description: row['Description/Add. Info.'] || 'No description available'
                                });
                            }
                            savePreviousEvent = row['Show Name:'];
                        });
                    }

                    setEvents(eventsTemp);
                })
                .catch(error => {
                    console.error('Error fetching the Excel file:', error);
                });
        };

        readExcelFile();
    }, []);

    return null;
};

export default EventsFetcher;
