import { atom } from "jotai";

export const eventsAtom = atom([]);
export const showsData = atom([
  {
    show: "https://www.youtube.com/embed/zZV32WaPIlQ?si=EH1LqLDGPEgBMH1c",
    name: "THE 22 WEST FILM FEST RECAP",
    title: "22 West Media",
    date_val: "Apr 11, 2024",
    key: 7,
  },

  {
    show: "https://www.youtube.com/embed/jE_efOkqTOI?si=ZwnUU25nVhTRXoEL",
    name: "Spotlight: Discrimination In the Cosplaying Community",
    title: "22 West Media",
    date_val: "Feb 29, 2024",
    key: 6,
  },

  {
    show: "https://www.youtube.com/embed/5J14EUO_eFk?si=K6XAwrLVL5WVdJDU",
    name: "Professional Makeup Artist and Makeup Fartist Try the Same Look | Local Access",
    title: "22 West Media",
    date_val: "Dec 8, 2023",
    key: 5,
  },
  {
    show: "https://www.youtube.com/embed/1ah6-FJ4FjE?si=KgllFMXs_RopFmFA",
    name: "22: The Office Friendsgiving",
    title: "22 West Media",
    date_val: "Nov 23, 2023",
    key: 4,
  },

  {
    show: "https://www.youtube.com/embed/Q-_5dj0MAJk?si=uMC8nV4x-NNTfhow",
    name: "HELP: Mise En Place",
    title: "22 West Media",
    date_val: "Oct 27, 2023",
    key: 3,
  },

  {
    show: "https://www.youtube-nocookie.com/embed/xEUohVaoH4M?si=2Xrwm1O2Yo4Ufa8v",
    name: "Payphone | Poetry Visualizer",
    title: "22 West Media",
    date_val: "Oct 20, 2023",
    key: 2,
  },

  {
    show: "https://www.youtube-nocookie.com/embed/HD7QFzYg7pk?si=PAW0Ft60bbCZM557",
    name: "Local Access: Skate 2",
    title: "22 West Media",
    date_val: "Oct 12, 2023",
    key: 1,
  },
]);

export const DarkModeState = atom({
  key: "darkModeState", // unique ID (with respect to other atoms/selectors)
  default: localStorage.getItem("darkMode")
    ? localStorage.getItem("darkMode")
    : false, // default value (aka initial value)
});

export const CurrentSongRadio = atom({ title: "", artist: "", albumArt: "" });

export const MagazineData = atom([
   {
    magazine_id: 46,
    magazine_img: "/image/MagazineArticles/Opinion/opinion1.jpg",
    magazine_name: "1 opinion- Last Train Nowhere",
    magazine_type: "online articles",
    magazine_titleData: ``,
    magazine_writer: "Keanu Hua",
    magazine_data: () => {
      return (
       <>
       
        <div>
      <p>
        It’s late evening. Dinner was a hearty Irish meal, or the blood and spice of 毛血旺 –
        heavy, sloppy and delicious things fitting for something more monstrous, always capped with
        matcha.
      </p>

      <p>
        Away from Rockville Town Square, across a few roads, past the movie theater, past the
        quiet offices. Everyone’s gone home from here, even if the Town Square is full of life. That
        kid is shambling to the metro to go back to his lodgings at George Washington University.
      </p>

      <p>
        Past the fare gates, scan his phone, up the escalators, facing west for the southbound
        train that’ll take him back. The Red Line pulls up and he steps on with nobody.
      </p>

      <p>Opportunity cost is one of the basic tenets of–</p>
      <p>“Step back.”</p>
      <p>Of economics. It includes your implicit and explicit–</p>
      <p>“Doors closing.”</p>
      <p>Costs.</p>

      <p>
        That kid knew that, but he chose to ignore it, and now look at him – sitting alone on that
        metro, looking out at the darkness, “Twilight Express” by 19’s Sound Factory blasting to imagine
        someone waiting on the other end, because this is all he can do. This is all he wants to do.
        That kid is not happy.
      </p>

      <hr />

      <p>Sometimes, we make blunders.</p>
      <p>
        For example, financiers blundered over several years leading up to 2008 and had a bailout
        valued at several hundred million. I did not blunder several hundred million, but I did blunder
        $5,950 over summer.
      </p>
      <p>
        TFAS is a program that promises you an internship somewhere in DC with accompanying
        3-unit coursework at GMU, guest lectures, socialization, housing, etc... It costs $8,450 at base
        and can be funded by scholarships.
      </p>
      <p>How much? Apply and find out. I got $2,500 taken off.</p>
      <p>
        Working where? Sink $750 to get a list of mostly-unpaid internships to work at, but we call
        work experience king anyway, so it’s fine.
      </p>
      <p>Learning what? Economics. Free-market economics.</p>
      <p>Living where? Nation’s capital with other talented students.</p>
      <p>
        Well. Okay. Sign up – see what happens – accepted – accept – pay $750 – ignore Sunk Cost
        ringing his bell. Go through internships, get one that sounds similar enough? It’s unpaid,
        because of course. That’s okay. Work experience is king. That’s okay. That’s okay.
      </p>

      <hr />

      <p>
        That kid feels like he’s done something wrong, that he doesn’t really know what it wants,
        like some lonely, monstrous thing. Shouldn’t he be happy? Everyone said he should be happy.
        It looked like the program was good. Why else do so many alumni love it?
      </p>
      <p>
        Please be happy. Just be happy. Why can’t he be happy? Is there something wrong with him?
      </p>
      <p>The doors open, and the kid silences the song of the monster.</p>
      <p>“This is a train to... Shady Grove.”</p>
      <p>Another nightjar of a person boards the metro. They share a glance and nothing more.</p>
      <p>“Step back.”</p>
      <p>
        But at least the sound of WMATA’s rumbling will rock the kid to sleep. It’s the only peace he
        knows here.
      </p>
      <p>“Doors closing.”</p>

      <hr />

      <p>
        Let’s not forget implicit costs, all those other things I could’ve been doing. Applying to
        PPIC, taking econometrics at UCI, those direct quantitative, policy, and research skills directly
        linked to my career goals of being a policy maker or academic. Econometrics is the core of
        empirical economic methods, and hence both.
      </p>
      <p>So what exactly did I learn?</p>
      <p>
        Work experience was soft skills, bureaucracy, and environmental law. The team was
        enjoyable, but there’ll always be that question of whether I should take the risk of sideways skills
        as compared to known factors like econometrics.
      </p>
      <p>
        Coursework was free market. We knew that, but other problems arose. For starters, the
        fundamental economic theories were public choice and Austrian school economics – heterodox
        schools. Not American mainstream. To be clear, these schools are not all bad; indeed, some
        economic tenets like opportunity cost were “Austrian” at one point, and Friedrich Hayek, an
        Austrian-school economist, did win a Nobel prize.
      </p>
      <p>
        Nowadays, some common criticisms I’ve observed are that Austrian economics largely ignores
        the empirical, data-driven method that orthodox economics is built upon. If my online Austrian
        friends are in fact Austrian, Austrians in economics programs in Austria learn the Austrian school
        in a historical, critical context, not as a foundational framework, whereas public choice’s major
        journal published a questionable article concluding election fraud in 2020. Maybe lying in this hot
        mess is something worthwhile, but the fact that we have such enormous mistakes is a red flag.
      </p>
      <p>
        What exactly did we learn? Some of it was fine. Some of it was not fine, for example a podcast
        on prisons where the host seemed to not be taking in what his guest said, or the claim that other
        fields like history (reasonable), philosophy (maybe), theology (what?) need to be introduced into
        economics, only to flop in following up to that claim, or that paper that claimed the British empire
        was a net benefit to India. Assignments were just “tell us your thoughts” for the most part about
        the reading, which is necessary for a short summer semester, with a longer essay of our own topic
        at the end that used very little of actual course content to make.
      </p>
      <p>
        What skillsets did I learn? When you discuss public policy, as I’ve learned here, you should be
        able to consider your status quo and alternatives across a consistent set of parameters. Hence,
        we could’ve tried to understand or introduce the fundamental methodology of economic analysis
        – regressions, data collection, those sorts of things – rather than people’s opinions on things.
      </p>
      <p>
        So the real question is: what does it mean to be an economist? Undeniably, you need “economic
        intuition” and “economic logic,” but these are just a start. It helps to know other perspectives, but
        you can’t just be contrarian to be contrarian – the scientific method needs you to test and verify
        your conjectures best you can. Hence, a heterodox theory should sufficiently explain something
        orthodox is incapable of, but I never felt like that was sufficiently proven.
      </p>

      <hr />

      <p>
        WMATA is the only peace he finds. Even after his final, when staff gave them free tacos, he’d
        rather abandon them for Vietnamese food at Innovation Center on the Silver Line. Rattling along
        the highways, the cityscape passing by, it reminds him of what could be. What isn’t, because we
        married our cars, and cars are a terribly possessive spouse.
      </p>
      <p>
        WMATA brings him to Rockville Town Square, Ballston Quarter, these sorts of bustling mixed-use
        sites because public transit relies on urban design that relies on public transit that relies on urban
        design, looping endlessly. A wonderful array of restaurants and cultures line up along WMATA’s
        routes.
      </p>
      <p>
        WMATA brings him to the Smithsonian. The Smithsonian Folklife Festival is another glimpse of
        American Indian life. Their hopes.
      </p>
      <p>
        WMATA reminds him of his writing. It’s no good, but it doesn’t matter. It’s the only thing that
        still reminds him he’s not some lonely, monstrous thing.
      </p>

      <hr />

      <p>
        I was tempted to take one of their slogans and turn it into a title – “the facts against socialism”
        being “the facts against TFAS,” but decided against it. I still don’t feel confident, so I don’t want to
        say I have any “facts.” What I do have, though, is a vague knowledge of economics and an
        expectation of how policy was made, neither of which were fulfilled in coursework.
      </p>
      <p>
        The closing ceremony is recorded. You won’t see me, but at about 1:21:13 in our closing
        ceremony, when awards are being given out for my track, I duck out because a strong
        physiological reaction sends me to the floor – ribs sealed, hyperventilating, numbness spreading.
        I couldn’t accept an award from folks like them, but I was too competitive to not want one.
      </p>
      <p>I paid for this service, and I regret it. That’s the free market, baby.</p>
      <p>
        So why did everyone else love TFAS? I’m still in the alumni group, following their Instagram and
        LinkedIn pages in futile hope I’ll realize what I missed. So many of my peers loved it, said it gave
        them great opportunities and was somehow rigorous.
      </p>
      <p>
        But maybe I’m just delusional. I keep coming back to it, to my peers’ happiness, to the praise,
        hoping I can find something – but I can never– never– never–
      </p>
      <p><i>Dieu tout puissant, assez, assez!<br />Shantih, shantih, shantih.</i></p>
      <p>
        Maybe it should’ve been up to me to make it matter, and it should’ve been up to me, because
        individual choice is the most important part of Austrian economics, anyway.
      </p>
      <p>
        Maybe I gained new insights into myself, that I don’t know what “happiness” or “enjoyment” are,
        that I’m only haunted by my love for WMATA.
      </p>
      <p>
        But do you have any idea how privileged I have to be to say I paid $6,000, for something I can’t
        guarantee will help that much, for “insight?”
      </p>
    </div>
       
       </>
      );
    },
  },
  {
    magazine_id: 46,
    magazine_img: "/image/MagazineArticles/Opinion/opinion1.jpg",
    magazine_name: "Farewell: The End of my Story with you, Dear Reader",
    magazine_type: "Student Life",
    magazine_titleData: ``,
    magazine_writer: "Vivien Gray Valoren",
    magazine_data: () => {
      return (
        <>
          <p>
            Looking over my journey through the past and my present at CSULB, it
            all began with a long road. Our paths have twisted and turned,
            rolled over hills and gone through deep valleys, bypassed the
            deserts and skirted along the coastline, and now the road peters out
            to an endless plain with no ending, and no beginning. Some of you
            have traveled with me along my first steps, others have only
            recently joined, and some were lost along the journey. There have
            been many campfires by the road in the starry late nights together,
            many rainy miserable water-logged days alone, and even more days I
            can’t remember to tell you. And it is thanks to some of you I
            carried on when I desperately wanted to lie down and never see the
            road again. Alas, all things in our mortal lives come to a
            conclusion, and rarely if ever we get to it coming beforehand.
            Except this time. My time at CSULB ends after this December as I
            move on to what befalls me in life after undergrad. But before I
            move on, I want to tell you one last story about what my journey was
            like and why it’ll matter after I’m gone.
          </p>
          
    
          <p>
            Long Beach was a shining new place that filled me with fear and
            wonder. I knew nothing, feared the unknown, but welcomed all that I
            saw. This change, I would later learn, was the key to the rebuilding
            of my very foundation. I felt this was the place where I needed to
            be. Growing, and learning, loving, and letting myself be who I am. I
            not only left behind my old life from Palm Springs, but also my old
            identity as I became more open about my transition. The city still
            feels like a howling beast in the night however I no longer fear the
            noise as I know what lingers in the dark. It was here I learned I
            love people yet I also fear them. The more I got closer to them the
            more I dreaded the eventual pain of abandonment or harm. Yet, it was
            these same people who because they opened their hearts allowed me to
            open mine.
          </p>
                <img
            src="/image/MagazineArticles/Culture/Vivien Gray Valoren.jpg"
            alt="MagazineImg"
            style={{
              width: "50%",
              height: "50%",
              display: "block",
              margin: "0 auto",
            }}
          />
          <p>
            Some are acquaintances, some are friends, and some now are even
            family. Even in passing I thank those who were friendly. It allowed
            me to feel safe, and be myself. I had grown accustomed to an
            environment where my true personality was clandestine, and where I
            felt I had to be hidden in the shadows. But thanks to my friends and
            loved ones I was finally able to walk into the light. They taught me
            I can be loved, and love others. I can be happy, and make others
            happy. And most importantly to me, I can be more than just a passing
            figure in their lives. I can be their sister, I can be their family,
            I can be their mentor, and I can be a true friend. I could finally
            be loved and cherished. This was a feeling so foreign to me, yet
            when it arrived I no longer felt so far away from concepts like
            unconditional love or chosen family. Losing my old family let me
            find my chosen family.
          </p>
          <p>
            My biological family had imparted me with CPSTD from years of
            physical, mental, emotional, and sexual abuse. And although I had
            left the family house for good, their ghosts still haunted my mind.
            But what do you do once you forget the faces you had spent over 20
            years growing with? Well, you remember the new ones that stick
            around. It is those who I met in the dorms of Beachside, the Queer
            Student Resource Center, the Pride Dorms, in various classrooms and
            events on campus, even if it was just for a semester or even just
            for a day. It was all these little interactions, these little steps
            on my path that lead me to where I am now. So I thank everyone.
            Including you for reading this. And for walking alongside me even
            for just a moment. By allowing myself to welcome new people in my
            life I was able to make new connections and find the right people to
            fill the holes in my heart my biological family had left open. I
            used to feel displeased with my wide torso, however I realize now
            it’s wide for my big heart to fit in. It yearned to love others
            deeply all these years and I’ve found it. That was my past and is my
            present. Which just leaves the future. A future that for many is
            clouded with fear and loathing.
          </p>
          <p>
            The results of the election have left many in my community and
            elsewhere in a dire situation. No one knows what will befall us in
            this moment of strife. But what I do know is time and time again the
            LGBT+ community has persisted and persevered beyond the radicals of
            hate and ignorance. I will survive. And even if I don't, the love I
            have in me will live on and never truly be extinguished. Because my
            time with you all has shown me how powerful love can be above all
            else.
          </p>
          <p>
            I stayed up late writing this. I feel it’s appropriate since the
            distractions of the day are over and the still of the night is where
            I best focus. And as I fight off the tiredness in my eyes I remember
            how much has passed and how quickly it seemed to have happened. I
            have grown more in my two years here than I ever have in my entire
            life up to this point. And I will continue to grow to my fullest
            potential long after these lasting words are written and I exist
            only in memory for you, dear reader. For me it was a long two years
            that flew by. For you it was only a glimpse into the flash of light
            that was my life. And for you and I both, it is the end of a long
            road. Farewell, dear reader. This journey may end, but life’s
            adventures go on.
          </p>
        </>
      );
    },
  },
  {
    magazine_id: 45,
    magazine_img: "/image/MagazineArticles/Opinion/opinion1.jpg",
    magazine_name: "What's it like about being Non-Binary RA after Math",
    magazine_type: "culture",
    magazine_titleData: `The end result of my time as a non-binary RA was one that left me with a disheartening experience from my identity being erased by CSULB’s Housing Department.`,
    magazine_writer: "Vivien Gray Valoren",
    magazine_data: () => {
      return (
        <>
          <p>
            The end result of my time as a non-binary RA was one that left me
            with a disheartening experience from my identity being erased by
            CSULB’s Housing Department. My original article in last year’s
            Outober issue, “What’s it like being a Non-Binary RA”, was brought
            up to Housing by someone. Most likely a sympathetic reader who may
            have unintentionally lit a fire that Housing would use to silence
            me. I knew what the consequences were but I was not expecting to be
            treated that way. But when such a person voices the treatment they
            received by said bastion of inclusion and diversity, there is a
            punishment for free expression. And now I will relish you, dear
            reader, of what I dealt with last semester.
          </p>
          <p>
            I was told by my supervisor that I had breached my contract. I had
            communicated with an outside media (22 West) about my experience
            during training with Housing. Mind you, inside the first page of
            every copy of 22 West is a disclaimer stating all written words
            within each issue are of the opinions of their creators and bear no
            official representation for anyone else. I was given the punishment
            of being put on probation for the academic year because of my breach
            of contract. It was my first semester as an RA and after three
            months I had a punishment that was dealt with a heavy hand. In my
            meetings with my supervisor and their assistant, I was asked, or
            more so reprimanded, about why I didn’t try to communicate my
            grievances with management before writing the article. But I had.
            Several times. Each time I was reminded Housing only saw my
            existence as an afterthought. I was put into the female wing of the
            dorms during training with a female communal bathroom but my own
            room was labeled as “gender neutral.” Even though Housing found an
            easy solution (which was simply cordoning off one of the unused
            bathrooms on the upper floors), I was told so much was going on with
            construction that they were unable to catch this mistake. So, in
            other words, I fell through the cracks. A feeling that is all too
            familiar to me. A disappointment but not a surprise. One that was
            once again caused by an institution failing me.
          </p>

          <p>
            I later had a meeting with Fatou Olshanki, an associate director of
            Housing. In that meeting she apologized for Housing’s actions.
            However, my academic probation for the year remained. She admitted
            that I somehow had been marked as female in their system. Mind you,
            my legal gender is X. This is stated on my student information, ID,
            and birth certificate. I was advised by Jensen Puckett of 22 West to
            speak to the CSULB Ombudsman. In that meeting I explained my
            grievances once again and told the Ombudsman how I was placed on
            probation for the academic year. I had one meeting with them and
            that was it. I never had any follow up or any other communications
            with the Ombudsman’s office. I’m pretty sure they used the situation
            as a learning experience for themselves as a means to cover their
            asses from a lawsuit. The semester after that, I heard from my RA
            friends they now mention during training in the pyramid that there
            are no gender-neutral restrooms. Which left me with the question:
            what do I tell the residents? My job revolved around them and there
            was no way I could keep something like this private. So I decided
            what I would do. I would serve as a role-model.
          </p>

          <p>
            I decided to be open about it to my peers and to the residents in
            the Pride Dorms. I asked myself many questions before I told them.
            Did I risk breaking the rules by writing the article? Yes. Did I
            risk getting into trouble? Also, yes. Was I going to let another
            institution keep me down because they assumed I’m a nobody with no
            voice? (Technically I am a nobody, but at least I can write), No.
            Queer resistance is breaking the rules. Especially rules that say
            you must trust the very institution that wronged you. I did it for
            my Queer RAs and especially the residents. Because even after all of
            that, some of them still chose to be RAs. Besides getting housing
            they otherwise could not afford, they wanted to do what they could
            for their community despite the actions of CSULB. The school showed
            their hand and during the aftermath of it all really highlighted the
            importance of community. The residents supported me, and ultimately
            they understood why I did it and felt my punishment was severe. I
            wanted to impart my experience on them. Acts of resistance are a
            core tenant of being Queer.
          </p>

          <p>
            I write this article as what may be my last since this is my final
            semester of undergrad at CSULB. When the question of where I shall
            go for grad school arises, a part of me still wants to go to CSULB
            because I love the environment and community here. But I don’t like
            the institutions that run it. Injustice and discrimination are
            unfortunately common parts of being Queer, especially for those like
            me who do not follow the norms for gender. However, so is fighting
            for your voice to be heard. I don’t regret writing the original
            article nor will I regret whatever happens to me after writing this
            one. And if CSULB decides to come after me for this, I’ll write
            another article about it. Because this is what it’s like to be a
            Non-Binary RA.
          </p>
        </>
      );
    },
  },

  {
    magazine_id: 44,
    magazine_img: "/image/MagazineArticles/Opinion/opinion1.jpg",
    magazine_name: "I now know peace",
    magazine_type: "arts",
    magazine_titleData: `what I felt from your words and force behind them`,
    magazine_writer: "Monica Perez",
    magazine_data: () => {
      return (
        <>
          <p style={{ textAlign: "center" }}>
            what I felt from your words and force behind them
            <br />
            stronger than the love you choose to withhold
            <br />
            a silence that lingers, not just between us but now lives inside me
            <br />
            <img
              style={{ width: "250px", paddingRight: "10px", float: "left" }}
              src="/image/MagazineArticles/Arts/arts_9.jpg"
              alt=""
            />{" "}
            so I’ll hide from the embrace he urges to give me
            <br />
            because its weaker than the anger he has reserved for <s>me</s>
            <br />
            a guilt that gradually festers inside me
            <br />i long for the day I can accept the <s>love</s> you have to
            give me <br />
            i’ve come to understand what kind of love that is
            <br />
            while it is love for you, it isn’t for me
            <br />
            because I’ve felt what real love is
            <br />
            not familial or romantically but from loyalty and friendship <br />
            unconditionally from the brown haired girl with glasses <br />
            oh how I love her with all my heart
            <br />
            nonjudgmental from the girls who teach me everything <br />
            and from the ones who grew with me and gave me true comfort and
            safety <br />
            Rest in peace my loves
            <br />
            because love is kind, it is not self-seeking or easily angered
            <br />
            a message he himself gave to me
            <br />
            before he peeled the gentleness off my heart
            <br />
            cold and heartless
            <br />
            words I’ll never let him lay upon me
            <br />
            for I still love the both of you
            <br />
            only in silence
            <br />
            that is what I can give in this moment
            <br />
            forgive me father
            <br />
            i choose to love, to let go of anger and resentment
            <br />
            possible with the strength you’ve given me
            <br />
            but in this moment I won’t be consumed
            <br />
            I wrestle the thought of their hate and their love
            <br />
            something I no longer crave
            <br />
            as I know love within myself and in others
            <br />
            i now know peace and it has no relation to my mother and father
            <br />
          </p>
        </>
      );
    },
  },
  {
    magazine_id: 43,
    magazine_img: "/image/MagazineArticles/Opinion/opinion1.jpg",
    magazine_name: "love you always",
    magazine_type: "arts",
    magazine_titleData: `i can see it in your eyes now
    i never noticed it was time
     why
     did
     i
     not
     notice?
    `,
    magazine_writer: "Monica Perez",
    magazine_data: () => {
      return (
        <>
          <p style={{ textAlign: "center" }}>
            i can see it in your eyes now
            <br />
            i never noticed it was time
            <br />
            why
            <br />
            did
            <br />
            i<br />
            not
            <br />
            notice?
            <br />
            but the end is when it comes down to
            <br />
            feeling your soul fade slowly
            <br />
            in my arms i held you
            <br />
            /warm/ yet empty
            <br />
            how do I take your pain away?
            <br />
            you always took mine
            <br />
            i’m gonna miss you, but I’ll be loving you always
            <br />
            you paint my dreams
            <br />
            guilt and fear I bear
            <br />
            was there more to be done?
            <br />
            i<br />
            didn’t
            <br />
            do
            <br />
            enough!
            <br />
            <div
              style={{
                width: "100%",
                alignItems: "center",
                margin: "30px 0px",
              }}
            >
              <img
                style={{
                  width: "70%",
                  alignItems: "center",
                  borderRadius: "10px",
                }}
                src="/image/MagazineArticles/Arts/arts_10.jpg"
                alt=""
              />{" "}
            </div>
            will I remember, the way you felt?
            <br />
            and how you healed me?
            <br />
            your presence is still needed
            <br />
            p l e a s e<br />
            i need you to breathe again
            <br />
            but you’re not here, and I’ll be loving you always
            <br />
            with the same immensity you felt for me
            <br />
            souls intertwined
            <br />
            heart is on my sleeve
            <br />
            childish drawings badly healed in my skin
            <br />
            because I love you always
            <br />
          </p>
        </>
      );
    },
  },
  {
    magazine_id: 42,
    magazine_img: "/image/MagazineArticles/Opinion/opinion1.jpg",
    magazine_name: "I Want to Make Beats",
    magazine_type: "opinion",
    magazine_titleData: `It was an amazing feeling arriving at Long Beach State as a freshman straight out of high school. I was so happy to be here. Everything seemed larger than life to me. The buildings were tall, the campus seemed to go on forever and there were more people here than I had ever encountered in my entire life. My mother and father were educated and did an excellent job of preparing me for college. I was naturally talented, very smart, better than average at math and well tutored by my mother in Biology and Chemistry. With a bright future ahead of me and everything to look forward to, there was something wrong with me. Deep inside of my heart, way in the back of my mind, lies a secret obsession. It was hard to explain to anyone I knew. It made me feel very insecure.`,
    magazine_writer: "Mfanisi Norman",
    magazine_data: () => {
      return (
        <>
          <p>
            It was an amazing feeling arriving at Long Beach State as a freshman
            straight out of high school. I was so happy to be here. Everything
            seemed larger than life to me. The buildings were tall, the campus
            seemed to go on forever and there were more people here than I had
            ever encountered in my entire life. My mother and father were
            educated and did an excellent job of preparing me for college. I was
            naturally talented, very smart, better than average at math and well
            tutored by my mother in Biology and Chemistry. With a bright future
            ahead of me and everything to look forward to, there was something
            wrong with me. Deep inside of my heart, way in the back of my mind,
            lies a secret obsession. It was hard to explain to anyone I knew. It
            made me feel very insecure.
          </p>
          <p>
            My mother advised me to choose a degree in engineering. This made
            sense because engineering was an impressive career. Once you
            graduate, you are guaranteed a high salary profession with benefits
            and a 401K retirement plan. My father wanted me to study nursing. He
            worked at the Los Angeles County Department of Mental Health. My
            father would tell me that the nurses he worked with made a ton of
            money without doing a lot of the dirty work or heavy lifting. To
            sell me on the idea, my father said that I had the added benefit of
            help from my mother who was already a Registered Nurse. She could
            tutor me though the entire nursing program. I passed on studying
            nursing. Nursing wasn't something I wanted to do for the rest of my
            life. I didn't like needles or blood, and frankly, it seemed like a
            boring job. I was eighteen at the time and I really wanted to follow
            the advice of my parents. They knew what was best for my future,
            right? What my parents did not know was that I was hiding a huge
            secret. A secret that filled me with so much passion that it had
            become my first obsession. This obsession was sparked by a hip hop
            song. As I sat there watching the music video “I Left My Wallet in
            El Segundo” by A Tribe Called Quest, I felt an indescribable rush of
            nervous energy. I was immediately hooked on Hip Hop music. Like a
            junkie or an addict. I instantly knew; I wanted to make beats for
            the rest of my life.
          </p>
          <p>
            My first year of college started off well. I experienced what I call
            the “freshman honeymoon period.” Those two freshman semesters when
            the professors seem so nice, all of the employees greet you with a
            smile, and your academic advisors inspire you with the phrase, “you
            got this!” Everything seemed great until my sophomore year. I had to
            start thinking about my choice of degree. When I looked through the
            school catalog it seemed to have every degree ever created. I
            decided to investigate a degree in music. At the time, it seemed to
            make the most sense to me. There was a music program at Cal State
            Long Beach and I’m obsessed with creating music. There was one
            problem. I know I said that I loved creating music. It was true. But
            when my father signed me up for piano lessons, unfortunately, the
            piano did not appeal to me at all. Plus, I didn't see anyone in hip
            hop playing the piano. Why would I need to know how to play the
            piano? I wanted two Technique turntables, a DJ mixer, and equipment
            that would allow me to make beats. By the time I was 19 years old, I
            did not have the requisite skills to obtain a music degree. Knowing
            I could not obtain a degree in music, I resigned myself to taking
            the first steps towards a degree in engineering. I enrolled into a
            drafting class. I don’t remember the details of the class. What I do
            remember is the odd feeling I had walking into the classroom on the
            first day. It felt like I had been sucked into a science fiction
            movie where it was obvious to everyone watching that I would be the
            first person to die. I wanted to be successful in school. I wanted
            to have a high salary profession with benefits and a 401K retirement
            plan. Most of all, I wanted to make my parents proud. But if you
            were to ask me at that moment what I really truly wanted, I would
            have told you I wanted to make beats.
          </p>
          <p>
            In the second semester of my sophomore year, I decided to declare my
            degree in Africana Studies. I figured that since I was Black, I
            could learn “How to be Black.” I quickly found out that Africana
            Studies is not a “How to Be Black” degree. I had to study history,
            political science, psychology, sociology, theater, music, and
            economics. I was required to read books, attend lectures, and read
            more books. I studied Africana Studies until the beginning of my
            senior year when I was offered a high salary profession with
            benefits and a 401K retirement plan with the Los Angeles County
            Department of Recreation. With two semesters remaining to obtain my
            degree, I decided to drop out of college to become a Recreation
            Supervisor at Roy Campanella park in Compton. This job had nothing
            to do with hip hop music or making beats. But I thought to myself I
            could make enough money to buy two Technique turntables, a DJ mixer,
            and equipment that would allow me to make beats. I told my parents:
            “Well, the reason I'm attending college is to get a high salary
            profession with benefits and a 401K retirement plan. I have achieved
            the dream.”
          </p>
          <p>
            As my mom used to say, some odd years later: I have returned to Cal
            State Long Beach as an adult student. While I was away, I enjoyed my
            career with the Los Angeles County Department of Parks and
            Recreation. I also obtained my real estate license. During my last
            two semesters, I've met some amazing professors such as Dr.
            Nicholas, Dr. Claybrook, and Professor Paige who have taught me that
            Africana Studies is a “How to be a Scholar” degree. My
            responsibility as a scholar is to learn and understand the
            principles of Kawaida and MA’AT and to help make the world a better
            place for all people. My favorite class is the History of African
            American Music. Dr. Wilson did an amazing job teaching this class.
            Best of all, I actually made beats for MC Jonny T and JTLR that
            featured Snoop Dogg, Xzibit, and Rick Ross under the professional
            name of Madd Scientist. I'm sharing my story to advocate for the
            creation of an Electronic Music degree under the Music program here
            at Cal State Long Beach. I feel that now is a great time to offer
            that type of degree. Plus, I personally know that DJing is a high
            salary profession with benefits and a 401K retirement plan. I'm more
            than sure that there is some young person on this campus with the
            secret obsession to make beats.
          </p>
        </>
      );
    },
  },
  {
    magazine_id: 41,
    magazine_img: "/image/MagazineArticles/Opinion/opinion1.jpg",
    magazine_name: "I MISS MY MOMMY WAHHHHH",
    magazine_type: "opinion",
    magazine_titleData: `You don’t know the flu until you’ve endured one for the first time away from home.   
    I’m in my third year at Long Beach State, and I commute and live at home with my family. As of right now though, I’m in Ireland for the semester, studying at a university in Limerick. It’s the opportunity of a lifetime. One that so many people would kill for.`,
    magazine_writer: "Jude Sampson",
    magazine_data: () => {
      return (
        <>
          <p>
            You don’t know the flu until you’ve endured one for the first time
            away from home.
          </p>
          <p>
            I’m in my third year at Long Beach State, and I commute and live at
            home with my family. As of right now though, I’m in Ireland for the
            semester, studying at a university in Limerick. It’s the opportunity
            of a lifetime. One that so many people would kill for.
          </p>
          <p>
            As I type this, it’s a rainy Thursday here. I’m sick- lying in a bed
            where the springs poke me if I turn to lay on my side. My blinds
            haven’t been opened for days and the only things I have resembling
            medicine are chamomile tea and two lemons on the verge of becoming
            moldy.
          </p>
          <p>
            I’ve been forcing myself to sleep so I can’t feel the sickness or
            think about how hungry I’ve been. When I do sleep, I find that I
            dream about my mom, Denise.
          </p>
          <p>
            Her hand is on my forehead, checking my temperature and tucking a
            blanket into my sides. She rubs vicks on my chest and puts tea on my
            nightstand, right next to pajamas that are newly washed and neatly
            folded. They have Spongebob on them. I would give everything to be a
            kid again right now- to be small enough to fully curl up in her lap
            and fall asleep while she watches old reruns of I Love Lucy.
          </p>
          <p>
            After trying (and failing) to calm this internal chorus of, “WAHHHH,
            I WANT MY MOMMY, WAHHHHH,” I decided that I was only doing more
            damage trying to fight it. Sitting in my own cloud of sickness,
            thinking about the Women’s History Month Issue of this magazine, and
            subsequently thinking about the most important woman in my life, I
            found comfort.
          </p>
          <p>
            I replayed favorite memories of her in my head, closing my eyes and
            hoping my mucus ridden nasal passageways would clear up at even the
            smallest of happy memories. As the clock struck midnight here and
            Thursday turned to Friday, I remembered Friday nights as a child.
            Coming home from school, giddy about the weekend but also exhausted
            from multiplication tables and handball (I did not know how good I
            had it back then).
          </p>
          <p>
            Back then, Friday night was take-out night. We’d commit to switching
            it up sometimes but our go-to was the local Chinese Place that
            stuffed their containers so full of lo-mein and barbecue pork that
            the seams would almost burst. My dad would be working a night shift
            and usually my older sister would join us, but some of my favorite
            Fridays are the ones I spent alone with my mom.
          </p>
          <p>
            We would make plates of food and flip through our DVD collection,
            debating genres we were in the mood for or actors whose faces we
            simply didn’t want to look at. It was on these Friday’s that she
            would show me some of my favorite movies ever. Bye Bye Birdie, Best
            in Show, Fatal Attraction, Moonstruck, The Waterboy, Mr.Mom, Uncle
            Buck, The Craft, The Thing, and The Long, Long, Trailer- which is a
            generally overlooked Lucille Ball and Desi Arnaz film that is about,
            you guessed it, a comically large trailer that the two take cross
            country on a screwball road trip. It’s one of my moms favorites, and
            now it's one of mine.
          </p>
          <p>
            You might be thinking: “God this fucker loves to talk about movies!”
            Well, I am my mother’s son.
          </p>
          <p>
            Denise Marovich-Sampson is an encyclopedia of pop-culture knowledge.
            Film, Television, Music, and Literature are all things she has been
            immersing herself in from a young age. She went to Long Beach State
            and wrote arts and entertainment stories for the Daily 49er, back
            when a new physical copy was circulated around campus every day of
            the school week.
          </p>
          <p>
            When I started at Long Beach and then began to write for 22 West,
            she would tell me stories of late nights spent in news rooms gluing
            the layout together and about interviews she had for her section,
            where she did movie reviews and pieces about student art
            performances on campus. Flipping through the archives of the
            newspaper and getting to read her writing was so amazing, but
            reading the rest of the newspaper gave me a glimpse into her life as
            a college student.
          </p>
          <p>
            From a September 1982 Issue, two whole pages are dedicated to a
            piece on the rise of the “Valley Girl” aesthetic. Below it is an ad
            for the film A Clockwork Orange because it was its opening weekend.
            Classified ads for roommates list a future tenants portion of the
            rent at $250 a month. In Long Beach. California. To be precise. Can
            you tell that I’m bitter?
          </p>
          <p>
            Thinking about my own future after college makes me cower in fear,
            but being given a glimpse into my mothers life in undergrad makes me
            hopeful for my own future, because her own life after college was so
            bright. Although my mom pursued a degree in newspaper journalism at
            Long Beach, she changed her mind and set her sights on becoming a
            teacher.
          </p>
          <img
            style={{ width: "300px", paddingRight: "10px", float: "left" }}
            src="/image/MagazineArticles/Opinion/I_MISS_MY_MOMMY_WAHHHHH-1.png"
            alt=""
          />
          <p>
            She crushed it. For over thirty years she was exceptional at what
            she did. She taught English for a majority of it, hating Fahrenheit
            451 and crying her eyes out whenever she read the end of To Kill A
            Mockingbird to her class. I would get irritated as a kid, being out
            in public with her and having people approach us and go: “No way!
            Mrs. Sampson?!” But looking back, they all serve as proof that my
            mom was damn good at what she did.
          </p>
          <p>
            She cared about each kid and wanted to make learning fun for them.
            As a kindergartener, I would sometimes get out early and have to go
            sit and watch her teach. Although I wouldn’t know that Animal Farm
            isn’t just about a bunch of talking pigs hanging out for a good
            eight years, watching her do her thing was magical. Students were
            responsive and sat up in their seats when she talked. She made the
            uninteresting hilarious. She often describes teaching as “having an
            audience.” A regular Jerry Seinfeld, this lady.
          </p>
          <p>
            When I got to high school, she was still teaching on my campus until
            my sophomore year, when she retired for good after working half time
            for a few years prior. I was lucky my mom was beloved. Kids I had
            never even met before would approach me and tell me how cool she was
            and how sad they were that their younger siblings couldn’t have her.
          </p>
          <p>
            It was a loss for the school and our whole community when she
            retired. I remember at her retirement dinner I got on the mic and
            said I was sad she wouldn’t be able to clear my absences for me
            anymore (something she shouldn’t have done), and while she laughed
            at it along with the rest of the room, I wish I would have said
            something more heartfelt about how much good she put back into the
            world. I hope what I’ve said here can help put it into perspective a
            bit.
          </p>
          <img
            style={{ width: "300px", paddingRight: "10px", float: "left" }}
            src="/image/MagazineArticles/Opinion/I_MISS_MY_MOMMY_WAHHHHH-2.png"
            alt=""
          />
          <p>
            After her retirement, the world shut down. Her mom- my nona- had
            dementia that worsened day by day. My mom is a strong and caring
            woman, and she took care of my Nona virtually everyday during this
            time. Underneath the weight of that pressure, she was struck with
            her own health issues, and almost died after passing out at home and
            being rushed to the hospital.
          </p>
          <p>
            My Nona passed away early last year from dementia. Another huge life
            event for my mom. For our whole family. She carried so many people
            on her shoulders as she got my Nona’s things in order and arranged
            funeral proceedings. She gave a beautiful eulogy. Her voice boomed
            through that chapel and captured everyone's attention. She is the
            strongest woman I know.
          </p>
          <p>
            Now, I’m still sick and 5,000 miles away from my mom as I type all
            of this, and as I do, she is recovering from an operation that has
            her feeling the healthiest she has in years. We get on the phone a
            few times a week, and hearing how much better she is just in her
            voice is worth its weight in gold.
          </p>
          <p>
            In my delirious sick dreams I dream of my mom taking care of me, but
            I also dream of the new memories I will get to make with her when
            I’m back from Ireland. Spending the summer going to the movies,
            doing the things on her “post-surgery bucket list,” and going to the
            Hollywood Bowl this fall to see one of her lifelong heroes, Joni
            Mitchell. I actually listened to Mitchell as my plane to Ireland was
            taking off, just so I could have a little piece of my mom with me.
          </p>
          <p>
            My mom encourages me to write, and she’s a fantastic writer herself.
            She reads all of my writing, so I know she’s reading this.
          </p>
          <p>
            I love you mom. Happy Women’s History Month to one of the most
            amazing women of all time.
          </p>
          <p>Lucille Ball has some competition.</p>
        </>
      );
    },
  },

  {
    magazine_id: 40,
    magazine_img: "/image/MagazineArticles/Arts/arts1.jpg",
    magazine_name: "FEAR PHOTO ESSAY",
    magazine_type: "arts",
    magazine_titleData: `Being a very self-critical person, I have always been fearful of the worst, especially in terms of my social interactions. One of the most engulfing has been the fear that the ones I hold dear would not hold me to the same standard, and that’s what influenced this photo essay. I chose to start with my friend Kevin enjoying the company of our other friends, Sam and Sella, and as he parts ways with them, he can’t help but let his mind flood with that fear that they are now talking about how awful Kevin is, and that they have been fake to him all along. I chose the red lighting to emphasize that this is Kevin's fear and the lighting change signifies it’s only in his head. When he looks back the lighting has changed back to normal, Sam and Sella are still having a casual conversion in actuality. Kevin’s fears are calmed and he can now move on in peace. The situation playing out in these photos is something I go through a lot but my fear always gets me in my head, which in the end is never what plays out.`,
    magazine_writer: "Rohan Bhardwaj",
    magazine_data: () => {
      return (
        <>
          <p>
            Being a very self-critical person, I have always been fearful of the
            worst, especially in terms of my social interactions. One of the
            most engulfing has been the fear that the ones I hold dear would not
            hold me to the same standard, and that’s what influenced this photo
            essay. I chose to start with my friend Kevin enjoying the company of
            our other friends, Sam and Sella, and as he parts ways with them, he
            can’t help but let his mind flood with that fear that they are now
            talking about how awful Kevin is, and that they have been fake to
            him all along. I chose the red lighting to emphasize that this is
            Kevin's fear and the lighting change signifies it’s only in his
            head. When he looks back the lighting has changed back to normal,
            Sam and Sella are still having a casual conversion in actuality.
            Kevin’s fears are calmed and he can now move on in peace. The
            situation playing out in these photos is something I go through a
            lot but my fear always gets me in my head, which in the end is never
            what plays out.
          </p>
        </>
      );
    },
  },

  {
    magazine_id: 39,
    magazine_img: "/image/MagazineArticles/Arts/arts2.jpg",
    magazine_name: "LOVE’S UNBLINDING",
    magazine_type: "arts",
    magazine_titleData: `I don’t often ask for help but
    I also never learned how to solve my own Problems. I stick my head in the Sand at the sight of trouble;
    Let the rocks and shell fragments Coat my brain so
    I don’t have to think.`,
    magazine_writer: "Ariel Smith",
    magazine_data: () => {
      return (
        <>
          <p>
            I don’t often ask for help but <br />
            I also never learned how to solve my own Problems. I stick my head
            in the Sand at the sight of trouble; <br />
            Let the rocks and shell fragments Coat my brain so <br />
            I don’t have to think. <br />
            But you’ve started pulling <br />
            My head out, dusting me off. <br />
            You make me think. <br />
            Ask for help or help yourself, <br />
            You say. <br />
            Now I’m aware. <br />
            I’ve been scared ever since. <br />
            There’s nowhere to hide when <br />
            You’re being watched. <br />
            No playing dumb when <br />
            You’re smart. <br />
            Nothing’s left to coat my brain with. Nothing’s left to hide behind.{" "}
            <br />
            Nothing’s left to be said. <br />
            Face your problems, you tell me. You can’t see the face when you’re
            Covered by the sand, so you <br />
            Pull my head out and tell me again Face your problems.
            <br />
          </p>
        </>
      );
    },
  },
  {
    magazine_id: 38,
    magazine_img: "/image/MagazineArticles/Arts/arts3.jpg",
    magazine_name: "ANNIVERSARY DINNER",
    magazine_type: "arts",
    magazine_titleData: `two candlesticks, languishing yellow, sit,
    Proud,			Rigid,
  held in small dark jars gripping their bases.`,
    magazine_writer: "Natalie Comfort",
    magazine_data: () => {
      return (
        <>
          <p>
            two candlesticks, languishing yellow, sit,
            <br />
            Proud, Rigid,
            <br />
            held in small dark jars gripping their bases.
            <br />
            thin wax flakes gather around the upper rim,
            <br />
            a couple falling further, past engraved spiraling <br />
            intertwined with thin straight cracks <br />
            of the brittle wood. <br />
            <br />
            a teardrop of wax slides, meeting the tablecloth,
            <br />
            inner liquid wax rippling and wrinkling, pressing
            <br />
            against the walls of its congealed surface. the man <br />
            slouches forward, forearms crossed, hands hanging
            <br />
            off the edge of the table, the woman leans to one side, <br />
            then another, eyes fixating on a painting, indistinct
            <br />
            brushstrokes of gold and blue.
            <br />
            <br />
            and the flame flickers, casting shadows,
            <br />
            one moment tall and thin,
            <br />
            another moment short and wide, <br />
            leaning to one side and then another, <br />
            responding to unfelt drafts. <br />
            <br />
          </p>
        </>
      );
    },
  },

  {
    magazine_id: 37,
    magazine_img: "/image/MagazineArticles/Arts/arts4.jpg",
    magazine_name: "CLOWN IN MY CLOSET",
    magazine_type: "arts",
    magazine_titleData: `There’s a clown in my closet
    And I won’t set him free
    Perhaps it’s the fear of him
    Hurting someone like how he hurt me`,
    magazine_writer: "Mila Le",
    magazine_data: () => {
      return (
        <>
          <p>
            There’s a clown in my closet
            <br />
            And I won’t set him free
            <br />
            Perhaps it’s the fear of him
            <br />
            Hurting someone like how he hurt me
            <br />
            <br />
            At the circus we laugh <br />
            During moments of our life
            <br />
            Where we wish for an escape <br />
            Of all things rife
            <br />
            <br />
            But when the show comes to an end
            <br />
            And we are left without light
            <br />
            The circus becomes silent
            <br />
            And the clown creeps into the night
            <br />
            <br />
            Lured me in with his warm smile
            <br />
            And his sleeves, full of color
            <br />
            But only some know
            <br />
            Of the dark secrets hidden in his big, red honker
            <br />
            <br />
            To put on a show was his only purpose
            <br />
            So he used me for laughter
            <br />
            Made a clown out of me
            <br />
            So he could be the ringmaster
            <br />
            <br />
            I balanced on trapezes for him
            <br />
            Without a safety net <br />
            Every day I risked my sanity
            <br />
            Just to be full of regret
            <br />
            <br />
            The clown used to make me feel <br />
            A little less ugly, a little less lonely
            <br />
            A little less
            <br />
            of a phony <br />
            <br />
            But now the clown makes me feel
            <br />
            Like I never needed an escape from reality
            <br />
          </p>
        </>
      );
    },
  },

  {
    magazine_id: 36,
    magazine_img: "/image/MagazineArticles/Culture/culture1.jpg",
    magazine_name: "HORROR FILMS THAT AREN’T HORROR FILMS",
    magazine_type: "culture",
    magazine_titleData: `All you scary cinephiles and freaky film fans think you know horror? Prepare to be humbled and beware the spoilers that follow!`,
    magazine_writer: "Leah Olds",
    magazine_data: () => {
      return (
        <>
          <p>
            All you scary cinephiles and freaky film fans think you know horror?
            Prepare to be humbled and beware the spoilers that follow!
            <br />
            <br />
            <b>
              1. "
              <a href="https://www.youtube.com/watch?v=enH3xA4mYcY">
                Sorry to Bother You
              </a>
              ” (2018, Boots Riley)
            </b>
            <br />
            <br />
            Boots Riley’s directorial debut is conscious on many levels, surreal
            to the max and is one of the most memorable dark comedies of the
            last decade.
            <br />
            <br />
            Here’s the basic premise: A poor Oakland man named Cassius Green
            begins working as a caller for a soul-sucking telemarketing
            business. He learns from his fellow Black employees that he must
            imitate a white man’s voice during calls in order to boost sales and
            move up the company ladder. Cassius does so and starts making gobs
            of money, much to the chagrin of former co-workers who are
            unionizing and protesting the company. In the end, he must decide
            whether he will stand with the exploited workers or keep crossing
            their picket line.
            <br />
            <br />
            And that is pretty much all you can expect to see in this flick!{" "}
            <br />
            <br />
            Well… except for the little thing at the end where evil CEOs are
            turning their loyal employees, including our protagonist, into
            literal workhorses who get pissed and thereafter stage an
            “Equisapien” revolution. So if you were wondering where the spooky
            element can be found, just watch the last ten minutes for some
            human-horse body horror. It will make you think you fell asleep at
            some point in your viewing but rest assured, you did not. <br />
            <br />
            <b>
              “
              <a href="https://www.youtube.com/watch?v=vgqG3ITMv1Q">
                The Big Short
              </a>
              ” (2015, Adam McKay)
            </b>
            <br />
            <br />
            Most people would sooner volunteer themselves for a helicopter
            bungee dive into a manmade lake – filled with horse manure, à la
            “Fear Factor” – than watch a bunch of{" "}
            <a href="https://www.youtube.com/watch?v=xbiDrzTd8fE">
              white dudes in business suits
            </a>{" "}
            discuss subprime mortgages and synthetic collateralized debt
            obligations. But the people who paid $12.50 to see this are not like
            most people. <br />
            <br />
            They likely enjoy other sadist entertainment, like the “Saw”
            franchise. Only instead of being obsessed with guts and gore, fans
            of this Oscar-nominated terror take pleasure in exposition, watching
            Margot Robbie and Selena Gomez give funny little PSAs explaining
            economic jargon while a bunch of greedy Wall Street guys take
            advantage of the incoming{" "}
            <a href="https://www.bls.gov/opub/mlr/2018/article/great-recession-great-recovery.htm">
              Great Recession
            </a>{" "}
            of the late 2000s. Truly chilling.
            <br />
            <br />
            <b>
              “
              <a href="https://www.youtube.com/watch?v=mE35XQFxbeo">
                A Bug‘s Life
              </a>
              ” (1998, John Lasseter)
            </b>
            <br />
            <br />
            Pixar’s junior release hit the scene back when the studio made
            family flicks about existentialism and exploitation. Wait… don’t
            they still do that? <br />
            <br />
            These bugs may seem all colorful and anthropomorphic-like, but make
            no mistake, they are as shifty and resilient as their real life
            counterparts. Hopper’s{" "}
            <a href="https://www.youtube.com/watch?v=VLbWnJGlyMU">
              capitalist monologue
            </a>{" "}
            will trigger the fight-or-flight response of underpaid workers all
            over America, and of course, his{" "}
            <a href="https://www.youtube.com/watch?v=Ilhoqey4LPY">
              death scene
            </a>{" "}
            is absolutely terrifying – enough to raise the eyelids of any
            desensitized five-year-old who found a bootleg of the film on
            Putlocker. <br />
            <br />
            <b>
              “
              <a href="https://www.youtube.com/watch?v=iH6FdW39Hag">
                The Curious Case of Benjamin Button
              </a>
              ” (2008, David Fincher)
            </b>
            <br />
            <br />
            An old man. A baby. An old man who is also a baby. A baby who begins
            his life as an old man and then becomes a young man who somehow
            becomes a baby again. And the whole time, the baby who is also an
            old man who is actually a young man is… Brad Pitt. Curious, indeed.
            <br />
            <br />
            No one could ever think up a nightmare this insidious, and no amount
            of wishful thinking or responsible drinking will ever get the images
            out of your head. This material is for singular viewings only. Then,
            after you rock yourself back and forth a few times in the corner,
            you might be able to go about your day again.
            <br />
            <br />
            <b>
              “
              <a href="https://www.youtube.com/watch?v=YwIt5wagRsg">
                Small Soldiers
              </a>
              ” (1998, Joe Dante)
            </b>
            <br />
            <br />
            Here is a title that was either a staple in your household’s VHS
            collection when you were small, or you have absolutely no idea what
            the hell this movie is but it sounds… familiar. <br />
            <br />
            Why? Probably because it’s basically the evil clone of “Toy Story”
            (1995), but instead of cowboys and spacemen, there are alien
            tribesmen called “Gorgonites” (good guys) and trigger-happy
            “Commando Elites” (bad guys, obviously), which are action figures
            brought to life by military computer chips. <br />
            <br />
            The creepiest scene is undoubtedly the one which finds the Commandos
            turning the Mattel-inspired “Gwendy” dolls into scantily clad
            killers who want to decapitate Kirsten Dunst’s girl-next-door
            character, Christy Fimple. One of the Gwendies – voiced by Sarah
            Michelle Gellar, no less – threatens Christy with the catchphrase,{" "}
            <a href="https://www.youtube.com/watch?v=n7KKfjFRw8w">
              “If you can’t pulverize, accessorize!”
            </a>{" "}
            Which is just… camp. Then the dolls jump across the darkened room
            with piercing giggles and stick-like body movement, before getting
            mutilated by their human hostage. <br />
            <br />
            If you have a thing for corrupted innocence and outright campiness,
            this is the one to know.
            <br />
            <br />
          </p>
        </>
      );
    },
  },

  {
    magazine_id: 35,
    magazine_img: "/image/MagazineArticles/Culture/culture2.png",
    magazine_name: "FEAR OF LOSING REPRODUCTIVE RIGHTS",
    magazine_type: "culture",
    magazine_titleData: `The war on reproductive rights has been an ongoing debate for decades now. However, the time has finally come when we must face the reality of it possibly getting overturned. Roe v. Wade, a landmark decision from 1973 that helps protect a women’s right to an abortion and privacy, is currently under fire with serious talks of it being overturned sometime between May and July of 2022. But, what does this mean for the future of reproductive rights in the U.S.?`,
    magazine_writer: "Lauren Galvan",
    magazine_data: () => {
      return (
        <>
          <p>
            The war on reproductive rights has been an ongoing debate for
            decades now. However, the time has finally come when we must face
            the reality of it possibly getting overturned. Roe v. Wade, a
            landmark decision from 1973 that helps protect a women’s right to an
            abortion and privacy, is currently under fire with serious talks of
            it being overturned sometime between May and July of 2022. But, what
            does this mean for the future of reproductive rights in the U.S.?{" "}
            <br />
            <br />
            “It’s a very scary thing,” Juana Garcia, a political science major
            and women’s gender and sexuality studies minor, said. “If it were to
            get overturned, it would be up to each state to choose if abortion
            would be legal or not because it is a federal law.” <br />
            <br />
            States would have the option to make it a crime to get an abortion,
            or even assist someone with getting an abortion, according to
            Garcia. Of course, states could also decide to uphold this decision
            and continue to allow this reproductive right. Garcia is not only
            involved in politics on the Long Beach State University campus, but
            she is also an activist for reproductive justice and social justice.
            In fact, she has fought for the right of women to have access to the
            abortion pill on CSU and UC campuses. According to Garcia, we won’t
            see much of a change in California, however, we might see an influx
            of people moving to the state and to other states that choose to
            uphold abortion rights. <br />
            <br />
            Although California will stay the same, a handful of states have
            already begun banning abortion and criminalizing those who get one.
            In Texas, a woman was recently arrested for having a “self-induced”
            abortion, however, it does look like the prosecutor is going to drop
            the case. Not only that, but Oklahoma has just passed a law that is
            described as a “near-total ban” on abortion. This means that those
            who get or try to have an abortion could face up to 10 years in
            prison for their actions or be punished with a $100,000 fine. “Prior
            to having Roe v. Wade, abortion had been illegal throughout the U.S.
            It was criminalized, and there was a huge increase of back alley
            self-induced abortions,” Garcia said. “A lot of women were dying
            during these procedures because they didn’t have the proper
            equipment and care.” <br />
            <br />
            But, the question is, why now? Why, after all these years, is Roe v.
            Wade getting overturned in 2022? According to Garcia, she believes
            it is because of who the supermajority of our judges is. There is a
            higher number of conservatives, and they are finally in a place
            where they can win this battle. <br />
            <br />
            “The people in office are impacting women, but those making these
            laws are men,” Garcia said. “So, how can a man in office make a law
            that they don’t understand because it isn’t about their bodies? I
            just don’t understand.” <br />
            <br />
            Over the years, women have been fighting tooth and nail to keep
            their reproductive rights alive, whether it be through sharing their
            personal stories or just marching the streets shouting for everyone
            to hear their pleas and demands. What can the people do to help? How
            can women and allies get their point across? According to Diana
            Fix-Mercy, a senior at LBSU majoring in women’s gender and sexuality
            studies, getting gruesome might be what we have to resort to. <br />
            <br />
            “I think it is a double-edged sword. Many times, you can kind of
            appeal to people’s sensibilities and emotions,” Fix-Mercy said. “We
            live in a society where you hear horrible things like that on the
            news every time you turn on the TV. So, a lot of people have become
            desensitized to those stories. So, we either need to become even
            more gruesome and even more shocking, or we need to try a different
            tactic.” <br />
            <br />
            Even though Fix-Mercy believes that using these stories gets
            sympathy and has helped appeal to the pathos of people, Garcia
            doesn’t feel the same way. Garcia believes that we shouldn’t have to
            resort to sharing personal stories in order to decide what women
            want to do with their bodies. <br />
            <br />
            “I feel like it shouldn’t have to come down to people showing
            vulnerability,” Garcia said. “I feel like it should just be up to
            people to have a choice. No woman is pro-abortion. No woman wants to
            go out and be like, ‘let’s murder a bunch of babies.’ Nobody wants
            that.” <br />
            <br />
            With the decision right around the corner, and with more and more
            states deciding to place strict bans on abortion, it makes sense
            that women are scared for the future of reproductive rights in the
            U.S. However, a good amount of states are also making abortion even
            easier to access, by making it cheaper and allowing abortion pills
            to be obtainable. Although the future isn’t looking too bright,
            there are always going to be people out there fighting for women’s
            rights. Of course, the fear is hard to overcome, but at least we
            know who is on the front lines of this war. <br />
            <br />
            “It should be up to every person to decide,” Garcia said. “Even if
            you personally choose to not do it, that doesn’t mean that you
            should be against people having a choice. You can be against it, but
            you don’t need to take away a person's right to choose.”
          </p>
        </>
      );
    },
  },

  {
    magazine_id: 34,
    magazine_img: "/image/MagazineArticles/Culture/culture3.png",
    magazine_name: "THE PERFORMANCE OF FEMINISM",
    magazine_type: "culture",
    magazine_titleData: `Are you a good person? How about you prove it by reposting pretty pastel-colored infographics on your Instagram story?<br /><br />
    Surface-level allyship is a glaring issue that sprouted after the George Floyd protests in 2020. People rushed to do the bare minimum and post black squares to show their support, not really contributing to the actual movement at hand. This has also long been a problem in feminism. Performative white feminism, propagated by media such as Buzzfeed, has plagued the feminist movement, ultimately steering the conversation away from intersectionality and treating such issues as a trending topic.`,
    magazine_writer: "Jude De Luna",
    magazine_data: () => {
      return (
        <>
          <p>
            Are you a good person? How about you prove it by reposting pretty
            pastel-colored infographics on your Instagram story?
            <br />
            <br />
            Surface-level allyship is a glaring issue that sprouted after the
            George Floyd protests in 2020. People rushed to do the bare minimum
            and post black squares to show their support, not really
            contributing to the actual movement at hand. This has also long been
            a problem in feminism. Performative white feminism, propagated by
            media such as Buzzfeed, has plagued the feminist movement,
            ultimately steering the conversation away from intersectionality and
            treating such issues as a trending topic.
            <br />
            <br />
            In the mid 2010s, large media company Buzzfeed started to post
            thought-provoking “woke” videos on Youtube. Certain videos of theirs
            include “Women Try Manspreading for a Week,” “36 Questions for Men,”
            and “9 Comebacks Feminists Would Be Proud Of.” These videos surely
            tried to put a spotlight on what vexes women, but the meaning
            twisted into the notions of “Men ruin everything” and “Men’s
            existence is terrible.” It also culminated into a new wave of “white
            feminism.” The movement shifted to focus on men and white women’s
            feelings rather than advocating for women's rights and equality
            between all genders overall. Buzzfeed never made the same amount of
            content or put in the effort about intersectional issues, leaving
            people such as women of color behind and isolated. One of Buzzfeed’s
            most controversial videos was titled “Women Paint with Their Period
            Blood.” This extremely performative video got its expected backlash,
            but the issue remained that white solutions such as this were put at
            the forefront instead of actual ways to contribute to the movement.
            <br />
            <br />
            Due to these empty actions and toxic ideas promoted by Buzzfeed,
            white feminism permeated society. Why should we be focusing on
            manspreading when there are more pressing issues at hand? Where is
            the same energy for problems such as trans women’s health and
            safety, bias in the medical field against black women, or pay
            inequality between white women and women of color? This also bleeds
            into the performative posting. Infographics posted on people’s
            stories echoed the same ideas reminiscent of Buzzfeed’s videos. Most
            of these infographics of white feminism promote “girlboss”
            solutions, watering down intersectional issues to just diversity and
            inclusion. <br />
            <br />
            Posting about these issues on Instagram and other social media sites
            has also served to trivialize the problem. There is information
            spread around about intersectional issues, but people stop posting
            about it when these issues fade away from the spotlight, even though
            that is not the reality of the world. Problems do not just disappear
            when they are not trending anymore. It must be an ongoing
            conversation, not a temporary pretty story that is only up for 24
            hours at a time. It is also a problem that people can tap through
            five posts in under thirty seconds. Social media is fast, people
            scroll away as soon as they are bored. Of course, these types of
            posts do spread awareness, but it overall does nothing to help when
            there are no resources or citations provided. There are also rarely
            any tangible actions attached to the simple post. At this point,
            posting such infographics only points to virtue signaling.
            <br />
            <br />
            In order to dismantle white feminism and to actively promote
            intersectionality, people must put in the work. Instead of blindly
            reposting pretty graphics that echo empty words such as “☆ EMPOWER
            WOMEN ☆” or “BE A GIRLBOSS!”, go beyond temporary stories that a
            person can easily swipe through. A good start to removing that
            internalized white feminism is a powerful book titled “White
            Tears/Brown Scars: How White Feminsim Betrays Women of Color” by
            Ruby Hamad. The book explores the history of white women’s role in
            white supremacy and why we must confront it to further promote
            equality for all. Additionally, make space to listen to those around
            you and acknowledge the privilege you may have. Learn how you can
            truly help your community and actually dedicate your time to places
            such as women’s shelters in your area or your local Planned
            Parenthood. Putting on a show on social media that you are a good
            person does not mean you’re a good person. Act on it, and truly
            enact change.
            <br />
            <br />
          </p>
        </>
      );
    },
  },

  {
    magazine_id: 33,
    magazine_img: "/image/MagazineArticles/Culture/culture4.jpg",
    magazine_name: "RED VIRGIN: THE LIFE AND MURDER OF A TEENAGE PRODIGY.",
    magazine_type: "culture",
    magazine_titleData: `Every decent parent wants their child to excel in life and many do whether it's through sports clubs or dance troupes. But during the early 20th century in Spain, one mother took that to an extreme length by specifically grooming her daughter to be among the most brilliant and accomplished women of her day before brutally murdering for reasons that are still debated on today. This is, about the story of Hildegart Rodriguez Carballeira.`,
    magazine_writer: "Joshua Biragbara",
    magazine_data: () => {
      return (
        <>
          <p>
            Every decent parent wants their child to excel in life and many do
            whether it's through sports clubs or dance troupes. But during the
            early 20th century in Spain, one mother took that to an extreme
            length by specifically grooming her daughter to be among the most
            brilliant and accomplished women of her day before brutally
            murdering for reasons that are still debated on today. This is,
            about the story of Hildegart Rodriguez Carballeira.
            <br />
            <br />
            Hildegart Leocadia Georgina Hermenegilda Maria del Pilar Rodriguez
            Carballeira was born on December 9, 1914 to Doña Aurora Rodriguez in
            Madrid. Her mother was originally from the town of Ferrol in the
            region of Galicia in northwestern Spain. She was conceived with the
            sole purpose of an experiment to create the perfect child to advance
            her mother's socialist and feminist beliefs, using a precedent in
            helping raise her nephew and turning him into a musical prodigy. Her
            biological father remained unknown to this day and it was said that
            Aurora only met him once and was selected for his intelligence and
            high status. Despite her mother's atheism, she was baptized in 1915.{" "}
            <br />
            <br />
            Hildegart was raised a child prodigy. Her mother was intimately
            involved in her education and pushed her to the highest standards
            possible. Hildegart could read at 2 years old and type at 4 years
            old. By age 10, she was fluent in German, French, and English and by
            age 11, she spoke at feminist conferences. Aurora controlled every
            aspect of her life and forbade her daughter from doing anything
            that'll distract her- including close friendships and romance. At
            the age of 13, she enrolled at the law school of the Complutense
            University of Madrid and earned her bachelor's by the age of 17
            while simultaneously teaching lessons at its School of Philosophy.
            While in university, she joined the Spanish Socialist Workers' Party
            (PSOE) whom she would later be expelled for publishing an article in
            La Libertad magazine against a reactionary candidate the PSOE
            supported. Hildegart's main interest was feminism and women's
            liberation. She believed that the key to Western misogyny was for
            women to take control of their bodies in the form of contraceptives
            and sexuality and advocated for desire and procreation. Hildegart,
            like many people across all social classes and education levels in
            the years before the rise of Hitler, was a proponent of eugenics and
            believed that humanity can only advance through deliberate natural
            selection. She published these beliefs in a series of books and
            pamphlets all before the age of 16, which received widespread
            acclaim and circulation and spoke at speaking engagements throughout
            Spain where newspapers at the time reported that standing ovations
            often lasted over five minutes.
            <br />
            <br />
            In 1931, Hildegart began interacting with some of Europe's and North
            America's foremost intellectuals. She began corresponding with
            Margaret Sanger, the American founder of Planned Parenthood, who was
            taken aback by Hildegart's intelligence at such a young age.
            Hildegart was practically fangirling in her letters- explaining her
            accomplishments while telling Sanger that she owned a picture of her
            in her room. Hildegart also asked Sanger for advice on how to
            navigate American cultural customs as a woman. That same year, she
            started writing to British social reformer and psychoanalytic expert
            Havelock Ellis who became enchanted with her writings and christened
            her "The Red Virgin" after her socialistic and feminist beliefs. In
            1933, Ellis would publish an article titled The Red Virgin about
            Hildegart's childhood upbringing and made sure to include her mother
            Aurora's master plan to raise the perfect child using eugenics. He
            wrote in the article that Aurora often told Hildegart that to
            "remember the mission, love is only passing.” Ellis emphasized how
            close Aurora and Hildegart were and how much Hildegart was guided by
            her mother through every step. Other than Ellis and Sanger,
            Hildegart was friends with War of the Worlds writer, H.G. Wells who
            rejected her offer to work as his secretary. <br />
            <br />
            Hildegart's plans for life didn't stop at law. She wrote to Sanger
            saying that she took up studying medicine and that she established
            the first birth control clinic in Spain, in Valencia and was in the
            process of opening another one in Malaga. She invited Sanger and
            Ellis to attend a birth control summit in Madrid she organized as
            she recently formed the Spanish chapter of the World League for
            Sexual Reform (Liga Mundial Para la Reforma Sexual). Although proud
            of Hildegart, Ellis shared his concern with her being involved in so
            many projects at one time and suggested in a letter that Sanger
            should take on some aspects of the conference. That would be the
            last time Ellis and Sanger interacted with Hildegart.
            <br />
            <br />
            On the night of June 9, 1933, Aurora snuck into Hildegart's room and
            shot her several times to death. There's no clear explanation of why
            her mother committed filicide. Some theories point that Hildegard
            had fallen in love with a Barcelona politician and planned to elope
            with him. Aurora may have found Hildegart's letters and killed her
            in response to the idea that she might leave her. Another theory
            points that Hildegart planned on visiting Wells or Ellis in the UK
            without her mother's permission. Lastly and what may be the most
            believable one, is that Aurora simply killed Hildegart out of
            jealousy. She was reported to have said in her trial that, "the
            sculptor, after discovering a minimal imperfection in his work,
            destroys it." <br />
            <br />
            Aurora was immediately tried for her crime. She claimed Wells and
            Ellis tried to coerce her to join an international spying cabal.
            This did not work as Aurora was sentenced to 26 years in prison in
            May 1934. Throughout the trial, Aurora was said to be calm and
            collected and showed almost no emotion at all other than sniffing a
            pot full of red carnations once. She was later transferred to a
            mental institution due to apparent schizophrenia, where she begged
            to be pardoned. She died in 1955 from cancer and was buried in a
            mass grave, during the height of Francisco Franco's regime.
            <br />
            <br />
            In 18 short years, Hildegart Rodriguez Carballeira accomplished what
            would have taken a lifetime to accomplish and involved herself with
            ideas that were decades ahead of her time and spoke with the top
            intellectuals of her day. This came at the price of a domineering
            and obsessive mother who ultimately led to her untimely death and
            her mother's shameful demise. A cautionary tale on how dreams must
            be independently curated and not be a carbon copy of a parent's
            vicarious living.
            <br />
            <br />
            <br />
            Sources
            <br />
            <br />
            Hildegart Rodríguez Carballeira. (2022, March 30). In Wikipedia.
            https://en.m.wikipedia.org/wiki/Hildegart_Rodr%C3%ADguez_Carballeira
            <br />
            <br />
            Coleman, E. (2013, July 24). Hildegart Rodríguez Carballeira: a
            Prodigy, a Champion &amp; the Tragedy. Margaret Sanger Papers
            Project.
            https://sangerpapers.wordpress.com/2013/10/07/hildegart-rodriguez-carballeira-a-prodigy-a-champion-the-tragedy/
            <br />
            <br />
            Meghan and Teghan, (2022, December 2). Spain &amp; Czech Republic.
            In Destination: Murder. Spotify.
            <br />
            https://open.spotify.com/episode/3nQEokeHLRVKTmDPeFr5MB?si=7FI2g0pXQ9OuCvfnTUnfRQ&amp;utm_source=copy-link
          </p>
        </>
      );
    },
  },

  {
    magazine_id: 32,
    magazine_img: "/image/MagazineArticles/Culture/culture5.jpg",
    magazine_name: "MOLDING MINDS OF FUTURE ACTIVISTS",
    magazine_type: "culture",
    magazine_titleData: `After growing up in a conservative family, Lori Baralt had no idea others had progressive thoughts as she did, let alone a whole major and career centered around it.`,
    magazine_writer: "Lauren Galvan",
    magazine_data: () => {
      return (
        <>
          <p>
            After growing up in a conservative family, Lori Baralt had no idea
            others had progressive thoughts as she did, let alone a whole major
            and career centered around it. <br />
            <br />
            Baralt, an associate professor and department chair of the women’s,
            gender, and sexuality studies at Long Beach State University, is
            striving for a better future for all. While attending college at the
            University of Florida, she changed her major at least four times
            until she finally settled on political science. <br />
            <br />
            “Then I took a women’s studies class and at that time, it was just
            called women’s studies,” Baralt said. “I loved it like I was excited
            to do my readings and to write things and I felt like it was
            relevant to my life.”
            <br />
            <br />
            Even though Baralt had found her calling, she still had a very
            conservative family who would never approve of her being a women’s
            studies major. So, she decided to double major instead of telling
            them that she switched. <br />
            <br />
            After graduating, she had no idea what she wanted to do, so like
            many other lost students who have graduated, she went to grad
            school. Once she received her graduate degree in sociology, Baralt
            ended up taking a position at LBSU and has been with Long Beach
            since 2009. <br />
            <br />
            Now, Baralt is set on helping students fall in love with women’s,
            gender, and sexuality studies like she did when she was in college.{" "}
            <br />
            <br />
            “I don’t even remember what class it was but I remember the books,”
            Baralt said. “It resonated with me deeply, and it gave language to
            things I saw and knew were wrong, but if I said it to my parents,
            they would tell me, ‘that’s just how it is.’”
            <br />
            <br />
            As a professor, she feels like she is helping make a difference in
            the world and that it is very rewarding. Of course, the pandemic has
            made it difficult to connect with students and see them have the
            experience that she once did. <br />
            <br />
            Through the years that Baralt has been at LBSU, her favorite classes
            to teach are the introduction class, gender, race, sex, and the
            body, as well as the reproductive justice course. <br />
            <br />
            “Students are already really passionate about the topic
            (reproductive justice) and we get to delve into it together and
            think about social change,” Baralt said. “I love that class every
            single time.” <br />
            <br />
            According to Baralt, she believes it is important for this subject
            to be taught because it is relevant to everything and it allows
            everyone to understand their place in the world. She also hopes it
            will help guide people in dismantling structural inequalities. Not
            only is she teaching the facts, but Baralt is also exploring
            frameworks and helping others create change through activism. <br />
            <br />
            She hopes that those who leave her class become agents of change
            either by taking what they’ve learned and applying it to their
            professions or going into direct activism. <br />
            <br />
            “To me, it is allowing students to look at the world through a very
            critical lens so they can apply it to everything else they do,”
            Baralt said. “I can’t imagine how it wouldn’t be important.” <br />
            <br />
            When Baralt feels as though the world is failing and there isn’t
            much hope, she looks to her students and she can’t help but feel a
            lot of hope for the future, knowing they will be in it. As far as
            activism and taking charge, Baralt has done plenty of research for
            women and social justice over the years, from her dissertation on
            breast cancer activism with a critical lens on the model of breast
            cancer advocacy in the U.S. to serving on the board of Breast Cancer
            Action. <br />
            <br />
            This semester, Baralt is currently not teaching in order to do
            research on parenting and caregiving as academics during the
            pandemic. <br />
            <br />
            “School’s were closed, and daycares were closed, but our jobs didn’t
            really stop,” Baralt said. “There is already a gender equity gap
            among women of color and women parents, and COVID made it worse than
            it already was.” <br />
            <br />
            Baralt has also been very focused on seeing what is important to
            students at LBSU. In fact, she has collected data on the
            reproductive health of students and what they need from the
            university. <br />
            <br />
            According to Baralt, there is a place for everyone in social
            movements, even for those who don’t like being loud. You can take
            charge and get involved in all kinds of ways, whether it be making
            spreadsheets or making signs. Baralt recommends finding your people
            and then finding your place. <br />
            <br />
            “Students should know that we exist and that we are here,” Baralt
            said. “We are a resource to the campus.” <br />
            <br />
          </p>
        </>
      );
    },
  },

  {
    magazine_id: 31,
    magazine_img: "/image/MagazineArticles/Culture/culture6.png",
    magazine_name: "EMBRACING FEMININITY, TAKING DOWN THE PATRIARCHY",
    magazine_type: "culture",
    magazine_titleData: `Growing up, I wanted nothing more than to be what I thought my dad wanted me to be, a boy. I knew I could never be that so I strived for the next best thing, being a tomboy.`,
    magazine_writer: "Lauren Galvan",
    magazine_data: () => {
      return (
        <>
          <p>
            Growing up, I wanted nothing more than to be what I thought my dad
            wanted me to be, a boy. I knew I could never be that so I strived
            for the next best thing, being a tomboy. <br />
            <br />
            For as long as I could remember, being feminine was frowned upon by
            those who were around me. So anything that was considered a trait of
            a girl, I got rid of to the best of my ability. I didn’t cry, I
            didn’t wear pink, and I especially didn’t wear dresses. <br />
            <br />
            I remember disliking others who were too “girly” and expressed their
            feminine side. Looking back, I think I was jealous because they were
            allowed to be what I couldn’t: themselves. The girl I deemed my
            “enemy” was the one who wore pink every day, put on makeup, or in
            her case, lipgloss and glitter from Claire’s, and she wasn’t afraid
            to let her emotions shine. She might never know that it wasn’t her
            fault and that it was my issues overtaking me. <br />
            <br />
            I surrounded myself with friends who dressed like me and boys who I
            played sports with. In my mind, if I hung out with boys and was able
            to fit in, it was like I was one of them. If I was good enough to be
            in their friend group, I would be good enough for my father. I would
            later learn that nothing was ever going to be good enough. <br />
            <br />
            I let my grades slip and I put everything into being the best on an
            all-boys baseball team. I felt like I had something to prove. It was
            a hell of a lot of work just to have him show up to one of my games
            every season. <br />
            <br />
            It worked for a while, but when middle school came around,
            everything changed. I changed. Even though middle school was an
            awkward time for all, it was when I started to dress how I wanted
            and express myself more than ever. <br />
            <br />
            Around this time, I moved in with my mother and realized that I
            didn’t have to change myself to earn love from a parent. With my
            mother, I was my most authentic self and I was the happiest I had
            ever been. She taught me how to never take any crap from any man and
            to never back down from a staring contest with a guy who is trying
            to make you feel uncomfortable. <br />
            <br />
            When I was young, I was always led to believe that being a woman was
            a weakness and showing just a hint of femininity would be my
            downfall. However when I got to high school, I quickly learned that
            femininity didn’t equal fragility, and being a woman came with more
            power than I had ever realized. <br />
            <br />
            In high school, I found myself surrounded by female teachers who
            radiated badass energy and held so much power in their hands. My
            male classmates and heck, even the male teachers at my school feared
            them and felt threatened by them. Seeing their strength made me
            realize why men want women to reject themselves and cast away their
            feminine sides. If all women knew the control they could have over
            themselves and others, we could take over the world. We would be
            unstoppable. Most importantly, we wouldn’t need men anymore. <br />
            <br />
            I’ve come to understand that being like “one of the guys” and
            viewing other women as the enemy was embedded into my brain by a man
            who didn’t want to see me succeed. Now in the age of social media,
            I’ve seen solidarity and empowerment between women, especially on
            TikTok. I remember the times when I would roll my eyes and pretend
            to gag anytime I thought someone was being “too girly,” but now I
            cheer them on and smile. <br />
            <br />
            Growing up for me meant learning that I didn’t have to change and
            hide my true self in order to please men. I know now that I can
            dress however I want and show as much emotion as I please because
            pleasing men is the last thing women should care about. <br />
            <br />
            If you’re scared of women rising up against the patriarchy, taking
            over, and creating the perfect utopia that is safe for women
            everywhere, just say that.
            <br />
            <br />
          </p>
        </>
      );
    },
  },

  {
    magazine_id: 30,
    magazine_img: "/image/MagazineArticles/Culture/culture7.png",
    magazine_name: "A CHRISTMAS FULL OF PAINT AND GIFT BASKETS",
    magazine_type: "culture",
    magazine_titleData: `The time has come! Christmas is around the corner and people are getting ready to crack open their piggy banks. As we all know, the holidays are not cheap and the pandemic has made it difficult to save any extra money for gifts this year. Speaking for myself, I only started working during the summer once I was fully vaccinated. With the little time we have, it’s time to get creative and make a budget for holiday shopping.`,
    magazine_writer: "Carlos Fuentes",
    magazine_data: () => {
      return (
        <>
          <p>
            The time has come! Christmas is around the corner and people are
            getting ready to crack open their piggy banks. As we all know, the
            holidays are not cheap and the pandemic has made it difficult to
            save any extra money for gifts this year. Speaking for myself, I
            only started working during the summer once I was fully vaccinated.
            With the little time we have, it’s time to get creative and make a
            budget for holiday shopping. <br />
            The holidays are a time where people start invading malls left and
            right. Buying gifts at the mall isn’t necessarily the cheapest
            option either, but hitting up your local Marshalls or Ross is
            perfect for saving money. Moreover, it’ll save you the energy
            fighting over the last overpriced scarf at Forever 21. When shopping
            at Marshalls or Ross, think about the different gift baskets one can
            make. Your loved ones are now receiving multiple items, for a lower
            cost, rather than getting a sweater they’ll probably only use once.
            This season, gift baskets are a great idea because shoppers can
            personalize each basket to their respected loved one and it won’t
            cost them their left kidney when done at local stores. <br />
            A gift’s value isn’t defined by the amount a person spends.
            Sometimes we don’t have that extra money to buy AirPods or a new
            Canon camera. The real value of a present comes from the thought and
            effort one makes. Remember, it’s time to get creative this year and
            what’s a better way than to create something. If a picture is worth
            a thousand words then a handmade painting is worth two thousand. All
            you need is a stack of canvases, that you can get for a low cost at
            the 99 cents store or Ross, and a set of paint and brushes. Imagine
            how fun it would be to just set yourself up and create something
            special and meaningful. Try this and you’ll be known as the new
            Picasso around the house!
            <br />
            Every year on Christmas a lot of money goes towards wrapping paper.
            Instead of traditional wrapping paper, save as much newspaper you
            receive in the mail and make a variety of wrapped presents. There is
            no need to go to Target or Walmart for wrapping paper that’ll be
            ripped up and trashed. Nevertheless, if the cute wrapping paper is a
            must this season, saving up on plain paper bags can be a perfect
            substitution. Paper bags, that you can get while shopping at your
            local grocery store, can be written and colored on. Once again there
            is room for creativity to prosper this Christmas; we just have to
            use the resources available around us! <br />
            The holidays are filled with love, food, and presents. There is this
            pressure created by capitalism that tells us to spend as much as we
            can, but the reality is not everyone has money to spend on big
            expensive gifts. Holidays are over commercialized to benefit large
            companies; it allows the little voice in our heads to persuade us
            into going practically bankrupt. Save money and give out gifts that
            are more meaningful; I promise your bank account will thank you
            later! People are out shopping at malls making their wallets cry. I
            don’t plan to be one of those shoppers this year. Will you?
            <br />
          </p>
        </>
      );
    },
  },

  {
    magazine_id: 29,
    magazine_img: "/image/MagazineArticles/Culture/culture8.jpg",
    magazine_name: "TAX-FILING SEASON IS A SCAM—DON’T BE A SHEEP!",
    magazine_type: "culture",
    magazine_titleData: `Too many people have too many opinions; capitalism, consumerism, they’re all part of the many problems that affect us all. Yet, we seldom have the means to enact change on our own. If only we listened to experts, instead of relying on word of mouth from strangers on social media, then perhaps many problems could be solved. But one of the oldest constants in human history regards the most dreaded word in the dictionary: taxes. For thousands of years, nobody has been able to crack this topic, so that’s why I, a liberal arts major, am exactly the person to ask the hard questions.`,
    magazine_writer: "Ethan Lauren",
    magazine_data: () => {
      return (
        <>
          <p>
            Too many people have too many opinions; capitalism, consumerism,
            they’re all part of the many problems that affect us all. Yet, we
            seldom have the means to enact change on our own. If only we
            listened to experts, instead of relying on word of mouth from
            strangers on social media, then perhaps many problems could be
            solved. But one of the oldest constants in human history regards the
            most dreaded word in the dictionary: taxes. For thousands of years,
            nobody has been able to crack this topic, so that’s why I, a liberal
            arts major, am exactly the person to ask the hard questions.
            <br />
            What’s the deal with taxes, anyway? We all work hard for our money.
            Look at the many incredible articles in this issue, such as Carlos
            Fuentes’ one on holiday gifts—and in my unbiased opinion—the article
            about saving money as a student. That’s why it is such a shame when
            we must take time out of our busy lives to file taxes when we should
            be shopping.
            <br />
            The government is like a bad friend who you loan money to, but then
            feel forced to ask for it back. And for those who owe money come tax
            season, isn’t the stress of finding that money enough? You know
            they’re good for it, so why do they make you jump through hoops just
            to get back your own money every year? They work for our own
            benefit, so the burden should be on them. <br />
            However, a bunch of crooks got together and promised that they would
            con all of us citizens by making the onus on us, instead. H&amp;R
            Block, more like H&amp;R… Blockheads. We’ve got work, and kids to
            feed, and movies to stream, we should not be required to pay
            corporations money to sit in those too-small, plastic chairs as
            terrible music blares. And it is insane that if you make a mistake
            they’ll come after you. You might make a small error, and then
            you’re forced to go through legal hoops. I don’t even know how you
            get a lawyer, do you just drive around looking for a bus bench ad?{" "}
            <br />
            But there is a way to alleviate these problems. The government must
            maintain free websites to submit your tax records, albeit with a
            small filing fee. For example, visit www.freetaxusa.com and easily
            input any information asked. I have no proof, but I’m pretty sure
            that the people in charge of these tax-filing rackets want it to
            seem like a scary process, when in fact it’s completely manageable
            on a quiet afternoon, relaxing in pajamas in the comfort of your own
            bed.
            <br />
            Additionally, depending on your location, there are always services
            available with trained representatives to help you file for free if
            you make it under a certain bracket. Look up the Volunteer Income
            Tax Assistance program near you. <br />
            The best day of my life is going to be when that H&amp;R Block
            across my street finally closes and they take down that silly cutout
            of a man smiling in such a smug manner. Don’t be scared of taxes,
            and whether you’re meant to pay or to get money back, you’ll be
            better off learning for yourself how to file.
          </p>
        </>
      );
    },
  },

  {
    magazine_id: 28,
    magazine_img: "/image/MagazineArticles/Opinion/opinion1.jpg",
    magazine_name:
      "LOS ANGELES TOURISM INDUSTRY REBOUNDING BUT WORKERS CONTINUE TO STRUGGLE",
    magazine_type: "opinion",
    magazine_titleData: `The tourism industry in Los Angeles has always been a significant contributor
    to the city's economy. However, while the industry is rebounding to
    pre-pandemic levels, workers in hotels and at LAX are struggling to make ends
    meet.`,
    magazine_writer: "Marcos Banuelos",
    magazine_data: () => {
      return (
        <>
          <p>
            The tourism industry in Los Angeles has always been a significant
            contributor to the city's economy. However, while the industry is
            rebounding to pre-pandemic levels, workers in hotels and at LAX are
            struggling to make ends meet.
          </p>
          <p>
            During the pandemic, the tourism industry benefited from massive
            bailouts, including $13 billion in PPP loans to the hotel industry
            and over $45 billion in total government support to the top five
            domestic airline carriers through the CARES Act's Payroll Support
            Program (PSP). Contractors and subcontractors received an additional
            $5 billion.
          </p>
          <p>
            Now, as the industry prepares to host the 2026 World Cup and the
            2028 Olympics, the City is investing in its future growth. Los
            Angeles is leading the nation in new hotel rooms, and LAX is
            investing $6 billion in an expansion.
          </p>
          <p>
            Unfortunately, the workers who keep the tourism industry
            functioning, safe, and profitable, including hotel housekeepers, LAX
            janitors and security guards, airplane cabin cleaners, airline
            catering workers, airline passenger service workers, LAX restaurant
            and retail workers, and others, are facing housing insecurity. Los
            Angeles is grappling with an unprecedented housing and homelessness
            crisis, and these workers are at risk of losing their homes.
          </p>
          <p>
            While these workers benefit from the Los Angeles Living Wage
            Ordinance (LWO) and the Los Angeles Hotel Worker Minimum Wage
            Ordinance (LA HWMO), the wage rates have not kept up with the rising
            cost of living in Los Angeles. As a result, over 36,000 people in
            Los Angeles and their families are struggling to make ends meet.
          </p>
          <p>
            Moreover, workers under the LWO have not seen their healthcare
            benefits keep up with rising healthcare costs. Many tourism workers
            and their families lack quality, affordable health insurance.
          </p>
          <p>
            Additionally, workers in the tourism industry are uniquely
            positioned to identify or respond to emergencies or threats.
            However, a lack of appropriate training can place both workers and
            the public at risk. A trained housekeeping workforce is needed to
            protect the health and safety of both workers and the public from
            crimes such as human trafficking, domestic, and sexual violence.
          </p>
          <p>
            Therefore, the City Council should instruct the Chief Legislative
            Analyst to report with an analysis, including the economic impacts,
            of amending the LWO and LA HWMO to raise the hourly wage to $25 in
            2023, and by $1 every year thereafter, to reach $30 an hour by 2028.
            The Council should also adjust the healthcare credit to meet the
            average cost of healthcare coverage, add minimum health benefit
            requirements, including family coverage, and require transparency
            around healthcare payments.
          </p>
          <p>
            The Council should ensure that workers receive all eligible paid
            time off and sick days under the LWO, LA HWMO, and the Citywide
            Minimum Wage. Moreover, the Council should add a Public Housekeeping
            Training requirement to the LA HWMO, similar to the ordinances in
            Santa Monica and West Hollywood.
          </p>
          <p>
            Finally, the Council should clarify that enforcement of the LWO and
            LA HWMO ordinances shall be conducted by the City's Office of Wage
            Standards. In conclusion, the tourism industry is rebounding in Los
            Angeles, but workers are still struggling to make ends meet. The
            City Council must take action to ensure that these workers receive
            fair wages, healthcare benefits, and adequate training to protect
            themselves and the public.
          </p>{" "}
        </>
      );
    },
  },

  {
    magazine_id: 27,
    magazine_img: "/image/MagazineArticles/Opinion/opinion2.jpg",
    magazine_name: "AMERICAN DREAM OR AMERICAN NIGHTMARE?",
    magazine_type: "opinion",
    magazine_titleData: `Many foreigners grow up hearing about how America is the greatest country in
    the world, and that anyone can come here and achieve their dreams. For many of
    our ancestors, this was true, but is it different now?`,
    magazine_writer: "Jessica Sutter",
    magazine_data: () => {
      return (
        <>
          <p>
            Many foreigners grow up hearing about how America is the greatest
            country in the world, and that anyone can come here and achieve
            their dreams. For many of our ancestors, this was true, but is it
            different now?
          </p>
          <p>
            Nike Takano, a good friend of mine, fashion designer and student,
            moved here from Japan expecting Hollywood glitz and glamor, but was
            greeted with a poor education system and faced with homelessness.
            When he told me he was making the decision to go back, I was sad and
            surprised. Instinctually I wanted to ask “why?”, but before the
            words could leave my mouth, I was met with an influx of reasons in
            my mind as to why he wouldn’t want to be here. Our country has
            several problems that need to be dealt with and it isn’t as easy to
            navigate as it was in the past. I sat down with him and asked what
            exactly he thinks is wrong with the country, how it was different
            from what he expected, and what factors played into his decision to
            leave.
          </p>
          <p>
            Both of Nike’s parents work at a U.S. Army base in Japan, so
            American patriotism and propaganda wasn’t something he was
            unfamiliar with. When asked how Japanese people view America, he
            said “They love it, they control the people to like America that's
            why. If something happens in Japan, America always helps us, if
            China attacks us, we rely on America to save us. America is a big
            back up for us.” Nike made his first significant move at a young age
            when he chose to go to a high school in New Zealand. He was
            initially interested in Marine Biology before discovering his love
            of fashion. Gradually learning English and meeting new friends, Nike
            realized he loved the freedom of living in a country where he could
            be whoever he wanted to. He was introduced to drugs, partying, and a
            wildly different social culture that he fell in love with. When
            considering colleges, his advisor told him Los Angeles would be the
            perfect place for him to study new, innovative fashion. Nike had
            seen American life through the lens of movies like American Pie;
            filled with crazy partying, sex, drugs, alcohol, and most of all,
            freedom. The media frames Los Angeles as a place where anyone can
            become famous at the drop of a hat, so he came here expecting big
            things, and what he was met with was quite the opposite of what he
            expected.
          </p>
          <p>
            Upon arriving in Los Angeles, he stayed a few weeks in a hotel while
            waiting to move into his dorm at Orange Coast College (OCC). He was
            told when he arrived at the college that they couldn’t process his
            dorm request, and the only other room available was a private dorm
            room for $2,000 a month, which was far above his budget. A couple of
            weeks into his U.S. arrival, Nike was already denied the “crazy”
            American dorm life he had dreamed of, and was now faced with
            homelessness. He ended up meeting another international student
            without a place to stay, and they lived in his hotel together for
            weeks, while looking for permanent housing. They searched for
            affordable housing, but were unsuccessful. Nike stayed with various
            people that he met through dating apps or classes. During this time,
            he did achieve one American dream: getting his dream car, a Toyota
            86. Once he got his car, it became his new home.
          </p>
          <p>
            Even though this sounds like it would be a hard time for anyone to
            go through, he was still elated to be here. “At the time, I didn't
            really care about the money, because I was just happy to be living a
            crazy life. Like it's something I couldn't do in Japan, sleep in my
            car. It was my first dream car, so it was fun. I had a sheet folding
            into a tent. I subscribed to a gym and they had a shower and sauna
            and everything.” he explained.
          </p>
          <p>
            Nike tried to find work, but without a valid green card, found his
            choices limited. Employers that did agree to hire him and pay him
            “under the table”, often took advantage of him, worked him long
            hours and sometimes didn’t pay him all his wages. He jumped between
            multiple jobs during his stay here, which made it hard for him to
            focus on his studies.
          </p>
          <p>
            At school, he attracted many more friends with his outstanding sense
            of style. He was by far the best dressed in the fashion program at
            OCC, catching the eyes of many. People were sympathetic to his
            situation, so he jumped between a few different classmates' homes,
            including mine, where he ended up staying until he left the country.
            He was never completely sure where he would end up and for how long,
            and he felt most Americans were unreliable.
          </p>
          <p>
            Feeling lost and stressed with work, living situations, navigating a
            new country, and going to school all at once, he was finally faced
            with the reality of what it is like to live here. All of the stress
            from just trying to eat and have a place to rest his head was
            hindering his ability to learn to his full potential. On top of
            that, he felt that the education system was extremely lacking
            compared to New Zealand or Japan. After experiencing student life in
            America, he said “I feel that many Americans are not well educated.
            I think the school system is not right. In America, everyone just
            goes to school and takes boring classes, but New Zealand is
            different. Math and English are mandatory until the second year of
            high school, but after that, everyone can take any classes they
            want, so everyone can think about what kind of career they want to
            pursue in the future from the time they are in high school.
            Therefore, those who go to college can concentrate on what they
            really want to do.” Because of the flexibility of this school
            system, Nike was able to take a fashion class and discover his
            passion early on, allowing him to start planning his future early.
            It’s easy to say Nike has many critiques on this country after
            experiencing the reality of what it’s like to try to survive here,
            yet he doesn’t regret coming here.
          </p>
          <p>
            Even through all this hardship, he felt like it was fun to live
            here, but it wasn’t best for him and his goals anymore. He said,
            “It's fun to live here, it's like a dream; driving my car crazy,
            doing weed, doing drugs, but that's what I shouldn't do. I came here
            to become a fashion designer.” Nike realized there was too much
            temptation at his disposal here and he knew he needed more structure
            to achieve his goals.
          </p>
          <p>
            Even with his critiques, he still says “My life has certainly
            changed since coming to the U.S. My life now will be less boring
            than other college students, but I am investing in my future.” For
            Nike, heading back home to where he has a place to stay and feels
            like he can focus, is a better idea at this point in his life. “I
            returned to Japan because I thought the U.S. would have a negative
            impact on my life,” he said. “Weed and cars can be done anytime, but
            I am 21 years old. I don't want to waste my life on drugs and cars.
            That's something I can do when I'm rich. There are priorities in
            this world. Right now my priorities are making money and honing my
            skills.”
          </p>
          <p>
            Nike is now back home, taking Artificial Intelligence and Special FX
            classes at the Digital Hollywood University. Next year he plans to
            enter the fashion program at ESMOD International and start his own
            online business. He plans to come back and visit his friends here
            once a year, and is considering moving back here once he is done
            with school and has made his fortune. He emphasizes that although he
            does not hate the country, he knows this isn’t a country where he
            will be able to successfully complete his goals without the
            distractions and hardships of living here hindering him.
          </p>
          <p>
            This story is a firsthand account of how growing up with American
            propaganda affects people’s perception and what happens when you
            make such a big move and find out not all of what you dreamed about
            was true. Many people from more strict and structured countries are
            drawn to America for the freedoms it allows; however, the reality of
            living in this free country can be much different than what it
            seems. Through his experience, Nike’s learned that the amount of
            temptation in a “free” country can easily lead to distractions and
            bad decisions that can lead him away from the things most important
            to him. It is crucial we listen to foreigners' perception and
            experience here, and that we see America from all different
            perspectives, not just our own.
          </p>{" "}
        </>
      );
    },
  },

  {
    magazine_id: 26,
    magazine_img: "/image/MagazineArticles/Opinion/opinion3.jpg",
    magazine_name: "DR STRANGE IN THE MULTIVERSE OF MADNESS REVIEW",
    magazine_type: "opinion",
    magazine_titleData: `“Dr Strange in the Multiverse of Madness” is exactly that: MADNESS. From the first frame to the last, it pulls you in right away, starting this thrilling journey through the Marvel multiverse and never lets up.`,
    magazine_writer: "Sean Lawrence",
    magazine_data: () => {
      return (
        <>
          {" "}
          <p>
            “Dr Strange in the Multiverse of Madness” is exactly that: MADNESS.
            From the first frame to the last, it pulls you in right away,
            starting this thrilling journey through the Marvel multiverse and
            never lets up. Sometimes there is too much exposition and explaining
            of complex multiversal concepts and it can be confusing sometimes.
            However, director Sam Raimi (the “Spider-Man” trilogy) and writer
            Michael Waldron (“Loki”, “Rick &amp; Morty”) are mostly
            laser-focused on developing the main storylines about Dr Strange,
            Wanda and America Chavez fully from start to finish. The story has
            deep, meaningful themes of loss, grief, gratitude and acceptance of
            reality – messages that are especially relevant now. There are a
            reasonable amount of surprising cameos, but not too many and they
            don’t take away or distract from the story, but enhance it. <br />{" "}
            <br />
            This enticing film has some of the darkest but most dazzling scenes
            and visual effects that one would expect from a great Marvel
            Cinematic Universe entry, and the action sequences are intense and
            shocking. This film is indeed the MCU’s first horror film, and is
            the scariest, grossest, and most bloody and violent entry in this
            landmark franchise, including “Moon Knight.” Doctor Strange 2 has
            demons, possessions, zombies, monsters, evil witches and wizards,
            jump scares, and gory deaths. This is fitting, because Mr. Raimi
            scared us with his “Evil Dead” franchise, and is combining his
            unique horror and comedy style with his superhero movie experience
            from his historic “Spider-Man” trilogy to make this harrowing but
            beautiful work of art. However, this is still a Disney movie, and
            has the same fear factor as Disney’s “Pirates of the Caribbean”
            franchise, where it dips its toes into horror but it doesn't go all
            the way there. As its PG-13 rating states, parents are highly
            recommended to only bring children above 13 years old, and to know
            what their kids are OK and not OK with. There are occasionally funny
            scenes with humor and weird aesthetics reminiscent of the hilarious
            and excellent “Thor: Ragnarok”, as comic relief for all this
            darkness. <br /> <br />
            Regardless of that, anyone in the audience can enjoy Doctor Strange
            2, but if they don’t rewatch the countless MCU movies and shows to
            prepare for it, such as Dr. Strange 1, “Avengers: Infinity War”,
            “Avengers: Endgame”, “WandaVision,” “Loki,” and “Spider-Man: No Way
            Home,” they won’t understand everything that is happening, and that
            could take away from the experience. This is a problem with the MCU
            – that it is so hard for the average consumer to keep track of all
            these plot details now. Each MCU entry feels like something we need
            to watch to not get confused with the story later, more than as
            something we want to watch of our own choice. However, there are
            recap videos on YouTube, and anyone who watches those while avoiding
            the spoilers will have an optimal experience with Doctor Strange 2.{" "}
            <br /> <br />
            In my overall gradings of MCU films, since I have given “Avengers:
            Infinity War,” “Avengers: Endgame,” “Spider-Man: No Way Home,” and
            “Black Panther” an A grade, I would give “Dr Strange in the
            Multiverse of Madness'' a solid B. It might scare or confuse you,
            but the action, visual effects, cameos and occasional jokes will
            delight you, and its heartbreaking moments will make you cry. I
            highly recommend it. <br />
          </p>{" "}
        </>
      );
    },
  },

  {
    magazine_id: 25,
    magazine_img: "/image/MagazineArticles/Opinion/opinion4.jpg",
    magazine_name: "WHAT I WISH I DID BEFORE GRADUATING",
    magazine_type: "opinion",
    magazine_titleData: `As I near the end of my college career, my eyes are finally open to the different options that the Long Beach State campus has to offer, and I have never felt such remorse.`,
    magazine_writer: "Lauren Galvan",
    magazine_data: () => {
      return (
        <>
          {" "}
          <p>
            As I near the end of my college career, my eyes are finally open to
            the different options that the Long Beach State campus has to offer,
            and I have never felt such remorse. I look around and see the
            endless possibilities that are offered here, and I have done close
            to none of them. They range from rock climbing to tap dancing
            classes, and the list is ever-growing. <br />
            <br />
            All of the free services and benefits that being a college student
            gets you were lost to me. I had no idea I was missing out on so much
            until I started to make a list of all the things I wish I had done
            in my college career. <br />
            <br />
            It feels like time flew by so fast and I zoomed out of here as
            quickly as I could. Was it because I didn’t have time? Or did I just
            think I didn’t have time? I was in such a rush to graduate that I
            didn’t realize that I was missing out on the fun around me. There
            was so much potential for me and yet, I didn’t take advantage of the
            social aspect of college. I grew up playing sports, but I didn’t
            step foot on a field unless I was covering a game for a class.{" "}
            <br />
            <br />
            With an open mind, I see all the things I could have participated
            in. If I would have known I would feel this regret, I would have
            joined a club or, who knows, tried out for the rugby team and gotten
            the wind knocked out of me. But, at least I would have tried. <br />
            <br />
            Here I sit, with so many regrets and no time to fulfill my dreams of
            learning how to tap dance. Ok, it isn’t necessarily a dream of mine,
            but it would have been nice to learn something new. Something to
            help me get out of my little bubble and daily routine of school,
            work, sleep, repeat. <br />
            <br />
            Now, with less than two weeks until I graduate, I feel dread and
            disbelief that I let all this time go to waste. These are my final
            days on campus, my final days being a Long Beach student, and I have
            nothing to show for it. I wrote for my school’s magazine and got a
            couple of awards for it, but I never strayed too far from my
            journalism major. <br />
            <br />
            When it comes to classes I wish I had taken, there are at least a
            dozen that I regret not taking. However, one of the most interesting
            and relevant subjects that I wish I had gotten more into is the
            Women’s, Gender, and Sexuality studies program. As I’ve grown, I
            realize how important this topic is to me, and how relevant it is to
            the world around me. I could have even minored in the subject and
            yet, I chose to rush through college like it was a race. Against
            who? I have no idea. <br />
            <br />
            What I do know is that I feel like I let others influence the way my
            college experience went. I could have taken the fun classes but
            instead, I did what I thought my family and my guidance counselors
            wanted, which was for me to take the classes I needed to graduate as
            soon as possible. <br />
            <br />
            I hear everyone talk about how extraordinary their experiences were
            at LBSU and how their teammates are their families that will be in
            their lives forever. Then there are people who talk about the fun
            classes they took over the course of their four years and here I am
            with my most interesting class being the geology 101 class I had to
            take to graduate. <br />
            <br />
            I'm happy to be graduating, of course, but that doesn’t mean I can’t
            feel remorse for the things I wish I did. I mourn my college career
            and can only hope that I make the best of the rest of my life.{" "}
            <br />
            <br />
            Future students, I hope you learn from my mistakes. I hope you don’t
            listen to the people saying you have to blast through college with
            nothing but academic achievements. I hope you join clubs and attend
            socials and make lifelong friendships that don’t fade away after you
            graduate. I hope you don’t wish there was something you did before
            you graduated. I hope you have no regrets. <br />
            <br />
          </p>{" "}
        </>
      );
    },
  },

  {
    magazine_id: 24,
    magazine_img: "/image/MagazineArticles/Opinion/opinion5.jpg",
    magazine_name: "HOW TO CONQUER YOUR FEARS",
    magazine_type: "opinion",
    magazine_titleData: `Before I start, trust me, I know what I’m talking about. I know what you’re going through when you question whether or not you can do it. You can. Trust me, but before you do…this thing that you fear to do, let me give you some pointers.`,
    magazine_writer: "Kamryn Bouyett",
    magazine_data: () => {
      return (
        <>
          {" "}
          <p>
            Before I start, trust me, I know what I’m talking about. I know what
            you’re going through when you question whether or not you can do it.
            You can. Trust me, but before you do…this thing that you fear to do,
            let me give you some pointers. <br />
            <br />
            Your teachers, mentors, advisors will tell you to strive for a goal.
            Writing down these short term or long term goals will help you focus
            on what you want in the end. Has this ever helped though? Don’t do
            that, don’t have a goal at all. Think about the thing that you want
            to do, whether it's academically, physically or mentally and think
            about what would happen if you don’t do it. If you don’t study for
            that test, get up early for practice, or attend your weekly therapy
            sessions. You feel that overwhelming sensation of failure? Yes, that
            is what you need to conquer your fears. <br />
            Use that sensation to focus. To ease your anxiety. <br />
            <br />
            Let me use an example for the ones that still don’t understand.
            You’re afraid of heights. That feeling when you’re in an airplane is
            very unsettling and causes issues with your desire to travel. It
            started when you were young when you fell off of the top of the
            swing set trying to impress everyone with your climbing skills.
            Alright, take that failure of not being able to climb the swing set.
            Think about what your mom said when you came home with a bruised
            knee and the disappointment in your mom's tone. Take that and apply
            it to your fear of traveling in an airplane. If I can’t anxiously
            ride this airplane what will my mom think? If I don’t ride this
            airplane what is the worst thing that can happen? Yes, it could
            crash but really think…is that the worst?
            <br />
            <br />
            A slight variation of this tactic would be having confidence. I can
            go on about how to have confidence but in this situation let's
            pretend that you have it already. Use this confidence and tell
            yourself that you can’t fail because you’re you. You're the baddest
            female dog ever, you’re the hottest tamale and you can conquer that
            fear. I do this everyday, so I can continue to walk the Earth with
            no fear. I look in the mirror and tell myself I am the best,
            hottest, good looking-est woman that has ever walked the Earth
            before I set foot in anyone's presence. What do you say in the
            mirror? Have you conquered your fears yet? Let’s continue to the
            people in your life.
            <br />
            <br />
            This is one of the most important aspects in conquering your fears
            in the long run. You need people around you who are the same as you.
            You need to remember you are the best, no one else can top you, but
            with the best comes the worst. If you have people who go against
            your morals, or judgment, or who don’t even know you they will not
            help you. Instead these people will make you even more afraid of the
            things you want to achieve. They will compete with you on your
            successes, take your lingo, they can even exhaust your time, and
            your mental health with their negativity ultimately limiting your
            power to conquer your fears. Sadly I’ll have to bring an ex-partner
            into this. His presence was charismatic (that's how they get you),
            loving and kind but underneath it all he was the most controlling
            person ever. It was tough trying to conquer my fear of writing. Of
            course he didn’t do this intentionally, he just slowly convinced me
            that my desire to conquer this fear was unattainable (which is
            always untrue). Find your best people, avoid the “worst” and stick
            with them as you conquer your fears…trust me you will conquer them.{" "}
            <br />
            <br />
            Let's talk about a special case of fear. Money. I know a lot of
            individuals have this overwhelming urge to either hoard or overspend
            their money causing them to feel fearful in losing it or using it.
            Money does not matter. Say that 10 times fast. In history class we
            were learning about how tombs would hold special items of the dead
            so they could have them in their after life (I think). These
            Egyptians had gold, jewelry, their favorite items of clothing, etc…
            but they’re dead (rip). What I’m trying to say is there is no point
            in being fearful of money. It holds absolutely no value when you’re
            gone (lol maybe a little). I know you’re smart enough to know how to
            use money, I’m not talking about not being responsible. I’m only
            talking about the thought of money and its possibilities. You will
            have some, you won’t have some, period.
            <br />
            <br />
            You must have a life motto. This motto will allow you to not only
            conquer your fears but it will push you to conquer many other fears
            you may have. Yolo is mine. Yolo stands for “You only live once”.
            When I go about my daily life, I sometimes come across decisions
            that I need to make immediately. Social anxiety is a big fear of
            mine. When people ask me out to eat, to come to a party or a social
            event my life motto comes into play- Yolo. I go just because I may
            not be able to see these people ever again. I also go back to my
            first tip. Thinking about what's the worst that could happen?
            <br />
            <br />
            Overall, overcoming your fears is all up to you. Each decision you
            make in regard to your fears will either hinder you or uplift you in
            the future. Yes, I will admit there will be days where you just want
            to cry, curl up in a ball and wonder why things never change but if
            you cower from anything that makes you uncomfortable you will never
            get to where you want to be. I ran away from my first grade crush
            and now I can’t even talk to someone I like without wanting to jump
            out of my skin. We need to put an end to childish behavior that is
            holding us back from achieving the impossible. <br />
            <br />
            Society will tell you to not take my advice. To continue to pass
            down the sad advice of entrepreneurs that make you pay to hear them
            speak. Trust me, my advice is free. Take my tips to heart for a week
            let me know if you don’t conquer your fears. <br />
            <br />
          </p>{" "}
        </>
      );
    },
  },

  {
    magazine_id: 23,
    magazine_img: "/image/MagazineArticles/Opinion/opinion6.jpg",
    magazine_name: "THERE ARE NO RIGHT CHOICES, BUT WAY TOO MANY WRONG ONES",
    magazine_type: "opinion",
    magazine_titleData: `Everyday, from the moment I wake up to when I fall asleep, I am bombarded with hundreds of decisions to make. Some trivial, some important. And with those decisions come a nearly infinite amount of options. In the morning, I wonder if I should park in the lot closest to my first class or to my last class. Do I want to take the 15 minute walk now or later? Or should I just be happy to get any spot at all after I leave late and spend an hour in traffic?`,
    magazine_writer: "Natalie Comfort",
    magazine_data: () => {
      return (
        <>
          {" "}
          <p>
            Everyday, from the moment I wake up to when I fall asleep, I am
            bombarded with hundreds of decisions to make. Some trivial, some
            important. And with those decisions come a nearly infinite amount of
            options. In the morning, I wonder if I should park in the lot
            closest to my first class or to my last class. Do I want to take the
            15 minute walk now or later? Or should I just be happy to get any
            spot at all after I leave late and spend an hour in traffic? <br />
            <br />
            Don’t even get me started on menus. Makes my head spin. If there are
            more than six different options, forget it. How am I supposed to
            know which two-seasoning combo I’d prefer with my chicken? I’ll take
            whatever the person next to me is getting. But these are minor
            choices. So I reassure myself that they don’t matter much. After
            all, it won't affect my entire life, right?
            <br />
            <br />
            Honestly, I’d always thought the larger choices in life would be
            more difficult, but at least there are a lot more obviously wrong
            choices in those departments. If I can’t decide what to do, I will
            at least have a general idea of what I most definitely shouldn’t do.
            It’s a bit like if you can’t be first, you can at least make sure
            you’re not last. If I can’t make the best choice, I can make sure
            I’m not driving myself towards catastrophe at every turn. The
            problem with small choices is you have no idea of knowing whether a
            small choice might be important until it’s much too late to go back
            and revise it. <br />
            <br />
            Also, unlike little choices, people have opinions on your
            life-altering choices, so at least there’s some guidance there. It
            may not be the highest quality guidance, but something is better
            than nothing. Your high school counselor thinks you should be an
            engineer because you didn’t totally fail your stats class. Your
            coworker is thoroughly convinced you should be an entertainer of
            some kind, since you made a joke that made her laugh once. The
            mother of the child you’ve watched at daycare thinks you would make
            a great teacher. Nobody cares whether I order the chicken or shrimp
            scampi at Olive Garden. I mean, I hardly even care. <br />
            <br />
            Even the bad advice can be helpful in some way, at least you have a
            direction to go in and then you can redirect later. And the
            consequences of larger decisions are easier to understand. They are
            logical in some way so if you want to work in a bank you would take
            an internship… in a bank. If you want to save a thousand dollars
            over the next three months, well, you just do the math. Small
            choices are a roll of a bagful of dice. You never know what you
            might end up with. <br />
            <br />
            I can’t help but think about how some little choices have effects
            that can multiply into massive effects over time. I can’t keep track
            of all the times an adult over the age of 40 has told me how some
            happenstance occurrence introduced them to their lifelong partner or
            the career of their dreams. It’s lovely to hear, almost inspiring,
            until you think about what would have happened if they weren’t
            reading their girlfriend’s favorite author in the metro. <br />
            <br />
            If that feels overwhelming already, we’ve forgotten to consider how
            those decisions will then open up another branch of decisions, and
            so on into infinity. Years from now, I could be sitting in a park
            somewhere, thinking that if I hadn’t missed my exit on the 405 that
            one fateful day in April 2022, I could be in a completely different
            place right now. I don’t want that type of responsibility on my
            shoulders. So maybe I’ll just flip a coin, or spin a wheel, or go to
            psychic. It probably won’t matter much in the long run anyway, not
            that there would be any way of knowing if it did.
            <br />
            <br />
          </p>{" "}
        </>
      );
    },
  },

  {
    magazine_id: 22,
    magazine_img: "/image/MagazineArticles/Opinion/opinion7.png",
    magazine_name: "THE DARK MASS OF NOTHING THAT IS MY FUTURE",
    magazine_type: "opinion",
    magazine_titleData: `It finally happened. My mother and I were talking in her room like we always do, just enjoying each other's company when she popped the anxiety-inducing question, “what are you going to do after you graduate?”`,
    magazine_writer: "Lauren Galvan",
    magazine_data: () => {
      return (
        <>
          {" "}
          <p>
            It finally happened. My mother and I were talking in her room like
            we always do, just enjoying each other's company when she popped the
            anxiety-inducing question, “what are you going to do after you
            graduate?”
            <br />
            <br />
            With graduation right around the corner, I find myself growing more
            and more worried every day. When my mother asked that question, I
            panicked and asked her if she was going to kick me out. I knew she
            wasn’t but I wanted to dodge the hard question as quickly as
            possible. <br />
            <br />
            A few days later, she brought it up again, genuinely curious about
            what I have planned for my future. Since then, I have been in a
            continuous spiral thinking about finally leaving the safety net that
            college provided me. <br />
            <br />I have been a student for 18 years, five of which were
            dedicated to being a college student. Being in school has provided
            me with a safe space that I knew I could always fall back on. It has
            given me a community of friends and professors who I knew would
            always be there. There has always been a certain feeling of comfort
            that came with being a full-time student. Who am I without that
            safety net? What will become of me when I finally leave this chapter
            of my life? <br />
            <br />
            There has always been something to advance to after a level of
            education. When you leave sixth grade, you go to middle school, and
            after that, you go to high school. You can even decide to carry on
            with college, and the options are endless when it comes to choosing
            the college and major you want to pursue. There is a lot to look
            forward to and a sea of things to explore. Even then, it comes with
            the familiarity of something we have been doing since we were
            children. <br />
            <br />
            As far back as I can remember, I know I have always loved writing.
            When I chose to become a journalism student, I decided that that was
            what I wanted to dedicate my higher education to. I know that I love
            the field I have chosen, so why do I find myself freaking out every
            time my future becomes the topic of conversation? It could have to
            do with my commitment issues, but that is for my therapist’s ears
            only. <br />
            <br />
            When I think about my future, my mind draws a blank, and I feel like
            I need to bury myself in my school work to help distract myself.
            Then, I find myself thinking about how I will no longer have school
            work to fall back on, and thus, the vicious cycle starts all over
            again. <br />
            <br />
            There are only a couple of weeks left until I finally get a
            handshake and my bachelor’s degree handed to me. Not only that, I
            have finals and projects that need to get done before I can even
            think about enjoying my graduation. And now, my mother has decided
            to plague my mind with the question I fear the most. <br />
            <br />
            For some reason, after all I have done to build up a decent resume,
            I feel like it isn’t enough and that my portfolio, which is filled
            with five years' worth of articles, won’t help me get a job.
            Journalism is one of those fields where you always get asked, “what
            are you going to do with a degree like that?” or, my personal
            favorite, “is that even a real major?” Even though I will defend my
            choice until I am blue in the face, those questions eat away at me
            and haunt my mind. What am I going to do with my degree? <br />
            <br />
            The answer: I have no idea, but I know that I have people who will
            support me and my decisions no matter what. It is strange, knowing
            that I can apply to any job and look qualified on paper but feel
            like I have no business being in the field yet. <br />
            <br />
            I have to remind myself that, even though I don’t have a job lined
            up for after I graduate, that doesn’t mean that I won’t find one,
            and it doesn’t mean that I’m not good enough to find a place that
            will hire me. Self-worth is not based on who makes the most money or
            who gets the nicest job straight out of college, but it is about the
            quality and the passion you put into your work. <br />
            <br />
            Even though I am absolutely terrified of what the future holds for
            me, I think I am going to be just fine…as long as no one else asks
            me what I am going to do after I graduate. Life is hard enough right
            now, and I want the vicious cycle of worrying to end for the time
            being. Let me finish finals in peace, and then maybe we can talk
            about the dark mass of nothing that is my future. <br />
            <br />
          </p>{" "}
        </>
      );
    },
  },

  {
    magazine_id: 21,
    magazine_img: "/image/MagazineArticles/Opinion/opinion8.jpg",
    magazine_name: "OUTSTANDING FEMALE ARTISTS",
    magazine_type: "opinion",
    magazine_titleData: `There are countless female artists that have changed the music industry. It is impossible to list them all, but here are ten that deserve recognition and praise.`,
    magazine_writer: "Jude De Luna",
    magazine_data: () => {
      return (
        <>
          {" "}
          <p>
            There are countless female artists that have changed the music
            industry. It is impossible to list them all, but here are ten that
            deserve recognition and praise.
            <br />
            <br />
            <b>Rina Sawayama</b> - “SAWAYAMA” [2020] - “STFU”
            <br />
            <br />
            Born in Britain, Japanese artist Rina Sawayama has always used her
            music as an outlet for her culture and personal experiences. Her
            electropop, high-energy songs explore multiple stories personal to
            her, such as capitalism in the face of climate change, pansexuality,
            and racism. Heavy/nu metal song “STFU” in the album “SAWAYAMA,” for
            example, is Sawayama’s expression of rage at others’ casual
            microaggressions against her as a Japanese woman.
            <br />
            <br />
            <b>fka twigs</b> - “MAGDALENE” [2019] - “fallen alien”
            <br />
            <br />
            fka twigs writes her songs with care, mixing painful
            autobiographical anthems and surreal revenge ballads. “MAGDALENE” is
            twigs’ R&amp;B intimate narrative after an intense public breakup.
            Her songs, filled with emotions such as anger and sorrow, resonate
            with the listener. In “fallen alien,” twigs relates her feelings on
            not fitting in with the conventional woman, something that
            contributed to said breakup.
            <br />
            <br />
            <b>Japanese Breakfast</b> - “Jubilee” [2021] - “Paprika”
            <br />
            <br />
            The band Japanese Breakfast is headed by Michelle Zauner.
            Alternative/dream pop album “Jubilee” is a poignant celebration of
            joy after Zauner dealt with the grief of losing her mother to
            cancer. The album opens with “Paprika,” an ambitious song that feels
            like a dreamy parade, representing that anguish is not needed to
            experience delight.
            <br />
            <br />
            <b>Doja Cat</b> - “Planet Her” [2021] “Love to Dream”
            <br />
            <br />
            First popularly known in 2018 for her song “MOO!”, where she raps
            about being a cow, Doja Cat has shaken off the title of being a
            “meme” artist, skyrocketing to fame with her pop R&amp;B/hip hop
            raps. “Planet Her” is a magical visual project, with Doja Cat
            exercising full creativity. “Love to Dream,” a song fantasizing
            about a better love, is one of the songs amongst this no-skip album.
            <br />
            <br />
            <b>Taylor Swift</b> - “Fearless (Taylor’s version)” [2021] - “Love
            Story”
            <br />
            <br />
            Throughout the years, Taylor Swift has been an artist that people
            unfortunately love to hate, but she has always worked through the
            media’s misogyny. As the ownership of her old songs was sold away,
            she has been rerecording her songs to reclaim what was once lost,
            starting with “Love Story.” <br />
            <br />
            <b>Britney Spears</b> - “Oops!... I Did It Again” [2000] -
            “Stronger”
            <br />
            <br />
            Britney Spears is and always will be an icon of this millenium.
            After unfairly forced into a conservatorship, with her father as one
            of the conservators, she rarely had her freedom. However, after her
            situation was exposed, countless people on the internet fought for
            her freedom and she successfully broke away from her family.
            “Stronger” from her 2000 album encapsulates the situation perfectly,
            as she powerfully sings “I’m not your property as from today.”
            <br />
            <br />
            <b>Lorde</b> - “Solar Power” [2021] - “Secrets from a Girl (Who’s
            Seen It All)”
            <br />
            <br />
            Although only releasing a few albums, her meaningful and formative
            songs have shaped many people since 2013. In her latest memoir album
            “Solar Power,” Lorde delves into her feelings of adulthood and
            growth. “Secrets from a Girl (Who’s Seen It All),” one of the
            psychedelic indie folk songs on her album, is a love letter to her
            younger self, imparting all of the secret wisdom she’s learned over
            the years.
            <br />
            <br />
            <b>Willow</b> - “lately I feel EVERYTHING” [2021] - “Lipstick”
            <br />
            <br />
            Making herself stand out beyond her own last name Smith, Willow has
            broken out in the music industry, most recently with pop-punk/indie
            rock album “lately I feel EVERYTHING.” Confronting her own mental
            issues with songs like “Lipstick,” Willow is vulnerable and honest
            with her audience, creating a sentimental soundscape.
            <br />
            <br />
            <b>Solange</b> - “A Seat at the Table” [2016] - “Weary”
            <br />
            <br />
            Although her older sister Beyoncé is certainly a model figure in the
            music industry, Solange has also worked hard to create her own
            music. Neo soul album “A Seat at the Table” embodies a transitional
            time in her life, dissecting her history and future. “Weary” is
            Solange’s way of showing she can prove herself in the world, but it
            is also a commentary of how weary she is of the inequality of the
            world.
            <br />
            <br />
            <b>Mitski</b> - “Laurel Hell” [2022] - “Love Me More”
            <br />
            <br />
            Mitsuki Laycock, popularly known as Mitski, is beyond “sad girl
            music,” which others mistakenly label her as. With her newest album
            “Laurel Hell,” Mitski exemplifies the meanings of one’s true self
            and vulnerability. “Love Me More,” in particular, shows her wanting
            someone’s love, but really needing to love yourself first.
            <br />
            <br />
            Honorable Mentions/Songs to Further Diversify Your Listening!
            <br />
            <br />
            Laufey - “Let You Break My Heart Again”
            <br />
            Olivia Rodrigo - “jealousy, jealousy”
            <br />
            Raveena - “Spell”
            <br />
            Remi Wolf - “Liz”
            <br />
            Mereba - “Black Truck”
            <br />
            Faye Webster - “I Know I’m Funny haha”
            <br />
          </p>{" "}
        </>
      );
    },
  },

  // {
  //   magazine_id: 20,
  //   magazine_img: "/image/MagazineArticles/Grunion/grunion1.png",
  //   magazine_name: "DAILY 49ER CONSIDERS REBRANDING NAME",
  //   magazine_type: "grunion",
  //   magazine_titleData: `After a year of controversy surrounding Cal State Long Beach’s student newspaper, the Daily 49er, the organization is set to change their name after a movement began to demand change.`,
  //   magazine_writer: "Paperman",
  //   magazine_data: () => {
  //     return (
  //       <>
  //         {" "}
  //         <p>
  //           After a year of controversy surrounding Cal State Long Beach’s
  //           student newspaper, the Daily 49er, the organization is set to change
  //           their name after a movement began to demand change. <br /> <br />
  //           The movement began on a website called jprstudents4change.org in the
  //           Spring of 2021. Visiting the website, readers are met with a big
  //           bolded “Racism exists at the Daily Forty-Niner.” After waiting about
  //           a year for everyone to forget about the movement, the Daily 49er
  //           recently put out a poll toward the end spring 2022. <br /> <br />
  //           A totally-real-and-not-fake official from the newspaper reached out
  //           for comment saying, “Don’t look at that website, we'd figured all
  //           the students active enough to push for a change have already latched
  //           onto the next movement.” They really said that, they said that
  //           because I reached out to them for comment and they told me, a
  //           reporter, that.
  //           <br /> <br />
  //           Now that the topic is back in the air for discussion and not going
  //           to be thrown away in a random Instagram poll because I know our
  //           patriotic, god fearing newspaper would never do that, the newspaper
  //           is set to change their name to a few of the following options.
  //           <br /> <br />
  //           “The Daily 69er”
  //           <br />
  //           “The Daily 48er”
  //           <br />
  //           “Daily 49er? I Hardly Know Her”
  //           <br />
  //           “We Back the Thin Prospector Pete Line”
  //           <br />
  //           “Was Trump so Bad? (newspaper)”
  //           <br />
  //           “JPR Department Pipeline”
  //           <br /> <br />
  //           Let’s hope that these are actual names they’re considering changing
  //           to, because the Daily 49er is an overtly offensive name, and
  //           everyone absolutely hates that name. Just ask anyone on campus what
  //           they think about the Daily 49er, they’ll always say, “Huh, what’s
  //           that?”
  //           <br /> <br />
  //           That’s all. Nevermind the fact that any 22 West Media associated
  //           person has to go on a 2 minute speech clarifying what we are and
  //           what we do, having to clarify that the article we published titled,
  //           “Best Places to Worship Satan,” was in fact, not a joke, and we are
  //           full blown satanists manifesting in the basement of the University
  //           Student Union.
  //           <br /> <br />
  //         </p>{" "}
  //       </>
  //     );
  //   },
  // },

  {
    magazine_id: 19,
    magazine_img: "/image/MagazineArticles/Grunion/grunion2.jpg",
    magazine_name: "WHAT TO EXPECT WHEN YOU’RE EXPECTING (VERY LITTLE)",
    magazine_type: "grunion",
    magazine_titleData: `There is no doubt in my mind that you all are weeping at the news of my leave from the hospital. It has come at quite an unexpected moment in time, I’m sure. Upper management hired me on Wednesday, all but 72 hours ago. And to lose such an esteemed professional with less than a weekend of merriment to share? Such an event must amount to personal tragedy.`,
    magazine_writer: "Dr. Ötsab Kcisz (Leah Olds)",
    magazine_data: () => {
      return (
        <>
          {" "}
          <p>
            – A testimonial from the guy who quit his job – <br />
            Date: 02.21.2022
            <br />
            By Dr. Ötsab Kcisz (Leah Olds)
            <br />
            To the beautiful and gorgeous staff at Light of Benevolence
            Sanatorium Unit,
            <br />
            <br />
            There is no doubt in my mind that you all are weeping at the news of
            my leave from the hospital. It has come at quite an unexpected
            moment in time, I’m sure. Upper management hired me on Wednesday,
            all but 72 hours ago. And to lose such an esteemed professional with
            less than a weekend of merriment to share? Such an event must amount
            to personal tragedy.
            <br />
            <br />
            But in lieu of a woeful goodbye, I bear unto you a piece of
            uplifting advice which is also the reason for my departure from your
            team. My dear nurses and surgeons (in addition to all those other
            tiny people who are not nurses or surgeons), take the following
            words to heart like a 1.83mm needle:
            <br />
            <br />
            GIVE.
            <br />
            UP.
            <br />
            <br />
            Some of you, especially those who are not nurses or surgeons, may
            wonder what this cryptic metaphor really means. Do not exhaust your
            delicate lobes on my behalf. I will present this idea in simpler
            terms using some reverse translations of the old country, from which
            I originate.
            <br />
            <br />
            “Quit until you cannot quit anymore.”
            <br />
            <br />
            “Crawl in the opposite direction of the finish line.”
            <br />
            <br />
            “Do not do that thing you were about to do.”
            <br />
            <br />
            “Seriously, do not do it.”
            <br />
            <br />
            Yes, my good-looking friends. These are what constitute my new life
            philosophy, my raisin debtor. For the past three days I have
            embarked on a journey which young people and hashtag analysts would
            refer to as a “#girlbossmoment” wherein I learned how, what, and
            when to quit.
            <br />
            <br />
            I personally believe that the truest form of self-care is one’s
            complete disengagement from the frivolities of daily life. But it
            seems that society at large looks down on those people who toss in
            the towel for hobbies, sports, jobs and romantic pursuits in the
            interest of self-realization. Why? The answer is simple.
            <br />
            <br />
            The people who expect us to do things do not want us to stop doing
            the things they expect us to do. <br />
            <br />
            This eerie realization rocked me to my core and tallied many
            queries. Who am I, a nondescript
            European-turned-American-through-marriage, if not an individualist
            at heart? Are we Yankee Doodles not entitled to the boundless joys
            of self-determination? Must we always have a ‘can-do’ spirit in
            order to get what we want? <br />
            <br />
            I would argue, no. Or rather, ‘no’ to the second question in a
            positive way, but ‘no’ to the third question in a negative way. See,
            the first question was rhetorical.
            <br />
            <br />
            If I could have mastered the art of quitting as a small wonder
            playing in the sandy sand, I would have said to myself, “Ötsab, you
            do not have to finish all of the veggies just to have babička’s
            dessert. You should ditch those xylophone lessons while you still
            have the chance. And for the love of God, do NOT attend medical
            school in Florida.”
            <br />
            <br />
            A truly successful person knows to shirk the game when their talents
            are not being acknowledged to a satisfactory degree, and I am no
            longer satisfied in my position. In our line of work, it is entirely
            too predictable to experience what researchers at the Cleveland
            Health Clinic refer to as “depersonalization,” where we feel
            disconnected from others due to mental burnout. <br />
            <br />
            But make no mistake, sweet chickadees. Burnout is not what led me to
            join the millions of quitters recorded by the Bureau of Labor
            Statistics in the last six months, who shook up capitalism’s
            icecap-less snowglobe. <br />
            <br />
            The only burnout I experienced was the kind that happens in my Honda
            Civic, in the company of Dr. Dre, after completing an eight-hour
            cardiopulmonary bypass. It seems that ‘chronic’ is a delightfully
            flexible term outside of Western medicine. But I digress.
            <br />
            <br />
            It is not always the people around us that make us do these things,
            you know. Sometimes the final straw can simply be the neglect of
            one’s own vision. And as a reaction to an unfulfilled promise, the
            desire to give up is so natural; it is what you should expect when
            you’ve already been conditioned to expect very little from certain
            engagements you make in good faith. <br />
            <br />
            Thus, I found serenity in the pink slip.
            <br />
            <br />
            After all, what is a quitter if not a person who is actually a
            premature winner, or an ambitious self-starter? Through quitting I
            have gained an ability of clairvoyant value, which clears my mind of
            all anxieties and abandons any task that is an affront to its own
            well-being. <br />
            <br />
            Quitting is an evolutionary defense mechanism and I am no more than
            a human animal at its mercy. When I proudly stormed out of the
            operating room yesterday afternoon, I finally acknowledged this
            biological fact. I also learned that there is simply no better time
            for self-reflection than in the middle of an open-heart surgery.
            <br />
            <br />
            Light of Benevolence, I quit not only for me, but also for myself
            and I. As the old American proverb states, “There is no ‘i’ in
            ‘team.’ But there is a ‘me,’ if you take out the ‘a’ and the ‘t.’”{" "}
            <br />
            <br />
            I wish all of you attractive, educated and medicated nurses and
            surgeons the best of luck. Take a load off, for once in your
            severely overachieving lives, and just give up!
            <br />
            <br />
            Ave maria,
            <br />
            Ötsab Kcisz , M.D. <br />
            *Former* Cardiothoracic surgeon
          </p>{" "}
        </>
      );
    },
  },

  {
    magazine_id: 18,
    magazine_img: "/image/MagazineArticles/Grunion/grunion3.png",
    magazine_name: "TREE CHRONICLES",
    magazine_type: "grunion",
    magazine_titleData: `I have come unfortunately unprepared with no food, no water. Just a guy, his surroundings, and some books. Pretty sweet set up for a tree though so part of me believes this whole thing won’t be so bad. At least it’s nice out. It’s one of those days where we dance around 70 degrees. Very few people come through campus on a Friday morning and it’s still too early for much general life to be about. It's peaceful, so I think I’m going to start out by reading before the sun starts baking me later.`,
    magazine_writer: "Joey Martinez",
    magazine_data: () => {
      return (
        <>
          {" "}
          <p>
            <b>
              <b>9 a.m.</b>
            </b>
            <br />
            I have come unfortunately unprepared with no food, no water. Just a
            guy, his surroundings, and some books. Pretty sweet set up for a
            tree though so part of me believes this whole thing won’t be so bad.
            At least it’s nice out. It’s one of those days where we dance around
            70 degrees. Very few people come through campus on a Friday morning
            and it’s still too early for much general life to be about. It's
            peaceful, so I think I’m going to start out by reading before the
            sun starts baking me later.
            <br />
            <br />
            <b>
              <b>10 a.m.</b>
            </b>
            <br />
            Pretty simple first hour. Nothing’s changed. My butt doesn’t hurt so
            that’s a plus. You know Target sells lawn chairs for $40? Why would
            they do that when they also sell this $14 one that doesn’t entirely
            suck.
            <br />
            <br />
            <b>
              <b>11 a.m.</b>
            </b>
            <br />
            It’s hitting me right now that I didn’t charge my phone last night
            and the little I did get to charge it before I left my dorm is only
            going to last me until maybe 1 or 2 p.m. if I keep using it. I don’t
            even think I can keep listening to Earth, Wind &amp; Fire, the
            greatest producers of soul ever, anymore or else my 20% won’t last.
            Guess it's time to really start getting into the mindset of a tree
            soon.
            <br />
            <br />
            <b>
              <b>1 p.m.</b>
            </b>
            <br />
            My editor in chief Andres Leon recognized me and we met in-person
            for the first time. I felt weird given the circumstances, that being
            that I'm running on maybe three or four hours of sleep. I’m getting
            seriously hungry and I couldn’t get up. Brutal lesson: Trees can’t
            dap people up.
            <br />
            <br />
            <b>
              <b>2 p.m.</b>
            </b>
            <br />
            The sun has finally positioned itself so that it shines directly at
            me. I don’t know what it is, but direct sunlight like this just
            makes me super tired. I’m extremely comfortable in my chair too.
            That’s what sucks though. I could totally fall asleep right now and
            have the best nap of my life, but I can’t. I am moving in and out of
            sleep and the only thing keeping me up is the need to stay awake to
            make sure my stuff doesn’t get taken. I just gotta keep fighting it.
            <br />
            <br />
            <b>
              <b>3 p.m.</b>
            </b>
            <br />
            This is stupid.
            <br />
            <br />
            <b>
              <b>
                4 p.m. <br />
                This is the best and I am literally the luckiest guy ever. My
                phone was at 5% and I got a call from a job I applied for almost
                2 months ago asking for an interview. A squirrel has also
                decided to eat and hang right by me for the better part of an
                hour. I’ve named him Paco. He’s a little dude who I heard
                rummaging behind me and when I put out my hand to him like you
                would to a cat he came up to me and looked at my palm as if I
                was going to feed him. Even though I had nothing for him though
                he’s still sticking around right by me.
                <br />
                <br />
                <b>
                  <b>5 p.m.</b>
                </b>
                <br />
                Everything is falling apart. Paco is gone and I don’t know where
                he went. No more funny squirrel munching sounds and watching him
                chase off other squirrel trespassers on his domain. My phone is
                completely dead so I’m going to have to rely on my laptop from
                here on. The chair has decided to give out because I kept
                resting my elbow and subsequently my entire upper body on one
                arm of the chair. I am now sitting on the ground for the rest of
                the time here with my only saving grace now being two friends of
                mine picking up food for me. Hopefully that sweet sweet
                Chick-Fil-A will keep me going.
                <br />
                <br />
                <b>
                  <b>6 p.m.</b>
                </b>
                <br />
                A friend of mine happened to have been walking by and grabbed
                one of the outdoor chairs between two buildings for me. Not as
                comfy as the lawn chair, but definitely an improvement above the
                ground. The food also came in. Awesome. Best part of this whole
                tree thing is the whole getting food without moving deal. But I
                guess trees don’t have to beg their friends to take their debit
                cards and pick up some sustenance.
                <br />
                <br />
                <b>
                  <b>7 p.m.</b>
                </b>
                <br />
                It has gotten really cold really fast over the last hour. This
                is the hardest the day has been and I think it’s only going to
                get worse. The warmth from my laptop as I write is the only
                thing helping my hands right now so I'm just going to keep
                typing. The weird thing about the last ten hours is how
                simultaneously I’ve talked to and interacted with no one, and
                how much I’ve gotten out of the select few conversations I've
                actually had. I think there's something very strange but
                valuable in the occasion of talking to someone then being forced
                to sit in complete silence while they get to go off and get
                distracted by the rest of their day. <br />
                I consider myself someone who finds a lot of value in the little
                interactions with people I talk to whether they are close
                friends or random strangers I'll never meet again. But sitting
                here, being forced to be subdued in thought of every interaction
                I've had because there’s nothing else to distract myself with
                anymore, has really been doing something to my head. <br />
                The way we communicate and give each other little information
                through the slightest actions is immensely undermined by the
                next distraction we give ourselves just a few seconds later.
                <br />
                Maybe this is the answer I’ve been looking for when wondering
                why people can be so dismissive of others' issues and flaws as
                something they can just go ahead and dislike them for rather
                than thinking for a moment that this person really, genuinely,
                doesn’t know any better and the signs that they don’t are clear.
                <br />
                Or maybe I'm just trying to keep my hands from freezing right
                now.
                <br />
                <br />
                <b>
                  <b>9 p.m.</b>
                </b>
                <br />
                I’m writing this ten minutes before nine so that I can just
                hurry up and get out of here. Everything was good and fine, the
                weather, the people, the seat. It’s just the last quarter that
                really made this horrible because for the last two hours,
                everything’s been gone. No sun, no people, the comfortable seat
                has been replaced by a metal chair getting just as cold as
                everything else.
                <br />
                I hope you’ve found some semblance of a lesson in all this. What
                I've found is don't be a tree.
                <br />
              </b>
            </b>
          </p>{" "}
        </>
      );
    },
  },

  {
    magazine_id: 17,
    magazine_img: "/image/MagazineArticles/Grunion/grunion4.jpg",
    magazine_name: "LONG BEACH PARKING LOTS FOR DEEP CONTEMPLATION",
    magazine_type: "grunion",
    magazine_titleData: `Ever find yourself slumped in the driver’s seat of your parked car, staring out into nothing with a
    mid-tempo alt rock song on repeat, destined to become the soundtrack of your life’s greatest
    disappointments? Oh, you haven’t? Well… me neither. Definitely not. But in the event that
    you’re playing hooky with therapy, here are some great local spots that will help fill the void!`,
    magazine_writer: "Leah Olds",
    magazine_data: () => {
      return (
        <>
          {" "}
          <p>
            Ever find yourself slumped in the driver’s seat of your parked car,
            staring out into nothing with a mid-tempo alt rock song on repeat,
            destined to become the soundtrack of your life’s greatest
            disappointments? Oh, you haven’t? Well… me neither. Definitely not.
            But in the event that you’re playing hooky with therapy, here are
            some great local spots that will help fill the void!
            <br />
            <br />
            <b>
              <b>1. The ‘University Overlook’ Lot</b>
            </b>
            <br />
            Students of Long Beach State, rejoice! For only $210 per semester,
            you will be treated to a decadent high-rise parking structure
            located just south of East Atherton Street. This lot boasts rooftop
            views of a picturesque campus where you will spend at least four
            years questioning the projected stability of your professional
            future. A student can change their major, but they can’t change that
            deafening inner-monologue that makes them a stranger to their
            lifelong dreams. So if you want to start your day with an enchanted
            gaze at the Walter Pyramid, or with 35 minutes of circling around
            like a shark before settling for a low attendance rate, this is the
            destination for you. Scenic, expensive, and mind-numbing – these are
            what make up a true university experience. Go Beach!
            <br />
            <br />
            <b>
              <b>2. The ‘I’ve Got Things To Do’ Lot</b>
            </b>
            <br />
            On the corner of Anaheim and Newport, this bustling commercial lot
            is flanked by a number of establishments, the likes of which include
            LB Laundry, Rose Massage, KBeauty, JJ Chinese Express, and El Pollo
            Loco. It’s the perfect Sunday morning “to-do” depot!
            <br />
            But enough with the advertising. It is likely that there is not a
            single errand you need to run here. You just needed a place to sit
            and think, or stare at a dumpster for 15 whole minutes with no
            interruptions. That five year plan you made as a high school senior
            is a thing of the past, friend. If you haven’t already secured your
            quirky group of best friends and the loft apartment in Los Angeles,
            then it is time to stop chasing the fantasy. Just find a parking
            space after business hours and watch the middle seasons of “New
            Girl” to get the desired effect. This is a destination that is
            guaranteed to soothe the sting of underachievement!
            <br />
            <br />
            <b>
              <b>3. The ‘Hold Your Cousin Accountable’ Lot</b>
            </b>
            <br />
            There are so many spaces to choose from at this asphalt haven, you
            might consider initiating a four hour long video call with an
            estranged relative just to clear the air on why you haven’t spoken
            to them since “that thing that happened at auntie’s house two years
            ago.” Turn up the heat, sister!
            <br />
            Oh and Jerry’s Breakfast Place is right across from it, should you
            end up falling asleep in the driver’s seat after all of the
            unpacking. Take it from me, the only other call you're going to want
            to make after that will be for an order of chilaquiles with some
            orange juice and an Advil (not included with the Jerry’s breakfast).
            <br />
            <br />
            <b>
              <b>
                4. The ‘Oh Look, It’s the Place You Haven’t Visited Since You
                Turned 18’ Lot
              </b>
            </b>
            <br />
            Praise be! It doesn’t matter if the sight of the chapel tower across
            the road gives rise to warm, nurturing feelings or deeply buried
            shame; at the very least, you will find plenty of spots for
            repentance at this street-facing lot on Third Street. But before
            opening your driver’s side door to air out the hotbox fumes and
            watch those CVS receipts get “swept away by the wind,” just know
            that everything you do is being witnessed by the eyes of God.
            <br />
            <br />
            <b>
              <b>5. The ‘You Might As Well Get Comfortable’ Lot</b>
            </b>
            <br />
            Sometimes, the only way to gain control of mental-emotional
            consciousness is by losing control of physical-material
            surroundings. So if you’ve been cruising down Long Beach Boulevard
            with that dead look in your eyes, desperate to make everything in
            the world stop for just a little while, then venture to a place that
            guarantees unrelenting stillness.
            <br />
            Sandwiched between Park’s Transmissions and Fluffy’s Sno-Balls, this
            lot has plenty of parking spaces but no designated exit in either
            direction. That’s right, you cannot leave!
            <br />
            Unless of course, you choose to drive in the opposite direction of
            the entrance’s painted arrow. But who are we kidding – you need this
            stationary solitude, or you just might lose it. If you are a
            functionally manic individual who can’t seem to allow yourself a
            crying spell, let this impractically designed car park do the work.
            Here’s to going nowhere fast!
            <br />
            <br />
            <b>
              <b>1st Runner-Up: The ‘Greasy Spoon’ Lot</b>
            </b>
            <br />
            Do you love fried chicken? How about aimless retrospection? If you
            answered ‘yes’ to one or both of those questions, start the 1992
            Toyota Camry that your parents say has “nine, maybe ten years left”
            and pull into this lot in front of Popeyes on Redondo Avenue.
            <br />
            You don’t even have to eat anything! Just pull in, nudge the parking
            block, roll your window down, smell the drifting fumes from the
            fryers, then roll your windows back up whilst taking stock of the
            dreams you had long mistaken for precious childhood memories.
            <br />
            <br />
            <b>
              <b>
                2nd Runner-Up: The ‘You Have Never Used A Wrench In Your Life’
                Lot
              </b>
            </b>
            <br />
            Rapid-fire question: What is something that is a life necessity but
            also has a habit of getting messy when it is not properly looked
            after?
            <br />
            If you answered “indoor plumbing,” you are right! But you are also
            wrong. In fact, a better answer would have been “my relationship,”
            because some of you know damn well that yours is in desperate need
            of fixing. That’s why you should take yourself to East Fourth
            Street’s ACE Hardware and think about that with your transmission on
            ‘P.’
            <br />
            And while you’re at it, pick up a flathead screwdriver that you’ll
            only use once so that you have an actual reason to be there that
            doesn’t include grabbing some free popcorn or living out a symbolic
            epiphany.
            <br />
          </p>{" "}
        </>
      );
    },
  },

  {
    magazine_id: 16,
    magazine_img: "/image/MagazineArticles/Grunion/grunion5.jpg",
    magazine_name: "THE MOST EPIC PRANKS TO PLAY THIS APRIL",
    magazine_type: "grunion",
    magazine_titleData: `This month, you might want to pull some totally crazy pranks. You wild animal! Here’s an outrageous list of savage af jokes to play on the people in your life. Get some laughs and have some fun.`,
    magazine_writer: "Jude De Luna",
    magazine_data: () => {
      return (
        <>
          <p>
            This month, you might want to pull some totally crazy pranks. You
            wild animal! Here’s an outrageous list of savage af jokes to play on
            the people in your life. Get some laughs and have some fun.
          </p>
          <ol>
            <li>
              <b>
                <b>Talk to and visit your parents!</b>
              </b>
              <br />
              It’s been a while since you’ve talked to your parents, right?
              Wouldn’t it be insane if you actually called them back and let
              them know about how you’re doing? You’ll definitely surprise them!
              Even crazier – going back home and giving them a hug. Make sure to
              bring them flowers to really seal the deal.
              <br />
            </li>
            <br />

            <li>
              <b>
                <b>Do all of the chores in your home!</b>
              </b>
              <br />
              You and your housemates have been extremely busy lately, and the
              toll of an unkempt home is noticeably getting worse on all of you.
              All of the dust piling up on the furniture, the dishes in the
              sink, the trash cluttered in the rooms, or the laundry that
              should’ve been done weeks ago? Take care of it all by yourself! I
              bet your housemates will FLIP.
              <br />
            </li>
            <br />

            <li>
              <b>
                <b>Ask your friends how they’re doing!</b>
              </b>
              <br />
              Your friends have been looking a little down lately. Maybe it’s
              the school work, their actual work, or personal matters. Take the
              time out of your day to be there for them and listen to their
              rants. It can help for the better, but that doesn’t matter.
              Ultimately it’s an intense prank that only you can pull off!
              <br />
            </li>
            <br />

            <li>
              <b>
                <b>Participate in class!</b>
              </b>
              <br />
              When your professor asks a question and it’s dead silent amongst
              you and your classmates, step up to the plate! Even if you get an
              answer wrong, who cares? You’ll be memorable as the class clown.
              Also, try interacting with your classmates! Instead of bolting to
              the door the second it’s time to go, stick around and talk about
              what a hoot the class is. I guarantee you’ll get them for sure!
              <br />
            </li>
            <br />

            <li>
              <b>
                <b>Put premium gas in your friend’s car!</b>
              </b>
              <br />
              Gas is so expensive nowadays at almost $7 a gallon. Imagine your
              friend’s surprise when they see a full tank of the most expensive
              and premium option! Their face will be hilarious. Also, this is a
              bonus prank on your bank account! The bank will call you about
              this suspiciously large purchase but you can laugh right in their
              ear and tell them all about this wacky prank. They’ll definitely
              laugh right back with you and note this down for their future
              shenanigans.
              <br />
            </li>
            <br />

            <li>
              <b>
                <b>Get revenge on all of the people who have slighted you.</b>
              </b>
              <br />
              There they are again. It could be the person who didn’t hold the
              door open for you. It could be the person who pushed you when they
              walked past you. It could be the person who cut you off when you
              were just about to get on the escalator. Either way, none of these
              people respect you. Why should you respect them?
              <br />
              Here’s what you do. First, you befriend them. Go up to them and
              strike a conversation. Surely, they won’t remember when they first
              wronged you, so they’ll be friendly. But, you remember. You always
              remember.
              <br />
              Plan to get closer to them. Of course, you’re sacrificing your
              dignity as you hang out with them, but you’re playing the long
              game. Build the most meaningful relationship of your life with
              this person. Share your deepest and darkest secrets. Create new
              memories and make plans for the future with them. It will be so
              funny. Do some innocent gag along the way, like a couple of the
              pranks listed above. They’ll laugh and say you’re the most
              hilarious friend they’ve ever had and that they’re glad to have
              you in their life, but remind yourself of your true intentions.
              Never let your guard down.
              <br />
              This final step is the most important. Ghost them. Ghost them
              completely and don’t look back. Block everything related to them
              on all platforms, even their mom that you promised to play
              badminton with. Take back what once was yours: your pride and
              self-respect.
              <br />
              One day, you’ll be walking, minding your business, but you’ll see
              them once again. They’ll look at you in disbelief and try to go up
              to you, but you can just cackle at their misery and walk away. You
              won. No one will ever dare to slight you again.
              <br />
            </li>
            <br />

            <li>
              <b>
                <b>Take care of yourself (*^▽^*)!</b>
              </b>
              <br />
              After spending your time cooking up some wild jokes, you might be
              wondering how to end your hilarious escapades. However, there is
              one more person that you forgot to prank: yourself! Do something
              for yourself that you wouldn’t normally do. Order your favorite
              takeout, prepare a bubble bath, and listen to your favorite music.
              ULTIMATE PRANK! <br />
            </li>
          </ol>
          <p></p>{" "}
        </>
      );
    },
  },

  {
    magazine_id: 15,
    magazine_img: "/image/MagazineArticles/Grunion/grunion6.jpg",
    magazine_name: "SKADOOSH MY HEART",
    magazine_type: "grunion",
    magazine_titleData: `It was a Wednesday morning during the first week of the spring semester. I can remember it so clearly. It was my first day, and I was late for class because I got lost looking for the right room. You were the mysterious seven-foot-something bear of a man, striking a pose in the Fine Arts 4 Building at Long Beach State University, and I was the one that couldn’t stop staring at how majestic and graceful you looked.`,
    magazine_writer: "Lauren Galvan",
    magazine_data: () => {
      return (
        <>
          {" "}
          <p>
            It was a Wednesday morning during the first week of the spring
            semester. I can remember it so clearly. It was my first day, and I
            was late for class because I got lost looking for the right room.
            You were the mysterious seven-foot-something bear of a man, striking
            a pose in the Fine Arts 4 Building at Long Beach State University,
            and I was the one that couldn’t stop staring at how majestic and
            graceful you looked.
            <br />
            <br />
            It felt as though you and I were the only ones in the room and that
            nothing else mattered. I haven’t been able to stop thinking of you
            since. I wanted to say something to you, but I was too nervous about
            you not feeling the same way. <br />
            <br />
            You had fine white and black hair that covered your body from head
            to toe. I was the curly-haired girl with glasses and hearts in her
            eyes. Also, I couldn’t help but notice the little smirk or smolder
            you were giving me as I walked by. I dream of that smirk. In fact, I
            was so distracted by it that I tripped and I was so embarrassed, but
            you didn’t even laugh, you just smiled at me without a hint of
            judgment. It’s like you know what it feels like to be different. You
            know what it’s like to not fit in. <br />
            <br />
            Call me crazy, but I feel like I wasn’t alone in feeling a spark
            because you didn’t move a muscle the entire time. It was like you
            were frozen in time with me. Also, I honestly have no idea how you
            held that pose for so long, you must do yoga or something. You
            didn’t even look strained, and I swear I never even saw you breathe.
            You looked so at peace. Maybe you could teach me sometime. Honestly,
            you didn’t move a single muscle, it was like you were a statue or
            something. Listen to me! I sound ridiculous. <br />
            <br />
            I don’t know what it was, but I felt like I’ve known you my whole
            life, or since 2008, and that we were like characters in a movie. We
            were a real-life Romeo and Juliet, and nothing could come between
            us. When I first laid eyes on you, it felt like I finally found
            inner peace. <br />
            <br />
            I don’t know what it was, but I feel like you are someone who could
            protect me, care for me, and make sure I am fed throughout the day.
            You probably give great big bear hugs, and I can’t think of anything
            more perfect. I did think it was a bit strange that you weren’t
            wearing a shirt or shoes, but that just tells me that you are a free
            soul who no one can tell what to do. <br />
            <br />
            I couldn’t help but notice how big your hands were, although now
            that I think about it, they were more like paws. However, you
            definitely need to do something about those long claw-like
            fingernails. Nonetheless, I dream (works) about those hands and all
            I want to do is Wuxi finger hold your hands more than anything. Is
            that embarrassing to admit? <br />
            <br />
            To be honest, I thought it was strange when a random person walked
            up and slapped your foot as you did your stance, but maybe he was
            just a friend. I’ve been too scared to return to the Fine Arts
            Building, but maybe once I work up the courage, I will see you
            again. I pray you will be there. Maybe we could even go out to eat
            or something. I know a great dumpling spot if that’s what you are
            into. <br />
            <br />I know there is a very small chance that you will ever see
            this, but I thought I would try. So, if you felt the same connection
            that I did that day in the Fine Arts 4 Building, you can email me at{" "}
            <span id="cloak0793951980014c8185d6b17c4f268981">
              <a href="mailto:kungfupandalover22@aol.com">
                kungfupandalover22@aol.com
              </a>
            </span>
            . I will be waiting for you, dragon warrior of my heart. With all my
            heart, I believe we were truly a missed connection. <br />
            <br />
            A wise man once told me, “Yesterday is history, tomorrow is a
            mystery, but today is a gift. That is why it is called the present.”
            I think his name was Oogway or something. I can’t remember. <br />
            <br />
            P.S. I hope none of this scared you away! And if anyone knows him,
            please reach out to me. <br />
            <br />
          </p>{" "}
        </>
      );
    },
  },

  {
    magazine_id: 14,
    magazine_img: "/image/MagazineArticles/Grunion/grunion7.jpg",
    magazine_name: "SQUIRRELS EAT THEIR OWN BABIES",
    magazine_type: "grunion",
    magazine_titleData: `Are squirrels good for anything? This is what popped up when I searched the word, "squirrel" on Google and it turns out they are nature's gardeners according to the University of Florida's News "Science &amp; Wellness" section. However, I hate Florida and I really hate squirrels so that isn't true. The second question that popped up after that was,”do squirrels eat their babies?” I don't want to do more research so I'm deciding here that this article is a scientific journal and that squirrels do eat their babies on the basis of because I said so. I even made it the title so it's doubly true. And I'm the editor-in-chief so it's triply true.`,
    magazine_writer: "Andres Leon",
    magazine_data: () => {
      return (
        <>
          {" "}
          <p>
            Are squirrels good for anything? This is what popped up when I
            searched the word, "squirrel" on Google and it turns out they are
            nature's gardeners according to the University of Florida's News
            "Science &amp; Wellness" section. However, I hate Florida and I
            really hate squirrels so that isn't true. The second question that
            popped up after that was,”do squirrels eat their babies?” I don't
            want to do more research so I'm deciding here that this article is a
            scientific journal and that squirrels do eat their babies on the
            basis of because I said so. I even made it the title so it's doubly
            true. And I'm the editor-in-chief so it's triply true.
            <br />
            <br />
            I'll tell you what they are good for. Stealing, marauding, tax
            evasion, grand theft, embezzlement and looking hideous. They scour
            the campus in search of their next mark, their next score. Some
            people are silly enough to feed the brown rats willingly by offering
            food out of the palm of their own hands. This only conditions them
            to embrace the mindset that the world belongs to them and we,
            students of LBSU, will kneel at their beckon.
            <br />
            <br />
            Remember the seven deadly sins? These little kangaroo wanna-be's are
            the face of gluttony. If you give them an inch, they'll run a lap
            around the entire campus. With their beady, little, dead eyes,
            shielding the devious thoughts they have after decades of
            conditioning to optimize their devious plans. They've only grown
            hungrier over the pandemic when the campus was empty. Now that we're
            back, we have no choice but to endure their onslaught of harassment
            while we try to live our lives on campus. Every night before I go to
            bed, I pray that I won't have to come face to face with a squirrel.
            I'm only a measly college student who can't defend himself.
            <br />
            <br />
            At 22 West Media, we have employees who have suffered the wrath of
            these fiends. We have our own support group to work through the
            traumas that we face when near the squirrels. Multimedia manager
            Jesse Mar Rameriz told me about a time he was enjoying his lunch, a
            nice and simple pesto pita bread sandwich.
            <br />
            <br />
            "It happened so quickly. I had just finished doing all my multimedia
            stuff, managing all the media multiple times, and decided to take my
            lunch break when all of a sudden this squirrel came out of the blue
            and began trying to take my lunch. He was sporting a ten-gallon hat,
            belt, and people call me crazy, but I swear that he was smoking a
            cig. I have scars on my body that I have to keep wrapped up 24/7 and
            I have to change the bandages daily… Every time I can't help but
            think of that damned rodent." Ramirez could not continue the
            interview as it was too traumatic, but gave me permission to include
            it. He said he'd want us to keep fighting the good fight.
            <br />
            <br />
            Lianna Schieber, the distribution manager for 22 West Magazine
            reported an attack to me personally after squirrel bandits ravaged
            her distribution golf cart. It was horrifying, I can recall
            answering a phone call and being met with panicked shrieking. It's
            hard to remember what she said exactly, but I vaguely heard the
            sounds of a tire popping, magazines being shuffled in the air and
            expletives being shouted.
            <br />
            <br />
            "These squirrels make it impossible for me to do my job as
            distribution manager. How am I supposed to wheel around if they keep
            slashing my tires and attacking me? What did I do to deserve this. I
            am but a speck in the universal order. My mind is matter, born of
            the cosmic dust and is bound to eventually return. A grain of sand
            in a galactic sandbox. These squirrels are the Earth to my moon,
            keeping me forever bound by gravity in their suppression. If it is
            the reality that I must coexist with these animals, then I simply
            refuse to believe that there is anything holy ruminating over this
            Earth. Our timeline has been forgotten, cast to ash." Schieber now
            stays in her apartment around the clock, because cart ASI #8 has a
            popped tire. Also because of her crippling and existential fear of
            squirrels. One of those, I'm not too sure, it's hard to tell.
            <br />
            <br />
            For years it's been thought that people were the biggest threat on
            campus, but Big Squirrel doesn't want you to know it's them. From
            the shadows they plot their deceptive tricks. From the shadows they
            view us. From the shadows, they skulk. I have no solid evidence due
            to the nature of this subject, but I have heavy reason to believe
            that our very own student media organization, the Daily Forty-Niner,
            is in cahoots with our enemy. <br />
            <br />
            In an article from 2019 titled, "The nutty history of the CSULB fox
            squirrel," author Hannah Getahun wrote, "Much like the campus, the
            fox squirrel that is so integral to the Long Beach State image
            became a part of Long Beach over time."
            <br />
            <br />
            The article itself details the origins of the squirrels on our
            campus, but there's a deeper subtext that has been skipped over. Why
            write about the squirrels? It's almost as if there's a ventriloquist
            behind it all, orchestrating what articles get published in order to
            control the narrative. It's no secret that the squirrels are
            dastardly creatures, so of course the mastermind behind it all would
            want to lull us into a security, acknowledging the issue while
            ultimately never addressing it. A display of grand inaction.
            <br />
            <br />
            Here at 22 West Magazine, however, we are standing our ground
            against Big Squirrel. I will not be silenced by these eldritch
            abominations. I refuse to be squirrel-pilled. In fact from this day
            forward, I declare 22 West Magazine a bastion against the furries.
            Furries being the squirrels. We don't dislike furries. Please don't
            write me an angry letter. The end I reached 1000 words, almost not
            quite, that's good, wait no. Okay. Wait there. Now.
            <br />
            <br />
          </p>{" "}
        </>
      );
    },
  },

  {
    magazine_id: 13,
    magazine_img: "/image/MagazineArticles/Grunion/grunion8.jpg",
    magazine_name: "BEST PLACES TO WORSHIP SATAN ON CAMPUS",
    magazine_type: "grunion",
    magazine_titleData: `It’s that time of year again! We are back on campus and people are starting to ask the big questions. It’s all you hear, “where is the best place to study?” or “where is the best place to worship king Satan?” Well, look no further because you are in luck. For those who have never been to campus or are in need of a refresher, we will go from some of the more well-known spots to ones that only appear to those who are worthy. Here is a rundown of the top-tier places that will help with all your Satan-worshiping needs this semester. Who knows, you might even run into fellow worshipers while checking out these must-visit locations at Long Beach State University.`,
    magazine_writer: "Lauren Galvan",
    magazine_data: () => {
      return (
        <>
          {" "}
          <p>
            It’s that time of year again! We are back on campus and people are
            starting to ask the big questions. It’s all you hear, “where is the
            best place to study?” or “where is the best place to worship king
            Satan?” Well, look no further because you are in luck. For those who
            have never been to campus or are in need of a refresher, we will go
            from some of the more well-known spots to ones that only appear to
            those who are worthy. Here is a rundown of the top-tier places that
            will help with all your Satan-worshiping needs this semester. Who
            knows, you might even run into fellow worshipers while checking out
            these must-visit locations at Long Beach State University. <br />
            <br />
            <b>
              <b>The Walter Pyramid </b>
            </b>
            <br />
            This first place is a classic. Everyone knows the infamous Walter
            Pyramid, but whether or not they know what it is used for is a
            different story. Yes, of course most people know it as a stadium
            that houses our basketball team, however, that isn’t the whole
            truth. If you have ever wondered how they power such an enormous
            indoor stadium, the answer is simple. Its power comes from the depth
            of hell itself and was forged to worship the man, the myth, the
            legend, Satan. It makes sense. Have you heard of the Illuminati?
            Walter Pyramid is where they all come together for their meetings
            every month. <br />
            Think about it, a huge triangle-shaped building with more than 4,000
            seats in it. What else would those seats be used for other than the
            butts of the Illuminati themselves? All of these factors make this
            spot a perfect place to worship Satan himself. It is in such a
            convenient spot. You could stop on your 30-minute walk from the
            parking lot to class or during your lunch break. It might have a bit
            of foot traffic, but don’t let that stop you from worshiping your
            soul away. <br />
            So, make sure to hit that basketball court! Oh, and don’t forget
            your ouija boards! <br />
            <br />
            <b>
              <b>The Library Basement </b>
            </b>
            <br />
            From horror movies to your grandparent's creepy house, going into
            basements is never a good idea. However, we are here for creepy in
            fact, we live for it. When it comes to worshiping the big man, dark
            and secluded areas are a must! Don’t be scared… or do. That is kind
            of the whole point. Our second spot is opposite the Pyramid, the
            basement of the library gets little to no foot traffic, and is a
            great spot to get your daily worship count in. I mean, have you ever
            seen a horror movie that doesn’t have a terrifying basement
            somewhere in it? <br />
            Since the lower level of the library is used for study groups, you
            will have a big empty space mostly to yourself. One might even say
            that it is the perfect area to draw your pentagrams. Make sure to
            bring a pal! After all, this is a group study floor. Which makes
            this the perfect place to make and bring friends who share the same
            lifestyle as you. And remember to have fun! <br />
            <br />
            <b>
              <b>The Performing Arts Theatre </b>
            </b>
            <br />
            Next up, we have the theatre, the place where the souls of failed
            acting careers will haunt for eternity. Some say you can still hear
            the musical theatre majors singing in the hallways, preparing for
            their big audition. <br />
            Hollywood is a hot spot for Satan. It is where all his top clientele
            come from, so of course, he would love the theatre. In fact, Los
            Angeles is home to some of the biggest Satanist communities out
            there. This place is arguably one of the scariest spots on campus.
            It has a big stage for you to set up your shrine and don't forget to
            light some candles to set the mood and get into character. <br />
            If you are looking for the right place to do a little worship or
            perhaps a nice spot to sell your soul for a decent acting career,
            the theater should be your next scene to check out. So if you don’t
            get the big lead in the next musical, don’t worry about it. Satan’s
            got your back! And you don’t have to go very far to get in touch
            with him. <br />
            Although this is a bit of a heavy-traffic area, don’t let that
            discourage you from belting out your love for Satan! Be loud and be
            proud. <br />
            <br />
            <b>
              <b>The Sometimes Chair </b>
            </b>
            <br />
            Finally, if you know the sometimes chair, then you are one of the
            few, and very lucky, who have been chosen by Satan himself. The
            chair is an invitation. Every now and then, there is an enchanting
            chair in a nice little secluded spot under the stairs that overlooks
            the pool. <br />
            If you ever find yourself walking to the upper campus, make sure to
            look out for it and don’t get discouraged if you can’t find the
            sometimes chair. It only shows itself to those who are the most
            dedicated to the King of Hell. If he thinks you are ready, then you
            will see it. It will call to you and consume you until you finally
            rest your butt on the most mediocrely amazing chair you have ever
            rested on. <br />
            This one is a little more advanced of a spot, so make sure to try
            out the other ones listed before venturing to the chair under the
            stairs. It has never appeared to me, and I am definitely not bitter
            about it. If you have yet to see it, that should only motivate you
            to worship harder and dedicate more time to seeking out the dark
            forces. <br />
            If it makes itself known to you, don’t be afraid. All it is is an
            invitation to worship Lucifer like you never have before, and I envy
            you for that. <br />
            Give them hell! Literally. Give them hell. Raise the dead. Worship
            the dark lord and have fun while doing it. All hail the dark king!{" "}
            <br />
          </p>{" "}
        </>
      );
    },
  },

  {
    magazine_id: 12,
    magazine_img: "/image/MagazineArticles/StudentLife/studentlife1.jpg",
    magazine_name: "REUNITING, INTERNATIONALLY",
    magazine_type: "studentlife",
    magazine_titleData: `So when I had the chance to write an article about the international student
    community, I thought it would be the perfect chance for us to get together. I
    figured that since the majority of us have been away from home for at least 3
    years in different parts of the world, we would all have our own experiences
    and our own stories to tell. So after weeks of planning, juggling timezones,
    scheduling and rescheduling, I managed to gathered everyone into one online
    meeting.`,
    magazine_writer: "Duy Mai",
    magazine_data: () => {
      return (
        <>
          {" "}
          <p>
            So when I had the chance to write an article about the international
            student community, I thought it would be the perfect chance for us
            to get together. I figured that since the majority of us have been
            away from home for at least 3 years in different parts of the world,
            we would all have our own experiences and our own stories to tell.
            So after weeks of planning, juggling timezones, scheduling and
            rescheduling, I managed to gathered everyone into one online
            meeting.
          </p>
          <p>
            <em>
              For context, here are the people who were in the conversation with
              me:
            </em>
          </p>
          <div>
            <div>
              <div>
                <b>A - </b>Co-founder of The Cambio Project. Currently working
                in Toronto, Canada for a publicist agency as a Search-point
                Coordinator.
              </div>
              <div>
                <b>G - </b>Co-founder of The Cambio Project. Fourth-year student
                in the Netherlands majoring in Applied Sciences.
              </div>
              <div>
                <b>T - </b>Ex-President of The Cambio Project. Third-year
                student in the Netherlands majoring in Media Communication.
              </div>
            </div>
          </div>
          <div>
            <i>
              <small>
                The conversation below was translated from Vietnamese.
              </small>
            </i>
          </div>
          <p>
            <b>Duy:&emsp; </b>
            <i>
              What made you decide to become an international student? Why did
              you choose your current school and the country you currently live
              in?
            </i>
          </p>
          <p>
            <b>G:&emsp; </b>In Vietnam, I was studying in advanced English
            language classes and so it was pretty much decided that I was going
            to study abroad. My parents were also able to financially sponsor my
            studies and so I began preparing for my eventual trip. I wanted to
            study in the US. However, due to how expensive tuition was (even
            after scholarships), I decided to travel to the Netherlands. They
            had lower tuitions and we were only required to be in school for 3
            years instead of 4. Also, they didn’t ask for any personal
            statements *laughs*. Even now, I’m still pleased with my decision
            when I was 18 to study Applied Sciences with a focus on
            environmentalism, which is what I always wanted to do.
          </p>
          <p>
            <b>T:&emsp; </b>My parents wanted me to study abroad since middle
            school. My dream destination wasn’t the US but the UK.
            Unfortunately, the UK is also very expensive so I also pivoted to
            Europe. I knew that I wanted to major in something that didn’t
            involve math, something more in the realm of liberal arts. So I
            decided to study Media Communication in the Netherlands.
          </p>
          <p>
            Another reason why I wanted to study abroad was because I feel like
            colleges in Vietnam are not as practical when it comes to media.
            Media there is more focused on politics, which is something that I
            didn’t enjoy.
          </p>
          <p>
            <b>Duy:&emsp; </b>
            <i>How did you feel first coming in as an international student?</i>
          </p>
          <p>
            <b>T:&emsp; </b>It took me a year to truly adapt to everything.
            Europe can be really depressing in the winter and I came in the
            middle of the pandemic. All the international students went back to
            their home countries and I was stuck attending classes online. In
            the beginning, I struggled a lot with loneliness. In high school in
            Vietnam, you would always have a group of friends you get to see
            every day and we don’t have anything like that in the Netherlands.
          </p>
          <p>
            It was also challenging handling living expenses. I didn’t know how
            to properly manage my funds. People tend to either overspend or
            underspend. I underspend a lot and that affected my quality of life
            quite heavily. I would also be a lot more reluctant to hang out with
            friends because it can be really expensive.
          </p>
          <p>
            <b>Duy:&emsp; </b>
            <i>
              How about you, G? Speaking of the pandemic, I know you had to halt
              your studies midway and return to Vietnam because of it. Can you
              talk about that?
            </i>
          </p>
          <p>
            <b>G: &emsp; </b>When I first arrived in the Netherlands, it was
            fall, which was really depressing. I couldn’t find housing during my
            first month there, so I would cry myself to sleep every night. Every
            night. I struggled a lot with living by myself in a foreign country
            where nobody looked like me or spoke my language. There was
            definitely a necessary period of adjustment.
          </p>
          <p>
            March 2020, after 6 months in the Netherlands and just when I began
            to settle, the pandemic happened. My parents wanted me to come home.
            They paid for my flight and so I felt financially obligated to
            travel back. I switched to online classes and that came with its own
            host of problems. The different time zones, for example. I would be
            up at night attending classes and had nothing to do during the day
            when my parents and siblings would be out and about. And because it
            was the height of the pandemic, I decided to take a gap year
            altogether.
          </p>
          <p>
            <b>
              Chị khá là bị traumatized bởi việc học nặng và qua một nước mới.
              Chị cảm thấy như là mình chưa sẵn sàng đi học lại{" "}
            </b>
            (I guess I was really traumatized by everything. The courseload was
            really heavy and having to adapt to a new country while studying
            made me feel like I wasn’t ready for school). The internship I
            landed also had a 6-month contract so I decided to commit to that
            instead. Within my gap year, I had 2-3 internships, which I found
            really enriching. I got to understand more about the work culture of
            Vietnam and also had something to put on my resume *chuckles*.
          </p>
          <p>
            When I returned to the Netherlands, it was a lot easier. For
            example, I had friends to help me with the housing situation. My
            goals were also really clear: I wanted to focus on my studies and
            earn my degree.
          </p>
          <p>
            <b>Duy:&emsp; </b>
            <i>And you, A? </i>
          </p>
          <p>
            <b>A:&emsp; </b>The first week, I missed my family a lot. Some
            nights I would cry myself to sleep too. But I got over it about 2 or
            3 weeks after thanks to my school holding a Vietnamese international
            student meetup. I got to meet a lot of friends and that helped with
            my homesickness a lot. They would take me to the bank, help me with
            groceries, and invite me to board game hangouts. They really helped
            me survive the brutal first few weeks.
          </p>
          <p>
            I also started dating someone about a month later and he introduced
            me to a group of friends. That helped a lot. I think adapting to a
            new environment depends a lot on how much you socialize so hanging
            out with them was really crucial.
          </p>
          <p>
            <b>Duy:&emsp; </b>
            <i>
              You gave me a perfect segue into my next question. It’s really
              difficult to find people you can rely on, especially in a new
              environment. Would you mind talking about a group of friends or a
              community that helped you a lot?
            </i>
          </p>
          <p>
            <b>A:&emsp; </b>The Vietnamese Student Association at my school
            helped me a lot. I volunteered there. I also saw a few issues in how
            the organization was run and so I suggested solutions. I became
            their social media manager. I learned a lot through running their
            Facebook account. I was then promoted to vice-president, and then
            president. I got to organize a lot of fun events and through that
            met a lot of people.
          </p>
          <p>
            As for the group of friends my boyfriend introduced me to, they were
            all older than me and so I got a lot of great advice from them. I
            feel like that’s the benefit of having friends older than you, I
            really can talk about my problems to them.
          </p>
          <p>
            <b>T: &emsp; </b>My school actually has a lot of Vietnamese
            students. I have a really close group of 3 other girls. They helped
            me a lot with my personal struggles. It’s nice being able to vent to
            them knowing that they are going through the same thing.
          </p>
          <p>
            Another really important person is my housemate, who is 10 years
            older than me. I relate a lot with what A said about talking to
            someone older than you. I think what she gave me was a different
            mindset. When you hang out with people of the same age, you all have
            the same perspectives and the same worries. When I talk to my
            housemate, she can give me a more holistic viewpoint. She really
            helped me develop a growth mindset.
          </p>
          <p>
            <b>
              Đối với em, điều quan trọng nhất mà em nhận được từ việc du học là
              cái mindset của mình chứ không phài là kĩ năng
            </b>
            (What I feel like I gained from being an international student is a
            different mindset, not the skills or knowledge you learn in school).
            A mindset like that only comes from interacting with others within a
            different community.
          </p>
          <p>
            <b>Duy:&emsp; </b>
            <i>
              Another perfect segue to my final question. This was meant to be a
              pseudo-reunion for The Cambio Project. We have not talked to each
              other in 4 years so I wanted to know how you think you’ve changed
              and how you think you’ll keep growing.
            </i>
          </p>
          <p>
            <b>T:&emsp; </b>I feel like I’m a lot mellower. Fewer panic attacks
            *chuckles*. I also feel a lot more confident in my own abilities.
            Not in an arrogant way like ‘I can do anything I want to’ but more
            like ‘If I really put my mind to this, I can learn and achieve at
            least something.’ I’m still the same in a lot of ways, and still
            really passionate about what we did in The Cambio Project. I still
            enjoy art, environmentalism, and sustainability.
          </p>
          <p>
            <b>G:&emsp; </b>I struggled with comparing myself to others a lot,
            especially in Vietnam where I was constantly surrounded by really
            high-achieving students. However, I feel like studying abroad really
            broadened my definition of success. I feel like anything is
            possible. As long as dedicate yourself to it, you really can achieve
            anything. It just takes time.
          </p>
          <p>
            I still struggle with the constant comparison. But getting to see
            the world and the different lives everyone leads, again, really
            shows me that success can mean a lot of different things.
          </p>
          <p>
            <b>A:&emsp; </b>I feel like the biggest change I’ve seen in myself
            in the past 4 years was how I now interact with people. I think I
            used to be really shy. I’d never speak my mind. But after 3 years of
            college and a year of professional work, I feel like you’d lose out
            on a lot if you don’t speak your mind. You really have to show that
            you’re there and you’re willing to contribute.
            <b>
              Chị cũng thay đổi mindset của mình rất là nhiều. Từ “Mình không
              làm được điều đó đâu. Mình rất là ngại.” cho tới việc là “Hay là
              mình thử một chút xem sao.”
            </b>
            (I also feel like my mindset has shifted from “I don’t know how to”
            to “Let’s just give it a try anyway.”) Overall, I feel like I have
            matured a lot compared to 4 years ago.
          </p>{" "}
        </>
      );
    },
  },

  {
    magazine_id: 11,
    magazine_img: "/image/MagazineArticles/StudentLife/studentlife2.jpg",
    magazine_name:
      "FOR THE LOVE OF THEATER. A TRIPLE THREAT OF THEATER MAJORS TAKE CENTER STAGE AT CSULB",
    magazine_type: "studentlife",
    magazine_titleData: `LONG BEACH, Calif. (AP) — Tiara Estupinian sits in the basement of the Theatre Arts building. She applies her makeup
    and heads to the costume crew to retrieve her costume. After she gets her costume, she must head upstairs to the
    studio theater and prepare for the first dress rehearsal. It's a routine she's become all too familiar with as this
    now marks her third time being a part of a CalRep production. Not far behind her is Arlene Duran. Similar to Tiara,
    this is nothing new as this is now her second CalRep production. For the newly transferred Angel Robbson, though,
    everything about this has been new. A transfer from Solano Community College in Fairfield, California, this is her
    first time doing theater at the university level. All three of these actors have had different journeys, but for
    these past two months, they've arrived at the same destination: the mainstage of the CSULB Theatre Arts department
    to take part in the play entitled "For the Love Of (or, Roller Derby Play)."`,
    magazine_writer: "Theodore Taylor III",
    magazine_data: () => {
      return (
        <>
          <p>
            LONG BEACH, Calif. (AP) — Tiara Estupinian sits in the basement of
            the Theatre Arts building. She applies her makeup and heads to the
            costume crew to retrieve her costume. After she gets her costume,
            she must head upstairs to the studio theater and prepare for the
            first dress rehearsal. It's a routine she's become all too familiar
            with as this now marks her third time being a part of a CalRep
            production. Not far behind her is Arlene Duran. Similar to Tiara,
            this is nothing new as this is now her second CalRep production. For
            the newly transferred Angel Robbson, though, everything about this
            has been new. A transfer from Solano Community College in Fairfield,
            California, this is her first time doing theater at the university
            level. All three of these actors have had different journeys, but
            for these past two months, they've arrived at the same destination:
            the mainstage of the CSULB Theatre Arts department to take part in
            the play entitled "For the Love Of (or, Roller Derby Play)."
          </p>
          <p>
            When Angel first transferred to Long Beach State, she had plans of
            just going to school and working until she was approached by the
            chair of the theatre department, Ezra Lebank. "This was such a
            last-minute thing. Like this semester, I just wanted to go to school
            and work," Angel said. "The day before classes started, I just came
            to campus to find my classes, and that was the day they did
            callbacks for the show - So Ezra and another teacher stopped me and
            asked if I auditioned for the show." "I originally read for Arlene's
            role, and the director asked me to stay the rest of the night and
            read for the character of Joy, who I didn't know was the lead until
            all the other girls told me," Angel said. Angel plays the lead
            character of Joy Ride. A newcomer to the Roller Derby team, Joy is a
            "social, funny, laid-back, go-with-the-flow type of girl." Joy Ride
            is in an interracial relationship with the character of Michelle
            played by Jane U'Ren. While being a part of this rehearsal process,
            Angel has found these traits of Joy to be similar to herself. "She's
            a lesbian, I'm a lesbian, so that's one similarity off the bat,"
            Angel said. "In the script, Joy is written to be black, and that
            plays into her relationship with Michelle - within the script, there
            is subtext that shows that while I'm happy in this relationship,
            there are aspects of my life that you will never understand, and I
            relate to that because my partner is also white."
          </p>
          <p>
            This play itself deals with themes of love, family, games, and
            friendship. But when it comes down to friendship, throughout the
            rehearsal, it has been evident between 4th-year theater majors Tiara
            Estupinian and Arlene Duran. Despite having characters that rarely
            interact with one another, this rehearsal process has made them even
            closer than before. "We're best friends, we've never said that, but
            we hang out and text every day, she's made this process fun," says
            Tiara. In August of 2020, Tiara enrolled at the College of the
            Canyons in Santa Clarita, California, as a bio major. Two months
            later, she switched her major to theater, and that has led us here.
            Earlier this semester, Tiara took part in another CalRep produced
            piece entitled "A Night of Student Work." Immediately after Night of
            Student Work, she jumped straight into the rehearsals for Roller
            Derby. Tiara plays the character of Lizzie Lightning, the co-captain
            of the Roller Derby team who is independent, competitive, grounded,
            headstrong, and wants to get hers by any means necessary. When
            seeing that CalRep was going to produce an all-women piece that was
            also looking for Latino actresses, she knew this was the right piece
            for her to audition for. "I honestly thought we were going to skate,
            but when I heard that there were Latino roles in this play, had
            women coming together, and talked about the love of the game, that's
            something that relates to me a lot," said Tiara. "This piece breaks
            stereotypes by showing that a Latina can do anything and be a
            leader, or a woman can do anything without having to be told XYZ."
          </p>
          <p>
            Last semester, Arlene Duran played the protagonist Amy in the play
            entitled "Dry Land." This semester she's back on the main stage,
            this time playing a nurse named Anna Stecia who's responsible,
            funny, and empathetic and always looking to take care of people.
            Playing the character of Anna has led to Arlene showcasing traits
            that she normally suppresses. "I feel like Anna is my opportunity to
            bring out my inner badass," says Arlene. Growing up shy, Arlene
            began to take up theater in high school, and that's where her love
            for acting began. Her love has been evident throughout this
            semester. Despite trying to balance a job on the weekend, 8 AM to 10
            PM weekdays, and an hour-long commute, she wouldn't trade it for
            anything. Using downtime, she goes to the University Library or the
            green room in the theater building to finish school assignments. She
            also credits her 8 AM acting class with acting professor Hugh
            O'Gorman as being beneficial to this rehearsal process. "I feel like
            I've been able to connect more and find a story for my character by
            taking Hugh's class this semester," Arlene said. "It opens us up a
            lot more, and I feel very grounded and feel like I can explore a lot
            more in this world of Roller Derby."
          </p>
          <p>
            This dynamic triple threat, all with different journeys, have a
            shared love for the arts. That love has been reflected in these last
            few weeks of rehearsals. Alongside a superb cast and director, "For
            the Love Of (or, the Roller Derby Play)" will be "rolling" through
            the theatre arts building from Oct. 19 to 28 at 7:30 PM and on Oct.
            21 and 28 at 2 PM. For the Love Of is set to be an exceptional
            experience, don't miss your opportunity to catch this show.
          </p>
          <a href="https://www.tix.com/ticket-sales/csulb-theatre-arts/6797">
            Get your tickets here.
          </a>{" "}
        </>
      );
    },
  },

  {
    magazine_id: 10,
    magazine_img: "/image/MagazineArticles/StudentLife/studentlife3.jpg",
    magazine_name: "SUPPORTING FOSTER YOUTH AT CSULB",
    magazine_type: "studentlife",
    magazine_titleData: `Guardian Scholars (GS) is a program on campus that supports current and former foster youth at CSULB. 
    If you have been in the foster care system and feel eligible to become a Guardian Scholars member, you can apply directly on the Guardian Scholars website.`,
    magazine_writer: "",
    magazine_data: () => {
      return (
        <>
          <p>
            Guardian Scholars (GS) is a program on campus that supports current
            and former foster youth at CSULB. If you have been in the foster
            care system and feel eligible to become a Guardian Scholars member,
            you can apply directly on the Guardian Scholars website. Guardian
            Scholars at CSULB provide student employment and academic and
            emotional support. The CSULB Guardian Scholars have 125 students
            this semester. On-campus activities throughout the year include
            guest panels, monthly workshops, graduation ceremonies, and holiday
            dinners. Other benefits of participating in the Guardian Scholars
            include book grants, scholarships, career readiness, priority
            registration for classes, academic tutoring, academic and financial
            aid counseling, and summer and winter break support for students who
            are at risk of being homeless.
          </p>
          <p>
            This holiday season, CSULB students, who were part of the foster
            care system and are members of Guardian Scholars, are eligible for
            up to a $425 gift from One Simple Wish, a nonprofit that supports
            current and former foster youth. Each wish granted by One Simple
            Wish helps students reduce educational expenses, explore areas of
            interest, and fulfill their passion for learning something new.
          </p>
          <p>
            The holiday gifts offered by One Simple Wish, a Los Angeles
            nonprofit supporting foster youth, come from various donors,
            including private, corporate, and celebrity contributors. Some
            examples of past gifts to CSULB students include cameras, computers,
            gym memberships, furniture, clothing, and digital equipment like
            tripods, ring lights, and more.
          </p>
          <p>
            Guardian Scholars' collaboration with Fostering Futures (FFSO) is a
            student-run organization built to increase foster youth awareness,
            community, and sense of belonging. Anyone can join this student
            organization and be involved with upcoming events, such as creating
            care packages for group homes in Los Angeles County. To join this
            organization, you can follow their Instagram page at{" "}
            <a
              style={{ color: "#50B3CD" }}
              href="https://www.instagram.com/fosteringfutures_csulb/"
            >
              @fosteringfutures_csulb
            </a>{" "}
            and submit an "Officer Application" on the link tree in their bio.
          </p>
          <p>
            Guardian Scholars and Fostering Futures have hosted joint programs
            like GS Bowling Night, Hallow Harvest, and Friendsgivings. Programs
            like Guardians Scholars genuinely make a difference in supporting
            foster youth by navigating higher education and increasing student
            engagement.
          </p>
          <p>
            If you are a current or former foster youth and want more
            information, you can visit the{" "}
            <a
              style={{ color: "#50B3CD" }}
              href="https://www.csulb.edu/student-affairs/guardian-scholars/eligibility"
            >
              Guardian Scholars website
            </a>
            . The Guardian Scholars program benefits its members and truly cares
            about its participants by providing services and resources that
            influence a student's time and academic journey at CSULB.
          </p>{" "}
        </>
      );
    },
  },

  {
    magazine_id: 9,
    magazine_img: "/image/MagazineArticles/StudentLife/studentlife4.jpg",
    magazine_name:
      "Q&A WITH DANNY JOHNSON, EXECUTIVE DIRECTOR OF THE AIDS ASSISTANCE THRIFT STORE",
    magazine_type: "studentlife",
    magazine_titleData: `AIDS Assistance Thrift Store is a nonprofit charity that has operated in Long Beach for over 30 years. Daniel “Danny” Johnson, the store’s manager and executive director, moved to the LBC from Huntington Park in 1987 and helped found the organization five years later. Despite grappling with a pandemic shutdown, break-ins, rent hikes and multiple location changes, AIDS Assistance Thrift Store continues to serve customers on a near-daily basis. `,
    magazine_writer: "Leah Olds",
    magazine_data: () => {
      return (
        <>
          {" "}
          <p>
            AIDS Assistance Thrift Store is a nonprofit charity that has
            operated in Long Beach for over 30 years. Daniel “Danny” Johnson,
            the store’s manager and executive director, moved to the LBC from
            Huntington Park in 1987 and helped found the organization five years
            later. Despite grappling with a pandemic shutdown, break-ins, rent
            hikes and multiple location changes, AIDS Assistance Thrift Store
            continues to serve customers on a near-daily basis. <br />
            <br />
            On April 27, 2022, I spoke with Johnson about the store’s past,
            present, and future.
            <br />
            <br />
            <b>Q:</b> I would love to know a little about you and your
            experience with AIDS Assistance. How did that come into being?
            <br />
            <br />
            <b>A:</b> I used to work for the Gay and Lesbian Center here in Long
            Beach many, many years ago. Many moons ago. And I worked with
            Project Ahead, which was a case management agency that at one time
            was servicing almost the whole South Bay. It was before{" "}
            <a href="https://aplahealth.org/">APLA</a> [AIDS Project Los
            Angeles] was even founded. Anyway, we did case management and I
            worked with them for a while. And then, in 1991 is when we put the
            store together. And I was one of the founding members of it. We put
            it together, we were on Seventh Street, and we did it to form an
            agency that would help clients directly. So we put in a program,
            what we call our “voucher” program. As it exists now… there used to
            be more they used to send to us, but now, it consists of{" "}
            <a href="https://www.dignityhealth.org/socal/locations/stmarymedical">
              St. Mary Medical Center
            </a>
            ,{" "}
            <a href="https://www.longbeach.gov/health/">
              the Health Department
            </a>
            , [and] APLA, which is now in Long Beach. And they send their
            clients to us with vouchers, and they get free clothing and free
            household [items], that sort of thing. So that’s how I got started,
            and that’s how this place got started.
            <br />
            <br />
            <b>Q:</b> Do you feel proud of that legacy?
            <br />
            <br />
            <b>A: </b>Mhmm. Yeah! <br />
            <br />
            <b>Q:</b> What has working with a non-profit been like?
            <br />
            <br />
            <b>A:</b> Working for a nonprofit is good. Especially if it’s small.
            I don’t know if I could work for one that’s huge, you know. ‘Too
            many cooks spoil the broth,’ as they say. But this has been
            satisfying, and we just stick to what we do. What we do best. So
            yeah, I enjoy it.
            <br />
            <br />
            <b>Q:</b> Is there anything that you think people don’t know about
            the store, that you think they should know?
            <br />
            <br />
            <b>A:</b> People should know that we founded it with a purpose to
            help clients directly, and we’ve stuck with that. We just do it, and
            no other store does it. A lot of times people have the misconception
            that there people are getting rich off of this sort of thing, and we
            don’t. We’re making it, you know, paying the bills and that kind of
            thing. And that’s about as far as it goes. So that’s why we’ve
            always had people bringing things in, we love the donations.
            Everything is donated here. And so, we keep going just so we can
            serve our clients. That’s it, they just need to know that. <br />
            <br />
            <b>Q:</b> Do volunteers usually come in on a daily basis?
            <br />
            <br />
            <b>A:</b> We don’t schedule volunteers. We get all of our volunteers
            through the Volunteer Center here in Long Beach – people doing
            community service [for] traffic tickets and things of that sort.{" "}
            <br />
            <br />
            <b>Q:</b> What role, in your opinion, does the store fulfill within
            Long Beach, the queer community, and for people living with AIDS?
            <br />
            <br />
            <b>A:</b> For greater Long Beach, it's a great place to come find
            stuff and buy stuff. And it's a place to donate. So on that broad
            scale, when you narrow it down to our clients, the clients have
            various backgrounds. Whatever pronouns are out there, you know, we
            serve them all. We don't ask any questions. And I've had people call
            and say, “Can I come in and shop?” And they're shy, you know, they
            say, “You won't make fun of me?” And I go, “No, we don't make fun of
            anybody in here. This is a safe place to shop and we don't have any
            opinion one way or the other. Be yourself.” I treat everybody the
            same. We're all the same species.
            <br />
            <br />
            <b>Q:</b> What does the future hold for this location?
            <br />
            <br />
            <b>A:</b> We don’t know. Since COVID has hit and stuff like that,
            it’s been a little bit of a roller coaster ride – up and down, up
            and down. And we’ve had a few break-ins and stuff, and we keep
            remedying that with better security. We have a mean junkyard dog
            that’s here at night.
            <br />
            <br />
            <b>Q:</b> Really?
            <br />
            <br />
            <b>A:</b> [Laughs] No, we don’t.
            <br />
            <br />
            <b>Q:</b> Is there anything else you’d like to add?
            <br />
            <br />
            <b>A:</b> Our main goal here is to be put out of business with a
            cure. <br />
            <br />
            AIDS Assistance Thrift Store is located on 2782 E Anaheim Street, in
            Long Beach, California. For information about hours and services,
            call (562)-987-5353. You can also find the store on social media:{" "}
            <a href="https://www.instagram.com/lbaidsassist/?hl=en">
              @lbaidsassist
            </a>{" "}
            on Instagram, and{" "}
            <a href="https://www.facebook.com/lbaidsassist/">
              AIDS Assistance Thrift Store
            </a>{" "}
            on Facebook.
            <br />
            <br />
            This interview has been edited for length and clarity.
            <br />
            <br />
          </p>{" "}
        </>
      );
    },
  },

  {
    magazine_id: 8,
    magazine_img: "/image/MagazineArticles/StudentLife/studentlife5.png",
    magazine_name: "BETTER THAN A BAND-AID",
    magazine_type: "studentlife",
    magazine_titleData: `From the first day I started living independently, I've been telling myself to get a first aid kit. The little red helpers can be taken anywhere, they’re not very costly, and the utility of their contents pretty much goes without saying. After all, a person can only ignore razor nicks and torn cuticles for so long before a more serious injury is sustained, and it will probably require a better remedy than a Band-Aid. I will have wished I was more prepared for that pain.`,
    magazine_writer: "Leah Olds",
    magazine_data: () => {
      return (
        <>
          {" "}
          <p>
            From the first day I started living independently, I've been telling
            myself to get a first aid kit. The little red helpers can be taken
            anywhere, they’re not very costly, and the utility of their contents
            pretty much goes without saying. After all, a person can only ignore
            razor nicks and torn cuticles for so long before a more serious
            injury is sustained, and it will probably require a better remedy
            than a Band-Aid. I will have wished I was more prepared for that
            pain.
            <br />
            <br />
            I know I started to dip my toes into the proverbial deep-end in that
            last paragraph, but I wasn’t being entirely too figurative about the
            first aid kit. I’m still getting by with the multi-colored Band-Aids
            sitting on the top of the fridge, to the right of the box crammed
            with last month’s mail advertisements, and just behind the basket of
            herbal teas. <br />
            <br />
            But this piece isn’t really about cuts and scrapes, or gauze and
            antibiotics, or any other household medical supply. It’s about
            therapy and why I haven’t gotten any yet.
            <br />
            <br />
            Nowadays, it is easy to identify the precedent that has been set for
            the issue of mental health. Media platforms, health organizations
            and everyday folks actively engage in discourse regarding mental
            illnesses, addiction, anxiety and trauma, and help dismantle what
            was once a major taboo. <br />
            <br />
            This de-stigmatization process has been ongoing since my junior high
            school days. During seventh grade lunch hours, some of my peers
            openly discussed their susceptibility to panic attacks and self-harm
            ideation. Four years later, my mother and eldest brothers
            experienced severe depression for over six months. Now, as a
            22-year-old, I plainly acknowledge my dealings with those same
            struggles, which have only been inflamed by a very personal loss
            just last year.
            <br />
            <br />
            So I say that I value my own mental health, and point to the
            evidence of my chronic oversharing, or my keen listening skills when
            family members need to vent their frustrations for a night. But when
            people ask if I have considered finding legitimate counsel, my walls
            go up. I proceed to lay out a catalog of deflections, from, “Well, I
            don’t want to seem whiny” to “Therapy’s too expensive!”, to the
            always timeless classic, “What if it doesn’t really go anywhere?”
            <br />
            <br />
            The funny thing about my excuses is that they are insanely shallow,
            and arriving at their solutions requires little brain power. It's
            like my conscience snaps a non-corporeal finger at me and says,
            “Seriously? You don’t want to seem whiny? The person you will speak
            with is a licensed psychologist who gets paid to listen to your
            problems, so don’t worry so much about how they come across. You
            worry that you can’t afford therapy, but your university offers
            psychological services at no charge to students. Try making a phone
            call that isn’t to your girlfriend or your buddy, just this once.
            And dude, the last excuse is by far the silliest. Believing that
            therapy won’t ‘go anywhere’ but making no effort to try it is like
            worrying your car cannot get you to a destination when you haven’t
            even filled the tank!”
            <br />
            <br />
            Every one of these allowances can be whittled down to the very human
            fear of unforeseen change. I am afraid of something that has to
            happen, an inevitable devastation that will take away the clarity
            and confidence I might have gained in therapy. Much like those who
            feel reluctant to take medication, I worry that leaning on therapy
            as a sort of crutch will only lead to regret once the peace in my
            life is disturbed; or worse, if I don’t notice immediate changes in
            my emotional health. Recovery has always been regarded as a personal
            oasis, but for so long the concept has seemed more like a mirage to
            me. I’d like to challenge that skepticism.
            <br />
            <br />
            But can I allow myself to embrace an environment that promotes
            positive, healthy changes despite knowing that my life is not
            impervious to arguments, accidents, pain and loss? Well, I’d like to
            think that by publicizing my considerations here, I can solidify my
            willingness to not just accept help, but choose action. I may not be
            naïve enough to believe that life will only ever be smooth sailing,
            but that doesn’t mean I should let myself get hit by incoming tidal
            waves without first signaling for a rescue. <br />
            <br />
            I’ve learned that fear is the only thing I have become dependent on.
            It damages my self-respect and renders me passive toward my own
            mental health. I want a relationship with therapy that will no
            longer be about digging for thin excuses, or applying those
            temporary Band-Aids that smother my oozing inner turmoil. I want to
            let myself heal without getting in my own way, out of sheer anxiety.
            And above all, I want access to a first aid kit whose tools can
            better my mental health, affirming the love I have for myself and
            others around me. <br />
            <br />
            It is not going to be entirely too easy, figuring out how to seek
            out the healing agent who can guide me through painful life
            encounters. But at least I know not to look on top of the
            refrigerator anymore.
            <br />
            <br />
          </p>{" "}
        </>
      );
    },
  },

  {
    magazine_id: 7,
    magazine_img: "/image/MagazineArticles/StudentLife/studentlife6.png",
    magazine_name: "INTO THE MOJAVE",
    magazine_type: "studentlife",
    magazine_titleData: `Heading north in Los Angeles, taking Route 14, shooting through Palmdale, through Lancaster, and finally through Rosamond, you’ll find yourself in the Mojave desert and its handful of unique cities.`,
    magazine_writer: "Joey Martinez",
    magazine_data: () => {
      return (
        <>
          {" "}
          <p>
            Heading north in Los Angeles, taking Route 14, shooting through
            Palmdale, through Lancaster, and finally through Rosamond, you’ll
            find yourself in the Mojave desert and its handful of unique cities.
            <br />
            <br />
            I woke up at 5:30 in the morning and made the trip at 6:00, planning
            on making my first stop in California City. I found out about it a
            few months ago and after some research became very interested in
            visiting. The city was planned out by real estate developer Nat
            Mendelsohn in the 1960’s and was supposed to be a city large enough
            to rival LA. That never really came to be though. California City is
            still the third largest city in California, but if you go there you
            will see that only a small portion is developed with buildings. The
            rest of it is a large grid of winding suburban dirt roads, ones that
            you could drive through like a normal suburb.
            <br />
            <br />
            I went to a diner there to get breakfast first and I’m not usually
            so hungry in the morning but I found myself ordering a pretty big
            meal. As I sat at my table I noticed glances from people around me.
            Not any harsh ones. They were to my big curly hair and flowery
            Hawaiian shirt. The population of California City is around 20,000
            and this particular area was a bit detached from the rest, so it’s
            easy to determine they just knew I wasn’t from around there. The
            waitress confirmed it as she asked if I was new here, and also
            recommended that I visit a big cat sanctuary a little ways off. I
            found out it was closed though.
            <br />
            <br />
            After that, it was off to the empty lots. There is a fine line that
            separates the paved town and the dirt suburbia that stretches as far
            on the ground as you can see. It was hard to distinguish what was
            more surreal though, driving through streets of nothing or the walk
            I took right in the middle of them. Throughout the trip I’d been
            listening to music constantly. Walking out though, I was immediately
            hit by the strangest silence I’d experienced. There was quite a bit
            of wind but that was it. The ambience was low and deep, leaving me
            with the thoughts of what it would be like to just live out here
            with nothing but roads surrounding you. I walked up in elevation
            enough to get a larger view of the roads surrounding me. Some say
            Mendehlson didn’t fail when planning and implementing California
            City because he made a lot of money from the buyers that purely put
            money in as an investment. There’s a story about him though. It says
            that while he lived in the area he would sometimes go up to the top
            of Galileo Hill, a park in California City, and envision the
            sprawling city he dreamed of. You can go up to that hill yourself
            now. Maybe you could see the same thing he did. I didn’t get the
            chance though. Just my luck that the entrance was gated off for the
            day.
            <br />
            <br />
            I was very tired and I couldn’t even tell you now how much time I
            spent going through the streets, rummaging through broken dreams. So
            I decided to rest and read into an empty culdesac as my little
            farewell to the town.
            <br />
            <br />
            It was during my time here that I tried figuring out my next stop
            and decided that it would be Boron, the smallest town I'd ever
            visited. Arriving to it from the west, I drove through a hill where
            I could make out the town in the distance. For some reason it gave
            me a sinking feeling. There was nothing around it and I found out it
            was only 14 square miles with around 2000 people. <br />
            <br />
            There was one big grocery store, two gas stations, and just a
            handful of small restaurants. Everywhere I went in, I got that same
            look from back in the diner. If it was noticeable in California City
            that I wasn’t from here, I was a big, dumb alien in Boron. There was
            a moment in the grocery store when a group of men seemingly on break
            or getting off of work stopped to look at me as I went through the
            aisles. Again, like the diner, its just a look of seeing something
            not from around here. Surreal stuff to me, but I get it.
            <br />
            <br />
            After Boron I drove around for a while, collecting thoughts,
            figuring what I should do next. Then, I decided to head to Red Rock.
            I’ve heard about it a few times and I found a dirt road leading to
            campsites to drive through. Joshua trees and rocks collared red to
            yellow were hugging the sides and the sky had gotten rid of the few
            small clouds. I was too caught up in it to notice that the road
            narrowed and the dirt became more akin to gravel and sand. Several
            dips and small rocks made it impossible to drive my low Malibu
            faster than five miles per hour without running the risk of scraping
            my bumper. My phone was dying as well so I had to give up the aux
            for my charger. There also wasn’t a single comprehensible radio
            wave. So I rolled down my window and decided that it would be time
            to get comfy again with my good friend's silence, prompting me to
            gather my thoughts on the trip so far.
            <br />
            <br />
            Why am I here? The question had been stirring in my mind yet
            suppressing itself equally. California City was exciting for me but
            made me think about how tragic it is for such a successful man to
            have not been successful enough for the sake of his dream. He wanted
            a legacy, something the world could hold onto that was his when he
            was gone. Before this I hadn’t even thought of the idea that I could
            have a successful career yet never do what it is I truly wanted. I
            began to think of Boron too and how it adds to the idea. I saw a
            good number of people there that seemed so content working off of
            each other as a community. Am I doing something wrong by trying to
            make it in the city? How far should I take this writing stuff before
            I realize that I should just go somewhere smaller and live contently
            like that? <br />
            <br />
            However, aside from the interesting prospect of being amongst a
            field of one man’s dead dream in California City or going to the
            smallest town I've ever been to, Boron, I have a special spot in my
            heart for the desert. It stems from a trip I took once with my dad
            when I was about six or seven. I don’t remember where we were going
            or why. I only remember the trip. Back then I was a much quieter
            kid. Quiet enough that I could go on a whole car ride without saying
            much. A lot of my family really got on me for being quiet, but not
            my dad. We mostly talked only to point out things we saw to each
            other. In between those moments, 70’s to 90’s rock played on the
            radio, getting me into my favorite genre of music, and inspiring my
            soundtrack for this ride. He isn’t around anymore, so maybe that’s
            what I’m afraid of. Losing the memory I mean.
            <br />
            <br />
            I think it’s time to go back.
            <br />
            <br />
            Deciding I’d make my way back down from the desert a different way,
            I made it down to Santa Clarita, back in the city, immediately
            hating it. We all know parts of Cali where the existence of you
            seems to matter a whole lot to strangers who won’t ever have to even
            talk to you. I’ve never been particularly discriminated against, at
            least I don’t think so, but every so often I get a look that was
            abundant in Santa Clarita. They aren’t looks of acknowledgement like
            back in the desert. No, these were looks that I’ve gotten quite
            often from people of higher privilege than I. They try to figure me
            out, my race, and whether or not they should be concerned about the
            guy who just got in line for chicken. Most people catch on in some
            way that I’m Mexican but it is the ones that spend a concerning
            amount of time amongst people just like themselves that get iffy
            about a guy like me. You could say they're actually looking at
            something else, my shoes, my hair, maybe there's a hole in my shirt,
            but I know when someone is looking at my face.
            <br />
            <br />
            And with the gentle reminder of the city, I can’t tell whether I
            came back the same or worse. Definitely not better. As I talked with
            my friends about the trip I was making, they’d mention “finding
            enlightenment.” Even partly joking about it when I came back, asking
            me if I had. It was never about that though. It was originally
            California City and wanting to see a large concept dream fail in
            physical form. That’s what intrigued me. But that, combined with
            Boron, the drive, the silence, has only filled me with fear.
            <br />
            <br />
          </p>{" "}
        </>
      );
    },
  },

  {
    magazine_id: 6,
    magazine_img: "/image/MagazineArticles/StudentLife/studentlife7.jpg",
    magazine_name: "SULKING IN THE GAPS OF MY MEMORY",
    magazine_type: "studentlife",
    magazine_titleData: `It took me a really long time to write this article, and I didn’t know why, but as soon as I finished, I realized that this is one of the only times I’ve confronted how scary it is having such large gaps in my memory.`,
    magazine_writer: "Jude De Luna",
    magazine_data: () => {
      return (
        <>
          {" "}
          <p>
            It took me a really long time to write this article, and I didn’t
            know why, but as soon as I finished, I realized that this is one of
            the only times I’ve confronted how scary it is having such large
            gaps in my memory.
            <br />
            <br />
            I never thought of it seriously, mainly because I have avoided
            addressing the topic directly for a long time. I joked a lot about
            how having a bad memory can be a kind of superpower. I can consume
            my favorite pieces of media time and time again and it feels new
            every time. I get spoilers and I can just distract myself enough to
            forget it. Some embarrassing moments are immediately blocked out. No
            randomly remembering some awkward moment from the fourth grade for
            me!
            <br />
            <br />
            With all of those obviously outstanding positives on one side, the
            negatives of this so-called power surely cannot stand a chance,
            right? Well, it’s more like a curse that I never learned how to
            face. I cannot reconcile with the fact that I can’t even remember
            things like the conversations I have with my friends and family.
            People tell me details about themselves in passing, like their
            favorite color, their allergies, and even their birthday and I can
            hardly recall. The conversation becomes a block of TV static in my
            mind, and it’s not like I wasn’t interested. It just couldn’t stick!
            I even had a somewhat completed draft written out for this article
            all in my head, but – surprise, surprise – I forgot most of what I
            wanted to say. You would think that I would know myself well enough
            by now, but I always overestimate the capacity of my negligible
            memory.
            <br />
            <br />
            One thing that really gets me is how I cannot remember most of my
            younger years. If I were to estimate how much I really recall, I
            would say that I know 5% of my elementary and middle school days,
            and about 20% of high school. Of course there’s no random awkward
            fourth grade memory to torture me, because there’s nothing at all
            stored in my miniscule memory storage unit. My friends and family
            bring up how I used to act when I was younger, and I can’t ever
            believe what they say. They tell stories, and then it’s me
            interrupting saying, “I did that?” or “Wait, what happened?” It is
            frustrating, and if I were to let this continue, who would I become?
            How will I grow if I cannot remind myself of who I used to be? The
            possibility of me regressing into an old me grows when I cannot
            remember my history, no matter how cringey it may be.
            <br />
            <br />
            Even some of my so-called “best days of my life” are part of
            memories that escape me. There are so many things that I can recall
            doing, like traveling with my family around the Philippines, going
            to see my favorite artists in concerts, and even my graduation, but
            the specifics of these pivotal moments seemingly evade my every try
            to remember. I know that it happened, but the fact that I will never
            be able to truly look back on all the little particulars fondly is
            disheartening, and frightening.
            <br />
            <br />
            A few years ago, I realized that journaling was something that could
            help with this little problem. I filled up a journal every year.
            When my friends asked me why I liked to journal, I always said that
            I liked to reflect on my feelings every night, making myself sound
            so emotionally aware. Sure, that was definitely an added bonus to
            it, but it’s not like it was the truth. I toiled away in those
            little notebooks for the sole reason of documenting every single
            thing that happened every day. I brought my journal around and wrote
            in it all of the things that I liked or disliked. I noted down all
            the jokes that made me laugh, the things that annoyed me, and the
            overall people watching I had done. This went for most of high
            school until my first year of college. Meanwhile, a new predicament
            arose in the years. As I reminisce, the only thing I see is my
            journaling. I was too worried, and I even remember thinking “I’m
            going to journal this, I need to remember this” but I never retained
            the actual memory. Reading the journals was a poor substitute for
            the real thing, but I had grown too dependent at that point. My hand
            was weary from all the writing, but if it weren’t for me writing
            everything down, what would become of me?
            <br />
            <br />
            Well, one day in the summer before my sophomore year in college, I
            found that my mother threw away all of my journals. She said it was
            just collecting dust, serving no real purpose. Those entries were
            the only thing I had to cling onto in my teenage years, and they
            were instantly gone. I obviously don’t remember October 5, 2017, but
            my journal did. Nevertheless, it was forcibly erased. After that, I
            didn’t have the energy to continue. I let myself, and my mind, waste
            away. Then, the pandemic started and my motivation to remember
            things diminished even more. I can’t think of anything from 2020,
            but at least in this case, I am sure I am not alone in that.
            <br />
            <br />
            As the world goes on, with things gradually getting better, I have
            grown to regret that period of time that I let myself go, but I have
            also realized that it wasn’t good to obsess over journaling so much.
            It has taken a long time for me to reconcile with the fact that I
            can’t remember everything in my life. The fear is there, but it is
            not too strong anymore. As I go forward, I will enjoy the moment
            instead of scribbling it all down. I definitely don’t remember how I
            got here exactly, but it must’ve been good since I know I want to
            keep going. I’ll have my friends and family with me to remind me of
            the good times, and to create new ones that I can cherish, no matter
            how short-term it may be.
            <br />
            <br />
          </p>{" "}
        </>
      );
    },
  },

  {
    magazine_id: 5,
    magazine_img: "/image/MagazineArticles/StudentLife/studentlife8.png",
    magazine_name: "STUDENTS BRING AWARENESS TO CLIMATE ISSUES ON CAMPUS",
    magazine_type: "studentlife",
    magazine_titleData: `Recycling bins, hydration stations, sustainable straws, and transportation are some resources that Long Beach State University has incorporated on campus.`,
    magazine_writer: "Kadie Gurley",
    magazine_data: () => {
      return (
        <>
          {" "}
          <p>
            Recycling bins, hydration stations, sustainable straws, and
            transportation are some resources that Long Beach State University
            has incorporated on campus. As a matter of fact, there is a program
            on campus to integrate sustainability. The environment is something
            that is affecting not only staff or faculty, but students too.
            According to a Students Organizing For Sustainability International
            survey amongst 7,000 students in higher education, 92% agree that
            sustainable development is something which all universities and
            colleges should actively incorporate and promote. The world is at a
            place where the younger generation is becoming “woke” about current
            climate and environmental issues. The students in the Environmental
            Science and Policy club are making a difference on and off campus.{" "}
            <br />
            <br />
            “Essentially, we meet once a week for 30 to 45 minutes and like to
            discuss an environmental topic that the club votes on,” said Naya
            Shah, environmental science and policy major. “You can come in and
            learn something new about an interesting topic or a way to help out,
            just kind of share ideas.”
            <br />
            <br />
            The club wanted to make sure this message was clear. “Environmental
            science is something that gets a lot of different majors involved
            because environmental issues are so prevalent and everyone seems to
            care a lot,” said Alex Lynch, environmental science and policy
            major. <br />
            <br />
            Shah and Lynch, both environmental science and policy majors,
            mentioned how you do not necessarily need to be that major to
            participate in the club or any of their events. “Our club is always
            open to any major and we get plenty of people from other majors to
            come and talk about the big topic of the day which is the climate
            issue,” said Lynch. “They just want to know more about what is going
            on.”
            <br />
            <br />
            With environmental issues coming to light, there is a need for more
            people to take action. The ES&amp;P club is striving to help more
            students apply for scholarships. Also, they want to help students
            get internships and potentially a career to help tackle the climate.
            On April 12, ES&amp;P hosted a career panel for students interested
            in working in environmental science. “The career panel is
            essentially a little job fair for environmental science,” said Shah.
            “It is a way for people who are looking for internships to get
            started, people who are listening to seek work to get started, and
            just general knowledge.” <br />
            <br />
            As mentioned before, they host on campus events and meetings, but
            the club partakes in off-campus activities such as hiking and their
            event on April 22. ES&amp;P marched to Target in protest of the
            corporation partaking in several emissions at the port. <br />
            <br />
            “We are co hosting a march protest to Target and co-hosting it with
            a group that is called Pacific Environment,” said Lynch. “We are
            basically trying to bring awareness to the fact that Target is
            participating in a lot of emissions at the port, which causes a lot
            of damage to people's lives. We are walking from the Pyramid to the
            Target south of campus. We are just trying to bring it up so that
            maybe they will have to deal and kind of reckon with and make some
            changes to reduce their emissions at the port and otherwise.”
            <br />
            <br />
            When asked if the campus is doing a decent job in adapting to needed
            sustainability changes, this is what the club had to say. “We have a
            sustainability department, which is good, a good step because they
            push, meet with, and work with all other departments to try to
            improve their sustainability the best they can,” said Lynch. “At the
            end of the day, it takes pressure from all sides. Adding more solar
            panels and native gardening is a big thing that we could do a lot
            better on, but there is no pressure to do it.”
            <br />
            <br />
            These students look forward to influencing others to make a
            difference and using their voices to make a change in our
            environment one day at a time. If you want to learn more about
            getting involved in the ES&amp;P club or attend club meetings, visit
            their Instagram @csulbesp or the ASI website. <br />
            <br />
          </p>{" "}
        </>
      );
    },
  },

  {
    magazine_id: 4,
    magazine_img: "/image/MagazineArticles/Arts/arts7.png",
    magazine_name: "FULL-BODIED",
    magazine_type: "arts",
    magazine_titleData: `Young women poised in their full glory. 
    While others are allowed to tell them why they’re worthy.
    Be my visual, be my muse!`,
    magazine_writer: "Arielle Zepeda",
    magazine_data: () => {
      return (
        <>
          <p>
            Young women poised in their full glory. <br />
            <br />
            While others are allowed to tell them why they’re worthy.
            <br />
            <br />
            Be my visual, be my muse! <br />
            <br />
            Anger driven men when women refuse
            <br />
            <br />
            Oh, that’s ok I didn’t want you anyway
            <br />
            <br />
            But he’s mad because I was the one who walked away
            <br />
            <br />
            Sittin’ here in their voluptuous folds <br />
            <br />
            Masculinity walks by and says, dayummmm girl you look thick <br />
            <br />
            But behind closed doors, fat girls make me sick.
            <br />
            <br />
            In hierarchy, women are to entertain
            <br />
            <br />
            for the people who are extremely vain.
            <br />
            <br />
            Deprivation of the things they love,
            <br />
            <br />
            some of those ”big bodied” women need a hug. <br />
            <br />
            stomach’s round and unique <br />
            <br />
            With stretch marks to add to her gorgeous physique <br />
            <br />
            Night and day starvation excites -- <br />
            <br />
            the thought of fitting into something nice and tight. <br />
            <br />
            Go on, that’s how you wear them right! <br />
            <br />
            Let those nalgas stand out big and bright <br />
            <br />
            We don’t do it for the others who despise
            <br />
            <br />
            They’re not the ones with these big ol’ thighs.
          </p>
        </>
      );
    },
  },

  {
    magazine_id: 3,
    magazine_img: "/image/MagazineArticles/Arts/arts8.jpg",
    magazine_name: "BEHIND THE COVER",
    magazine_type: "arts",
    magazine_titleData: `I chose Malala Yousafzai because I believe she is the living embodiment of what it means to stand against oppression. The simple act of receiving an education was considered rebellious for women in Pakistan. `,
    magazine_writer: "Patrick Daugherty",
    magazine_data: () => {
      return (
        <>
          <p>
            I chose Malala Yousafzai because I believe she is the living
            embodiment of what it means to stand against oppression. The simple
            act of receiving an education was considered rebellious for women in
            Pakistan. <br />
            <br />
            Despite the relentless danger lurking among the region, she carried
            on with her education. Malala nearly paid the ultimate price for
            pursuing an education, when she was brutally attacked by the
            Taliban, on the 9th of October 2012. The Taliban attempted to
            silence Malala permanently with a bullet to her head, but Malala
            survived the gunshot wound, and would go on to become the youngest
            person ever to win the Nobel Peace Prize. <br />
            <br />
            Her activism for women’s rights and severe criticism of oppressive
            governments has energized an entire generation of activists fighting
            for women’s equality. Malala reminds us about the pedestal we stand
            on when we receive our education, how lucky we are to only have to
            worry about the fragile fabrics of reality that make up the college
            student psyche. <br />
            <br />
            She is a breathing reminder to the privileged educated class that we
            must never settle for oppression, and must endlessly fight the
            treacherous battle for educational equality no matter what flag we
            fly.
            <br />
          </p>

          <div
            style={{
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "450px",
                borderColor: "white",
                borderStyle: "solid",
              }}
            >
              {" "}
              <p>
                Note from an editor - Andres Leon
                <br />I wanted to acknowledge that while the topics in this
                issue of 22 West Magazine were mostly focused on identity and
                were light-hearted, there are still many injustices against
                women that were not covered. I would also like to apologize for
                the absence of a Black History Month issue. It would have been
                published in February, but the timing was off as the semester
                had just begun. Hopefully next year the interest in volunteering
                for this magazine will be more present so that we are able to
                cover more topics to represent marginalized groups on campus
                better. Nevertheless, thank you for your support thus far and I
                look forward to bringing more awareness to various issues using
                this magazine.
              </p>
            </div>
          </div>
        </>
      );
    },
  },
  {
    magazine_id: 2,
    magazine_img: "/image/MagazineArticles/Arts/arts5.jpg",
    magazine_name: "HIPPOPOTOMONSTROSESQUIPPEDALIOPHOBIA",
    magazine_type: "arts",
    magazine_titleData: `A conceptualization of incomprehensibility
    With endless postulations of consternation
    Do these words grant any invincibility
    Or fall short with the sense of floccinaucinihilipilification
    Like the repetitive learning in the 6th grade on Mesopotamia
    How long can we go before the effects of trichotillomania
    Set in`,
    magazine_writer: "Avery Keller",
    magazine_data: () => {
      return (
        <>
          <p>
            A conceptualization of incomprehensibility
            <br />
            With endless postulations of consternation
            <br />
            Do these words grant any invincibility
            <br />
            Or fall short with the sense of floccinaucinihilipilification
            <br />
            Like the repetitive learning in the 6th grade on Mesopotamia
            <br />
            How long can we go before the effects of trichotillomania
            <br />
            Set in
            <br />
            <br />
            All of the thoughts cloud in motion contemporaneous
            <br />
            Constantly camouflaged in the crimes of academia
            <br />
            Is it enough to be honorificabilitudinitatibus
            <br />
            Never ending that feeling of claustrophobia
            <br />
            We can only turn our heads in these straightjackets
            <br />
            Should we throw it all out and move to Massachusetts
            <br />
            For what
            <br />
            <br />
            Is it any different or all just interchangeable
            <br />
            Breathe in breathe out let it go to nothingness
            <br />
            As we keep going and going does it become unintelligible
            <br />
            When will we begin the brennschluss
            <br />
            The wheels stop turning the plane plummeting
            <br />
            Will someone catch it or are they too headstrong
            <br />
            Do they even know
            <br />
            <br />
            Supercalifragilisticexpialidocious
            <br />
            Antidisestablishmentarianism
            <br />
            Pneumonoultramicroscopicsilicovolcanoconiosis
            <br />
            Xenotransplantation
            <br />
            Pseudopseudohypoparathyroidism
            <br />
            Newtown&shy;mount&shy;kennedy
            <br />
            Is it all too much
            <br />
            <br />
          </p>
        </>
      );
    },
  },

  {
    magazine_id: 1,
    magazine_img: "/image/MagazineArticles/Arts/arts6.png",
    magazine_name: "“VITALITY”",
    magazine_type: "arts",
    magazine_titleData: `Words launch like asteroids
    and howl towards the World–
    a blinding inferno that paints the sky like
    blood that never spills.`,
    magazine_writer: "Anna Brown",
    magazine_data: () => {
      return (
        <>
          <p>
            Words launch like asteroids
            <br />
            and howl towards the World–
            <br />
            a blinding inferno that paints the sky like
            <br />
            blood that never spills.
            <br />
            <br />
            And the World holds Her breath,
            <br />
            tilting on Her axis like a crumbling tower and
            <br />
            exposing a frail surface, waiting
            <br />
            for the familiar embrace of fire.
            <br />
            <br />
            Because She knows asteroids.
            <br />
            How they crumble to dust and ash,
            <br />
            strangling Her, blanketing the sky
            <br />
            with noxious clouds.
            <br />
            <br />
            Yes, the sand seeps into canyons and trenches,
            <br />
            slipping through every crevice–
            <br />
            wriggling its way into history, a parasite
            <br />
            detected, yet never exterminated.
            <br />
            <br />
            The sun winks into a memory,
            <br />
            flowers and trees wither and decay,
            <br />
            and even the roaring tiger collapses for
            <br />
            a deep, weary slumber in a bed
            <br />
            <br />
            Of frigid, crystallizing grass.
            <br />
            Until the sun blazes again,
            <br />
            they never bloom nor wake.
            <br />
            And still, She resigns to every asteroid
            <br />
            <br />
            Because the quiet World is familiar.
            <br />
            These dusty asteroids can be housed
            <br />
            And the Apocalyptic asteroids cannot.
            <br />
            The ones that explode and
            <br />
            <br />
            Score Her surface with craters and
            <br />
            blast plants and felines and
            <br />
            consume them all with fire and floods
            <br />
            and fractures that can’t be repaired–
            <br />
            <br />
            The asteroids born of speech cannot do this.
            <br />
            If She must choose between the two,
            <br />
            She will resign herself
            <br />
            To the familiar World of ash.
            <br />
          </p>
        </>
      );
    },
  },
]);
