import React from "react";
import "../../style/Page404/index.scss";
import { NavLink } from "react-router-dom";

const Page404 = (props) => {


  return (
    <>
      <div className="Page404">
              <img src="/image/page404/page404.svg" alt="404" className="Page404_img"/>
              <div className="Page404_imgtext">The page you are trying to reach is unavailable or does not exist.  Please check the link or return home.</div>
              <NavLink to="/"  className="homeReturn">Home</NavLink>
      </div>
    </>
  );
};

export default Page404;
