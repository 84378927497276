import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useAtom } from "jotai";
import { DarkModeState } from "../../Jotai/index";

export default function GenderPieChart({data, colors}) {

    const [darkMode, setDarkMode] = useAtom(DarkModeState);

    return (
        <PieChart
            colors={colors}
            series={[
                {
                    data,
                },
            ]}
            slotProps={{
                legend: {
                  labelStyle: {
                    fontSize: 24,
                    fill: 'var(--text-color)',
                  },
                  itemGap: 20,
                },
              }}
            width={700}
            height={350}
        />
    );
}
