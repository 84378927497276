import React from "react";
import "../../style/magazine/index.scss";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { NavLink } from "react-router-dom";
const Magazine = (params) => {
  return (
    <>
      <div className="MagazineOpen">
        <div className="MagazineOpen_card">
          <div className="MagazineOpen_cardsrc">
            <img
              src={"../../" + params.magazineInfo.magazine_img}
              alt="logo1"
              className="titleImage"
            />
            <div className="MagazineOpen_text">
              <div className="MagazineOpen_type">{params.magazineInfo.magazine_type.toUpperCase()}</div>
              <div className="MagazineOpen_mgname">{params.magazineInfo.magazine_name}</div>
              <div className="MagazineOpen_titleData">{params.magazineInfo.magazine_titleData}</div>

              <div className="MagazineOpen_name">Written by {params.magazineInfo.magazine_writer}</div>
              <div className="MagazineOpen_data">
                    {params.magazineInfo.magazine_data()}
              </div>
              {/* redirect to issuu website - issuu button */}
              <div className="MagazineIssueBtn">
                  <NavLink to="https://issuu.com/22westmagazine" target="_blank" className= "ReadFullIssuebtn">Read Full Issue</NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Magazine;
