import React from "react";
import "../../style/WhatHappening/index.scss";
import { DarkModeState } from "../../Jotai/index";
import { useAtom } from "jotai";
import { Link } from "react-router-dom";

const WhatHappening = (props) => {
  const [darkMode, setDarkMode] = useAtom(DarkModeState);

  return (
    <>
      <div className="WhatHappening">
        <div className={darkMode ? "WhatHappeningMain darkmode" : "WhatHappeningMain"} >
          <div className="WhatHappening_titlebar">
            <div className="WhatHappening_title">What’s Happening?</div>
            <div className="WhatHappening_titleicon">
              <div className="WhatHappening_titleiconmainDiv">
                <div  className="WhatHappeningIcon">Follow us</div>
                <Link to={"https://www.instagram.com/22WestLB/"} target="_blank">
                <img src="/image/logo/Insta.svg" alt="icon" className="WhatHappeningIcon"/>
                </Link>
                <Link to={"https://www.tiktok.com/@22westlb"} target="_blank">
                <img src="/image/logo/tiktok.svg" alt="icon"  className="WhatHappeningIcon" />
                </Link>
                 <Link to={"https://www.youtube.com/@22WestVideo"} target="_blank">
                <img src="/image/logo/youtube.svg" alt="icon" className="WhatHappeningIcon"/>
                </Link>
              </div>
            </div>
          </div>

          <div className="WhatHappening_imgSec">
            {/* <img src="/image/WhatHappening/sa1.png" alt="WhatHappeningImg" className="WhatHappeningImg"/>
            <img src="/image/WhatHappening/sa4.png" alt="WhatHappeningImg" className="WhatHappeningImg"/>
            <img src="/image/WhatHappening/sa3.png" alt="WhatHappeningImg" className="WhatHappeningImg"/>
            <img src="/image/WhatHappening/sa3.png" alt="WhatHappeningImg" className="WhatHappeningImg"/>
            <img src="/image/WhatHappening/sa2.png" alt="WhatHappeningImg" className="WhatHappeningImg"/>
            <img src="/image/WhatHappening/sa1.png" alt="WhatHappeningImg" className="WhatHappeningImg"/> */}
           <iframe width={"100%"} src="https://www.instagram.com/22WestLB/embed/" className="instagramImg" frameBorder={0} />

          </div>

          {/* <div className="LinktreeBtnDiv">
            <div className="LinktreeBtn">Check out our Linktree</div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default WhatHappening;
