import React from "react";
import "../../style/Sports/index.scss";
import { useAtom } from "jotai";
import { DarkModeState } from "../../Jotai/index";
import { NavLink } from "react-router-dom";

const Sports = ({id}) => {
    const [darkMode] = useAtom(DarkModeState);

    return (
        <>
            <div id={id} className="sports-HomeLive">
                <div className="sports-HomeLiveCard">
                    <div className={darkMode ? "sports-cardSection darkmode" : "sports-cardSection"}>
                        <div className="sports-liveRadioTitle">
                            <div className={darkMode ? "sports-liveRadioTitleTxt" : "sports-liveRadioTitleTxt whitemode"}>Sports</div>
                            <div className="sports-instagramhandle">@22WestSportsLB</div>
                        </div>
                        <div className={darkMode ? "sports-radioPlaylistNameactive darkmode" : "sports-radioPlaylistNameactive"}>
                            <a href="https://www.instagram.com/22westsportslb/" target="_blank" rel="noopener noreferrer">
                                <img src="/image/Sports/Sports.png" alt="Sports Section Image" className="sports-AnnouncementsImgData"/>
                            </a>
                        </div>
                    </div>
                    {/* <div className={darkMode ? "sports-cardSection-livestream darkmode" : "sports-cardSection-livestream"}>
                        <div className="sports-liveRadioTitle">
                            <div className={darkMode ? "sports-newVideoSection":"sports-newVideoSection whitemode"}>Livestream</div>
                            <div className="sports-brodcastdetails">Broadcasts every Fridays 11 AM - 4 PM</div>
                        </div>
                        <div className="sports-videoIframe">
                        <iframe width={"100%"} src="https://www.youtube.com/embed/YXoA7EUpAaY?si=vf-tdz3SAeeSYmPz&controls=0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default Sports;
