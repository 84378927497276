import React from "react";
import "../../style/MagazineView/index.scss";
import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { MagazineData } from "../../Jotai/index";
import Magazine from "../../components/magazine/Magazine";
import LatestArticles from "../../components/LatestArticles/LatestArticles";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

const MagazineView = (props) => {
  const { magazineId } = useParams();
  console.log(magazineId);
  const [Magazinedata, setMagazineData] = useAtom(MagazineData);

  return (
    <>
      <div className="MagazineView">
    <ScrollToTop></ScrollToTop>
        {Magazinedata.map((data) => {
          if (data.magazine_id === parseInt(magazineId)) {
            return <Magazine magazineInfo={data} />;
          }
        })}

        <LatestArticles/>
      </div>
    </>
  );
};

export default MagazineView;
