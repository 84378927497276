import React, { useState } from "react";
import "../../style/LatestArticles/index.scss"
import { useAtom } from "jotai";
import { DarkModeState } from "../../Jotai/index";
import { MagazineData } from "../../Jotai/index";
import { Link, NavLink, useLocation } from "react-router-dom";
import { motion, Variants } from "framer-motion";




const LatestArticles = () => {


    const cardVariants = {
        offscreen: {
          y: 300
        },
        onscreen: {
          y: 50,
          rotate: -10,
          transition: {
            type: "spring",
            bounce: 0.4,
            duration: 0.8
          }
        }
      };



    const [darkMode, setDarkMode] = useAtom(DarkModeState);
    const [showArt, setshowArt] = useState(3);
    const [Magazinedata, setMagazineData] = useAtom(MagazineData);


    return (
        <>

            <div className="LatestArticles">

                <div className={darkMode ? "LatestArticlesMain darkmode" : "LatestArticlesMain"}>

                    <div className="LatestArticlesTitle">
                        Latest Articles
                    </div>
                    <div className="LatestArticlesCards">
                        {

                            Magazinedata.map((data, count) => {


                                if (count < showArt) {

                                    return (<>

                                        <NavLink to={"/magazineSearch/" + data.magazine_id} className="navLinkDesign">
                                            <div className={darkMode ? "ArticlesCards" : "ArticlesCards ArticlesCardslightmode"}>
                                                <img className="ArticlesCardsimage" src={data.magazine_img} alt="img" />
                                                <div className="ArticlesCardstopic">{data.magazine_name}</div>
                                                <div className="ArticlesCardsText">{data.magazine_titleData}</div>
                                            </div>
                                        </NavLink>

                                    </>)
                                }
                                else {
                                    return (<></>);

                                }
                            })}

                    </div>
                    <div className="LatestArticlesShowBtn">
                    {console.log(showArt)}
                        {(showArt >= Magazinedata.length) ? <></> : <div className={darkMode ? "btnShowmore" : "btnShowmore lightmodeBtn"} onClick={() => setshowArt(showArt + 3)}  >   More articles...
                        </div>}
                    </div>



                </div>
            </div>
        </>
    );
};

export default LatestArticles;
