import React from 'react';
import '../../style/ShowModal/index.scss'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/system';

const CustomModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const CustomBox = styled(Box)({
  position: 'absolute',
  width: 500,
  backgroundColor: 'white',
  borderRadius: 2,
  color:'black', 
  boxShadow: '24px',
  padding: '16px',
  '&:focus': {
    outline: 'none',
  },
});

const CustomAvatar = styled(Avatar)({
  width: '80px',
  height: '80px',
});

const CustomTypography = styled(Typography)({
  textAlign: 'center',
  marginTop: '8px',
});

const ShowModal = ({ open, handleClose, selectedShow }) => {
  return (
    <CustomModal open={open} onClose={handleClose}>
      <CustomBox>
        <div className='showDescription-Header'>
            <CustomAvatar alt={selectedShow.name} src={selectedShow.image} />
            <div className='showDescription-Author'>
                <CustomTypography variant="h6">{selectedShow.name}</CustomTypography>
                <CustomTypography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                Director: {selectedShow.director}
                </CustomTypography>
            </div>
        </div>
        
        <CustomTypography variant="body2" sx={{ mt: 2 }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse
          lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.
        </CustomTypography>
      </CustomBox>
    </CustomModal>
  );
};

export default ShowModal;
