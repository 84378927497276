import React,{useEffect} from "react";
import "../../style/Magazines/index.scss"
import Herobanner from "../../components/herobanner/Herobanner";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import MagazineTypes from "../../components/MagazineTypes/MagazineTypes";
import InfoCard from "../../components/InfoCard/InfoCard";
import Advertisements from "../../components/Advertisements/Advertisements";
import RecentArticle from "../../components/RecentArticle/RecentArticle";
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';

const Data = [
  {
      title:"Magazine",
      imageLink:"/image/mgbanner.png",
      text:"22 West Magazine, a California State University, Long Beach staple since 1977, is a student-run and curated publication dedicated to serving and engaging the university community.",
      btnName:"Current Issue",
      btnLink:"https://issuu.com/22westmagazine",
      btnColor:"#5F6F52",
      btnTextColor:"white"
  }
]


const Magazines = () => {
  const location = useLocation();
  useEffect(() => {
    // Parse the query parameters
    const queryParams = new URLSearchParams(location.search);
    const scrollTo = queryParams.get('scrollTo');
    // If scrollTo parameter is present, scroll to the target element
    if (scrollTo) {
      scroller.scrollTo(scrollTo, {
        duration: 500,
        smooth: true,
        offset: -200,
      });
    }
  }, [location]);
  
  return (
    <>
      <ScrollToTop/>
      <Herobanner imageData={Data}/>
      {/* <Advertisements/> */}
      {/* tab switch functionality & magazines/articles of specific types */}
      <MagazineTypes  id="Magazines" />
      

      <InfoCard  id="InfoCard" />
    </>
  );
};

export default Magazines;