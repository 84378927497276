import React from 'react';
import { useAtom } from 'jotai';
import { DarkModeState } from '../../Jotai/index';
import "../../style/ContactInfoRadio/index.scss"; 

const ContactInfoRadio = () => {
  const [darkMode] = useAtom(DarkModeState);

  // Function to open email client
  const sendEmail = (emailAddress) => {
    window.location.href = `mailto:${emailAddress}`;
  };

  // Function to open link
  const openLink = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className={darkMode ? "bottom-section darkmode" : "bottom-section"}>
      {/* <div className={darkMode ? "bottom-card music-feature-card":"bottom-card music-feature-card whitemode"}>
        <h2>Feature your music on Radio!</h2>
        <p>Looking for a platform for your songs? 22 West Radio is for you! Reach us at: info@22westmedia.com</p>
        <button onClick={() => sendEmail('info@22westmedia.com')}>CONTACT</button>
      </div> */}
      <div className={darkMode ? "bottom-card questions-card":"bottom-card questions-card whitemode"}>
        <h2>Questions or Work Order?</h2>
        {/* <p>Email Radio GM Michael Hinojos, RadioManager@22WestMedia.com</p> */}
        <p>Email Radio Morgan Barela, RadioManager@22WestMedia.com</p>
        <button onClick={() => sendEmail('RadioManager@22WestMedia.com')}>EMAIL</button>
      </div>
      {/* <div className={darkMode ? "bottom-card discord-card":"bottom-card discord-card whitemode"}>
        <h2>We have a 22 West Radio Discord!</h2>
        <p>In order to join, please click this button below</p>
        <button onClick={() => openLink('https://discord.gg/A8agMWVwxX')}>INFO</button>
      </div> */}
    </div>
  );
};

export default ContactInfoRadio;
