import React, { useEffect } from 'react';
import "../../style/Video/index.scss"
import Herobanner from "../../components/herobanner/Herobanner";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import LatestVideo from "../../components/LatestVideo/LatestVideo";
import Shows from "../../components/Shows/Shows";
import BehindtheScenes from "../../components/BehindtheScenes/BehindtheScenes";
import Videobanner from "../../components/Videobanner/Videobanner";
import Advertisements from "../../components/Advertisements/Advertisements";
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';
const Data = [
  {
      title:"Television",
      imageLink:"image/Video/videoBanner.jpg",
      text:"Founded in 2000, 22 West Television is a student-run broadcast outlet at California State University, Long Beach, that produces episodic and narrative content, fostering student engagement and creativity.",
      btnName:"none",
      btnLink:"/",
      btnColor:"#5F6F52",
      btnTextColor:"white"
  },
  // {
  //     title:"Radio",
  //     imageLink:"/image/Logo1.jpg",

  //     text:"Dive into soundscapes on our dynamic radio.",
  //     btnName:"Go to show page",
  //     btnLink:"/radio",
  //     btnColor:"#3468C0",
  //     btnTextColor:"white"
  // },

  // {
  //     title:"Magazine",
  //     imageLink:"/image/Logo2.jpg",

  //     text:"Explore the latest trends on our chic magazine page.",
  //     btnName:"Go to show page",
  //     btnLink:"/magazine",
  //     btnColor:"#FF9843",
  //     btnTextColor:"white"
  // }
]

const Video = () => {
  const location = useLocation();

  useEffect(() => {
    // Parse the query parameters
    const queryParams = new URLSearchParams(location.search);
    const scrollTo = queryParams.get('scrollTo');
    // If scrollTo parameter is present, scroll to the target element
    if (scrollTo) {
      scroller.scrollTo(scrollTo, {
        duration: 500,
        smooth: true,
        offset: -200,
      });
    }
  }, [location]);

  return (
    <>
    <ScrollToTop/>
     <Herobanner imageData={Data}/>
     {/* <Advertisements/> */}
     <LatestVideo id="Video_Latest"/>
     {/* <Shows id="Video_Shows"/> */}
     
     {/* <BehindtheScenes id="Video_Behindthescenes"/> */}
     <Videobanner id="Video_Videobanner"/>
    </>
  );
};

export default Video;
