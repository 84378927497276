import "../../style/Advertisement/index.scss";
import Aboutbanner from "../../components/Aboutbanner/Aboutbanner";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import React, { useState } from "react";
import { useAtom } from "jotai";
import { DarkModeState } from "../../Jotai/index";
import WebPrintRates from "../../components/WebPrintRates/WebPrintRates";
import RadioRates from "../../components/RadioRates/RadioRates";
import CampusDemographics from "../../components/CampusDemographics/CampusDemographics";

const Advertisement = () => {
    const Data = [
        {
            title: "Advertise with Us",
            imageLink: "image/About/AboutPageBanner.jpg",
            text: "The LBSU campus is vast, home to over 40,000 students, faculty, and staff. With no outside publications allowed to distribute on-campus, 22 West Media offers a unique opportunity to reach tens of thousands of potential customers within the crucial 18-24 college-age demographic. By advertising with 22 West Media, you directly support the students in media at Long Beach State University.",
            text2:"",
        },
    ];

    const [darkMode, setDarkMode] = useAtom(DarkModeState);
    const [activeRatesTab, setActiveRatesTab] = useState("webPrintRates");

    const handleRatesClick = (tab) => {
        setActiveRatesTab(tab);
    };



    return (
        <>
            <ScrollToTop />

            <div className="Advertisement">
                <Aboutbanner imageData={Data} />



                <div className="AdvertisingRates">
                    <div className="advertising-rates">
                        <div className="ratesTab">
                            <button
                                className={activeRatesTab === "webPrintRates" ? "active" : ""}
                                onClick={() => handleRatesClick("webPrintRates")}
                            >
                                WEB & PRINT ADVERTISING RATES
                            </button>
                            <button
                                className={activeRatesTab === "radioRates" ? "active" : ""}
                                onClick={() => handleRatesClick("radioRates")}
                            >
                                RADIO ADVERTISING RATES
                            </button>
                            <button
                                className={activeRatesTab === "campusDemographics" ? "active" : ""}
                                onClick={() => handleRatesClick("campusDemographics")}
                            >
                                CAMPUS DEMOGRAPHICS
                            </button>
                        </div>
                        <div className="tab-content">
                            {activeRatesTab === "webPrintRates" && <WebPrintRates />}
                            {activeRatesTab === "radioRates" && <RadioRates />}
                            {activeRatesTab === "campusDemographics" && <CampusDemographics />}
                        </div>
                    </div>
                </div >

            </div>
        </>
    );
};

export default Advertisement;
