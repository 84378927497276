import React from "react";
import "../../style/History/index.scss";
import Navbar from "../../components/navbar/Navbar";
import Aboutbanner from "../../components/Aboutbanner/Aboutbanner";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { useState } from "react";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { useAtom } from "jotai";
import { DarkModeState, eventsAtom } from "../../Jotai/index";
import { NavLink } from "react-router-dom";
import Kbeach from "../../components/HistorySections/KBeach.jsx";
// import UnionWeekly from "../../components/HistorySections/UnionWeekly";
// import CollegeBeat from "../../components/HistorySections/CollegeBeat";

const History = () => {
  const Data = [
    {
      title: "History",
      imageLink: "image/staff/22West-group-1.png",
      text: "Before converging into a single website in November 2017, 22westmedia.com was known as three distinct media entities at California State University, Long Beach: KBeach Radio, College Beat Television & Productions, and the Long Beach Union Weekly newspaper. We are proud of our heritage and strong connection to the campus, and we honor our media alumni with the following history of our company:",
      text2: ""
      // "Any student with an interest in media is welcome to join the team. We accept any level of experience. We're located at the bottom floor of the USU, so stop by and say hi!",
    },
  ];
  const buttonData={
    link:"/about",
    buttonText:"Back to About Us"
  };
  return (
    <>
      <ScrollToTop />

      <div className="About">
        <Aboutbanner imageData={Data} buttonData={buttonData}/>
        <div className="HistoryPage">
          <div className="HistoryHeader">
          </div>
          <div className="Historybody">
            <div className="BodyHeader">
              <div className="Kbeach">
                <img src="https://asicsulb.org/22westmedia/images/about/kbeach-logo.png" alt="KBeach Logo" />
              </div>
              <div className="noBGlogo">
                <img src="https://asicsulb.org/22westmedia/images/about/cb-logo.png" alt="College Beat Logo" />
              </div>
              <div className="noBGlogo">
                <img src="https://asicsulb.org/22westmedia/images/about/uw-logo.png" alt="Union Weekly Logo" />
              </div>
            </div>
            <div className="HistoryContent">
              <Kbeach />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default History;
