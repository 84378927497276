import React from "react";
import "../../style/About/index.scss";
import Aboutbanner from "../../components/Aboutbanner/Aboutbanner";
import AboutStaff from "../../components/AboutStaff/AboutStaff";
import { useState } from "react";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

const DevTeam = () => {
  const Data = [
    {
      title: "About Us",
      imageLink: "image/techteam/techteam.jpg",
      text: "The 22 West Media website, developed by Cal State University computer science graduate students, showcases cutting-edge web technologies. The team, led by Julian Miller, includes Kenil Prakashbhai Vaghasiya, Kunjal Ashvinbhai Patel, Rushabh Shah, and Satyam Sharma. Huy Tran, with Danny Lemos' guidance, designed the site, supported by 22 West Media's student assistants. This project aims to provide a rich, engaging experience and highlight the talents of 22 West Media's student-run team.",
    },
  ];

  const staffData = [
    {
      gif: "image/techteam/julianmiller.png",
      img: "image/techteam/julianmiller.png",
      name: "Julian Miller",
      status: "Web and Application Developer, Manager",
      outline: "",
      dribble: "",
      instagram: "",
      linkedin: "https://www.linkedin.com/in/julianemiller/",
      tiktok: "",
      soundcloud: "",
      substack: "",
      web: "",
      youtube: "",
      category: "Administrative",
    },
    {
      gif: "image/techteam/kenilvaghasiya.png",
      img: "image/techteam/kenilvaghasiya.png",
      name: "Kenil Vaghasiya",
      status: "Software Developer",
      outline: "",
      dribble: "https://dribbble.com/kenil_vaghasiya",
      instagram: "",
      linkedin: "https://www.linkedin.com/in/kenil704/",
      tiktok: "",
      soundcloud: "",
      substack: "",
      web: "https://www.kenilvaghasiya.com",
      youtube: "",
      category: "Administrative",
    },
    {
      gif: "image/techteam/kunjalpatel.png",
      img: "image/techteam/kunjalpatel.png",
      name: "Kunjal Patel",
      status: "Software Developer",
      outline: "",
      dribble: "",
      instagram: "",
      linkedin: "https://www.linkedin.com/in/kunjal-patel2001/",
      tiktok: "",
      soundcloud: "",
      substack: "",
      web: "",
      youtube: "",
      category: "Administrative",
    },

    {
        gif: "image/techteam/rushabhshah.png",
        img: "image/techteam/rushabhshah.png",
        name: "Rushabh Shah",
        status: "Software Developer",
        outline: "",
        dribble: "",
        instagram: "",
        linkedin: "https://www.linkedin.com/in/rushabhshah309/",
        tiktok: "",
        soundcloud: "",
        substack: "",
        web: "https://rushabhshah309.github.io/",
        youtube: "",
        category: "Administrative",
      },

      {
        gif: "image/techteam/satyamsharma.png",
        img: "image/techteam/satyamsharma.png",
        name: "Satyam Sharma",
        status: "Software Developer",
        outline: "",
        dribble: "",
        instagram: "",
        linkedin: "https://www.linkedin.com/in/satyam-sharma-26a08311a/",
        tiktok: "",
        soundcloud: "",
        substack: "",
        web: "",
        youtube: "",
        category: "Administrative",
      },
    
    
  

  ];

  const [staffDataFil, setstaffDataFil] = useState(staffData);

  

  return (
    <>
    <ScrollToTop/>
      <div className="About Devteam">
        <Aboutbanner imageData={Data} />

        <div className="AboutStaffMenu">
          
        <div className="DevTeamtext">ASI Web Development Team</div>
          <div className="AboutStaffMenucard">
            {staffDataFil.map((data, index) => {
              return <AboutStaff staffData={data} />;
            })}
          </div>
        </div>


 


      </div>
    </>
  );
};

export default DevTeam;
