import { useAtom } from "jotai";
import { DarkModeState } from "../../Jotai";
import "../../style/RecentArticle/index.scss";

const RecentArticle = (props) => {
    const [darkMode, setDarkMode] = useAtom(DarkModeState);
    return (
        <>
            <div className="RecentArticle">
                <div className={darkMode ? "recentArticleMain darkmodeRecentArticle" : "recentArticleMain lightmodeRecentArticle"}>
                    <div className="ImageSection">
                        <img
                            className="RecentArticleImage"
                            src={props.data.magazine_img}
                            alt="Image"
                        />
                    </div>

                    <div className="RecentArticleTopic">
                        <div className="RecentArticleType">
                            {props.data.magazine_type}
                        </div>
                        <div className="RecentArticleTitle">
                        {props.data.magazine_name}

                        </div>
                        <div className="RecentArticleDate">
                        </div>
                        <div className="RecentArticleAuthor">
                        {props.data.magazine_writer}

                        </div>
                        <div className="RecentArticleText">
                        {props.data.magazine_data()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RecentArticle;