import React, { useEffect, useState } from "react";
import "../../style/Advertisement/index.scss";
import { useAtom } from "jotai";
import { DarkModeState } from "../../Jotai/index";


const AdvertiseAgreementAccordian = () => {
    const [accordionOpen, setAccordionOpen] = useState(false);

    return (
        <>
            <div className="agreementAccordion">
                <div className="agreementAccordionItem">
                    <button
                        className="agreementAccordionTitle"
                        onClick={() => setAccordionOpen(!accordionOpen)}
                        aria-expanded={accordionOpen}
                        aria-controls="accordion-content"
                    >
                        The Fine Print - 2024 Advertising Sales Update
                        <span className="agreementAccordionSymbol">{accordionOpen ? "-" : "+"}</span>
                    </button>
                    <div
                        className={`agreementAccordionContent ${accordionOpen ? "open" : ""}`}
                        id="accordion-content"
                        role="region"
                        aria-labelledby="accordion-title"
                    >
                        <p>
                            A. Publisher (Associated Students, Incorporated) reserves the right to revise advertising rates and policies at any time. Contracted advertisers receive 60- day protection from all rate changes.
                        </p>
                        <p>
                            B. Publisher always reserves the right to refuse any advertisement. If an advertisement is refused, prepayment for that ad, if any, will be refunded or credited toward future ad sales. Acceptance by representative does not constitute acceptance by publisher.
                        </p>
                        <p>
                            C. Advertiser must inform sales of run dates in advance. Advertiser assumes responsibility for all unused advertising if run dates are not provided.
                        </p>
                        <p>
                            D. Advertiser will be charged for any advertisement cancelled after 5PM, Friday, the week prior to publication.
                        </p>
                        <p>
                            E. Payments accepted by check only and must be made payable to Associated Students, Inc. Include #521-3567-00 in the memo line. A TAX ID# or SSN# is required for billing purposes.
                        </p>
                        <p>
                            F. Remedies for errors occurring in an advertisement due to mistakes on the part of 22 West are limited to one (1) correction and reinsertion of adjusted advertisement.
                        </p>
                        <p>
                            G. 22 West will accept ads via USB thumbnail drive, Dropbox, Google Drive or email to advertisingmanager@22Westmedia.com. Be sure to include all pictures, text, and fonts.  22 West is not responsible for any forgotten material. Fonts will be substituted in this case. Submission of 300 DPI images in TIFF or PDF format results in the highest quality printed reproductions.
                        </p>
                        <p>
                            H. Sizes are guaranteed based on a proportion of the actual page size. Please be sure to use correct measurements for your ad size. Ad sizes are available at www.22westmedia.com/advertising. 22 West Media will reduce any ad improperly sized. 22 West will not be held responsible for lost material resulting from size reduction. Artwork smaller than ad size will be assumed as intentional.
                        </p>
                        <p>
                            I. Consideration is given to position requests, but failure to meet such requests does not constitute cause for adjustment, refund, or return.
                        </p>
                        <p>
                            J. All accounts over one run must pay for the first week in full prior to publication or have an approved credit account with the Associated Students, Incorporated business office.
                        </p>
                        <p>
                            K. In the event of non-payment or untimely payment on behalf of the advertiser, full payment is due immediately upon request of the Associated Students, Inc.
                        </p>
                        <p>
                            L. Multiple-week contracts must be paid in advance or advertisers must submit a credit application to the Associated Students Business Office for approval. Credit applications can be obtained by emailing advertisingmanager@22Westmedia.com
                        </p>
                        <p>
                            M. Failure to pay per invoice terms net 30 days will result in a 2% finance charge automatically applied.
                        </p>
                        <p>
                            N. Advertiser agrees to pay all costs of collection (including attorney and court fees) in the event of failure to pay in accordance with payment schedule specified herein. Disputes under this Advertising Agreement shall be subject to the exclusive jurisdiction of the courts of California.
                        </p>
                        <p>
                            O. The Advertiser warrants they will not use any false or misleading statements in their advertisements whether by statement, act, omission or implication. The Advertiser warrants that he/she will not use any vulgar, offensive, or disreputable means of advertising.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdvertiseAgreementAccordian;