import React from "react";
import "../../style/About/index.scss";
import Navbar from "../../components/navbar/Navbar";
import Aboutbanner from "../../components/Aboutbanner/Aboutbanner";
import AboutStaff from "../../components/AboutStaff/AboutStaff";
import { useState } from "react";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { useAtom } from "jotai";
import { DarkModeState,eventsAtom } from "../../Jotai/index";
import { NavLink } from "react-router-dom";

const About = () => {
  const Data = [
    {
      title: "About Us",
      imageLink: "image/Advertisements/22w-team-retreat-fa24.jpg",
      text: "22 West is a student-run, student-driven digital media center at California State University, Long Beach. We encompass a production company, radio station, and magazine, providing a space for students to express themselves and gain hands-on experience in media production. Any student with an interest in media is welcome to join the team, regardless of experience level. Visit us on the bottom floor of the USU to say hi!",
    },
  ];

 const staffData = [
  // ADMINISTRATIVE
  {
    gif: "",
    img: "image/staff/Dec24/Jeffrey Whitten.jpg",
    name: "Jeffrey Whitten",
    status: "Assistant Director, Student Media",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Administrative",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Livana Oweis.jpg",
    name: "Livana Oweis",
    status: "Graduate Assistant",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Administrative",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Vishwanath Bhat Padukudru.jpg",
    name: "Vishwanath Bhat Padukudru",
    status: "Graduate Assistant",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Administrative",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Chloe Clemmons.jpg",
    name: "Chloe Clemmons",
    status: "Office Assistant",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Administrative",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Andres Avila.jpg",
    name: "Andres Avila",
    status: "Office Assistant",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Administrative",
  },
    {
      gif: "",
      img: "image/staff/Dec24/Taylor Bueller Scott.jpg",
      name: "Taylor Buhler-Scott",
      status: "Assoc. Dir. of Programs & Comm",
      outline: "",
      dribble: "",
      instagram: "",
      linkedin: "",
      tiktok: "",
      soundcloud: "",
      substack: "",
      web: "",
      youtube: "",
      category: "Administrative",
    },

  // MARKETING
  {
    gif: "",
    img: "image/staff/Dec24/Katrina Nunez.jpg",
    name: "Katrina Nunez",
    status: "Marketing Assistant",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Marketing",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Jadyen Arana.jpg",
    name: "Jadyen Arana",
    status: "Social Media Assistant",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Marketing",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Phyke Soriano.jpg",
    name: "Phyke Soriano",
    status: "Student Graphic Artist/Animator",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Marketing",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Arleth Rivera.jpg",
    name: "Arleth Rivera",
    status: "Student Graphic Artist/Animator",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Marketing",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Dylan Ong.jpg",
    name: "Dylan Ong",
    status: "Student Staff Photographer",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Marketing",
  },

  // RADIO
  {
    gif: "",
    img: "image/staff/Dec24/Morgan Barela.jpg",
    name: "Morgan Barela",
    status: "Student General Manager",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Radio",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Sesha Srungarum.jpg",
    name: "Sesha Srungaram",
    status: "Student Station Engineer",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Radio",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Havni Rami.jpg",
    name: "Havni Rami",
    status: "Music Assistant",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Radio",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Samantha Cortes.jpg",
    name: "Samantha Cortes",
    status: "News & Public Affairs Assistant",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Radio",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Ella Peng.jpg",
    name: "Ella Peng",
    status: "Production and Imaging Assistant",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Radio",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Zachery Durham.jpg",
    name: "Zachery Durham",
    status: "Student Play by Play Announcer",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Radio",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Bruce Kuntz.jpg",
    name: "Bruce Kuntz",
    status: "Student Play by Play Announcer",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Radio",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Kaylee Gallagher.jpg",
    name: "Kaylee Gallagher",
    status: "Student Board Operator",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Radio",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Davyn Knight.jpg",
    name: "Davyn (Zoie) Knight",
    status: "Student Performance DJ",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Radio",
  },

  // MAGAZINE
  {
    gif: "",
    img: "image/staff/Dec24/Jensen Puckett.jpg",
    name: "Jensen Puckett",
    status: "Student Editor In Chief",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Magazine",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Gia Krupens.jpg",
    name: "Gia Krupens",
    status: "Student Managing Editor",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Magazine",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Caroline Bae.jpg",
    name: "Caroline Bae",
    status: "Student Art Director",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Magazine",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Tulasi Napolitani.jpg",
    name: "Tulasi Napolitani",
    status: "Distribution Assistant",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Magazine",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Alana Loinaz.jpg",
    name: "Alana Loinaz",
    status: "Student Copy Editor",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Magazine",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Payton Smith.jpg",
    name: "Payton Smith",
    status: "Advertising Assistant",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Magazine",
  },

  // TELEVISION
  {
    gif: "",
    img: "image/staff/Dec24/Ana Espinoza-Cruz.jpg",
    name: "Ana Espinoza-Cruz",
    status: "Student Production Coordinator",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Television",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Brent Cuenca.jpg",
    name: "Brent Cuenca",
    status: "Student Executive Producer",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Television",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Joshua Baldwin.jpg",
    name: "Joshua Baldwin",
    status: "Student Executive Producer",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Television",
  },
  {
    gif: "",
    img: "image/staff/Dec24/German Skinder.jpg",
    name: "German Skinder",
    status: "Student Video Producer",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Television",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Isabella Mojica.jpg",
    name: "Isabella Mojica",
    status: "Student Video Editor",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Television",
  },
  {
    gif: "",
    img: "image/staff/Dec24/Gabe Thomas.jpg",
    name: "Gabriel Thomas",
    status: "Student Video Editor",
    outline: "",
    dribble: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    soundcloud: "",
    substack: "",
    web: "",
    youtube: "",
    category: "Television",
  },
];

  const buttonData={
    link:"/history",
    buttonText:"History of 22West Media"
  };

  const [staffDataFil, setstaffDataFil] = useState(staffData);
  const [filcategory, setfilcategory] = useState("All");
  const [darkMode] = useAtom(DarkModeState);

  const selectcategory = (name) => {
    setfilcategory(name);
    setstaffDataFil((data) => {
      return staffData.filter((data, index) => {
        if (name === data.category) {
          return true;
        }
        if (name === "All") {
          return true;
        }
      });
    });
  };

  return (
    <>
      <ScrollToTop />

      <div className="About">
        <Aboutbanner imageData={Data} buttonData={buttonData}/>

        <div className="AboutStaffMenu">
          <div className="AboutStaffMenuNav">
            <div
              onClick={() => {
                selectcategory("All");
              }}
              className={
                filcategory === "All"
                  ? "AboutStaffMenuitem active"
                  : "AboutStaffMenuitem"
              }
            >
              All
            </div>
            <div
              onClick={() => {
                selectcategory("Administrative");
              }}
              className={
                filcategory === "Administrative"
                  ? "AboutStaffMenuitem active"
                  : "AboutStaffMenuitem"
              }
            >
              Administrative
            </div>
            <div
              onClick={() => {
                selectcategory("Magazine");
              }}
              className={
                filcategory === "Magazine"
                  ? "AboutStaffMenuitem active"
                  : "AboutStaffMenuitem"
              }
            >
              Magazine
            </div>
            <div
              onClick={() => {
                selectcategory("Radio");
              }}
              className={
                filcategory === "Radio"
                  ? "AboutStaffMenuitem active"
                  : "AboutStaffMenuitem"
              }
            >
              Radio
            </div>
            <div
              onClick={() => {
                selectcategory("Television");
              }}
              className={
                filcategory === "Video"
                  ? "AboutStaffMenuitem active"
                  : "AboutStaffMenuitem"
              }
            >
              Television
            </div>
            <div
              onClick={() => {
                selectcategory("Marketing");
              }}
              className={
                filcategory === "Marketing"
                  ? "AboutStaffMenuitem active"
                  : "AboutStaffMenuitem"
              }
            >
              Marketing
            </div>
          </div>

          <div className="AboutStaffMenucard">
            {staffDataFil.map((data, index) => {
              return <AboutStaff staffData={data} />;
            })}
          </div>
        </div>

        {/* <div className={darkMode ? "aboutTeamSection darkmode" : "aboutTeamSection"}>
          <div className={darkMode ? "aboutTeamSection_main darkmode" : "aboutTeamSection_main"}>
            <div className="aboutTeamSection_title">ASI Web Development Team</div>
            <div className="aboutTeamSectionData">
              <img src="/image/techteam/techteam.jpg" alt="techteam"></img>

              <div className="techteam_aboutText">
              <div className="textAboutTeamPage" >
              The 22 West Media website, developed by Cal State University computer science graduate students, showcases cutting-edge web technologies. The team, led by Julian Miller, includes Kenil Prakashbhai Vaghasiya, Kunjal Ashvinbhai Patel, Rushabh Shah, and Satyam Sharma. Huy Tran, with Danny Lemos' guidance, designed the site, supported by 22 West Media's student assistants. This project aims to provide a rich, engaging experience and highlight the talents of 22 West Media's student-run team.
              </div>

              <NavLink className="navLinkData" to={"/team"}>
              <div  className="techteam_aboutTextbtn">
              View
              </div>
              </NavLink>

              <div></div>

              </div>

            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default About;
