import { useAtom } from "jotai";
import React, { useState } from "react";
import { DarkModeState, MagazineData } from "../../Jotai";
import { NavLink, Link, Route } from "react-router-dom";
import "../../style/MagazineCards/index.scss";

const MagazineCards = ({magazine_id, magazine_img, magazine_name, magazine_titleData, magazine_type, magazine_writer}) => {
    const [darkMode, setDarkMode] = useAtom(DarkModeState);

    return (
        <>

            <NavLink to={"/magazineSearch/" + magazine_id} className="navLinkDesign">
                <div className={darkMode ? "MagazineArticlesCards" : "MagazineArticlesCards MagazineArticlesCardslightmode"}>
                    <img className="MagazineArticlesCardsimage" src={magazine_img} alt="img" />
                    <div className="MagazineArticlesCardstype">
                        {magazine_type}
                    </div>
                    <div className="MagazineArticlesCardstopic">
                        {magazine_name}
                    </div>
                    <div className="MagazineArticlesCardsAuthor">
                        {magazine_writer}
                    </div>
                    <div className="MagazineArticlesCardsText">
                        {magazine_titleData}
                    </div>
                </div>
            </NavLink>
        </>
    );
}

export default MagazineCards;