import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import GenderPieChart from "../../components/GenderPieChart/GenderPieChart";


const CampusDemographics = () => {
    return (
        <>
            <div>
                <div className="ratesTabTitle">Population Total: 40,124</div>
                {/* Your HTML content for Campus Demographics tab */}
                <div>AM+M 2008 COLLEGE NEWSPAPER AUDIENCE SURVEY</div>



                <div className="PieChart">
                    <div>
                        <GenderPieChart
                            data={[
                                { id: 0, value: 57.9, label: 'Female      ' },
                                { id: 1, value: 42.1, label: 'Male' },
                            ]}
                            colors={['#4cc8f4', '#2b76a8']}
                        />
                        <GenderPieChart
                            data={[
                                { id: 0, value: 36859, label: 'Under-\ngraduates' },
                                { id: 1, value: 3265, label: 'Graduates' },
                                { id: 2, value: 4851, label: 'Faculty/\nStaff' },
                                { id: 3, value: 3168, label: 'On Campus\n Residents' },
                            ]}
                            colors={['#4cc8f4', '#2b76a8', '#b9ddea', '#003f6a']}
                        />
                    </div>
                </div>

                <hr className="hr-rule" />

                <div className="populationData">
                    <div>
                        <h1 className="title">Age:</h1>
                        <h2>Average Undergraduate age:</h2>
                        <h3 className="title">Female: 21.62</h3>
                        <h3 className="title">Male: 22.04</h3>
                        <h2>Average Graduate age:</h2>
                        <h3 className="title">Female: 29.17</h3>
                        <h3 className="title">Male: 28.31</h3>
                    </div>
                    <div>
                        <h1 className="title">Population</h1>
                        <h3 className="title">Undergraduates: <span>36,859</span></h3>
                        <h3 className="title">Graduates: <span>3,265</span></h3>
                        <h3 className="title">Faculty/Staff: <span>2,446 Faculty – 2,405 Staff</span></h3>
                        <h3 className="title">On Campus Residents: <span>3,168</span></h3>
                    </div>
                </div>

                <hr className="hr-rule" />

                <div className="ratesTabTitle">About Our Readers</div>
                <div className="aboutReaders">
                    <ul>
                        <li>Monthly discretionary spending $566 per student.</li>
                        <li>76% of all students have read the newspaper in the last 7 days</li>
                        <li>Students who read the entire newspaper spend 14.1 minutes reading on average</li>
                        <li>Nearly 75% of all students read the advertisements</li>
                        
                        <li>78% of all students use social media</li>
                        <li>90% of all students have a smartphone</li>
                        <li>89% of all students use Digital devices for news</li>
                        <li>24% of all students use Print Publications</li>
                        <li>37% of all students use Radio</li>
                    </ul>
                </div>

            </div>
        </>
    );
}

export default CampusDemographics;