import React, { useState, useEffect } from 'react';
import { Scheduler } from "@aldabil/react-scheduler";
import "../../style/ScheduleCalendar/index.scss";
import { useAtom } from "jotai";
import { DarkModeState,eventsAtom } from "../../Jotai/index";
import EventsFetcher from '../EventsFetcher/EventsFetcher';


const ScheduleCalendar = ({id}) => {
  const [events, setEvents] = useAtom(eventsAtom);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [darkMode] = useAtom(DarkModeState);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div id={id} className="ScheduleCalendar-container">
      {events.length==0?<EventsFetcher/>:null}
      <div className={darkMode ? "ScheduleCalendarCSS darkmode" : "ScheduleCalendarCSS"}>
        <div className={darkMode ? "ScheduleCalendar-title" : "ScheduleCalendar-title whitemode"}>Radio Schedule</div>
        <div className="ScheduleCalendarCSSMain">
          <Scheduler
            view={isMobile ? 'day' : 'week'}
            events={events}
            month={{ startHour: 1, endHour: 23 }}
            day={{  weekDays: [0, 1, 2, 3, 4, 5,6], weekStartOn: 0,startHour: 0, endHour: 24 }}
            week={{ weekDays: [0, 1, 2, 3, 4, 5,6], weekStartOn: 0,startHour: 0, endHour: 24 }}
            fields={{
                name: "description",
                // type: "input",
                default: "Show Details",
                config: { label: "description", multiline: true, rows: 4 }
              }}
           
            draggable={false}
            deletable={false}
            editable={false}
          />
        </div>
      </div>
    </div>
  );
}

export default ScheduleCalendar;
