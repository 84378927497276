import React, { useState, useRef, useEffect } from "react";
import "../../style/Footer/Footer.scss";
import { Link} from "react-router-dom";
import { Link as RouterLink } from 'react-router-dom';

function Footer() {


    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section">
                    <h3 className="footer-heading"><Link to="/radio" className="LinkScss">Radio</Link></h3>
                    <ul className="footer-links">
                        <li>
                            <RouterLink to="/radio?scrollTo=Radio_Schedule">
                                Schedule
                            </RouterLink>
                        </li>
                        {/* Links will be added later */}
                        {/* <li>
                            <RouterLink to="/radio?scrollTo=Radio_Shows">
                                Shows
                            </RouterLink>
                        </li>
                        <li>
                            <RouterLink to="/radio?scrollTo=Radio_Sports">
                                Sports
                            </RouterLink>
                        </li>
                        <li>
                            <RouterLink to="/radio?scrollTo=Radio_Sports">
                            Livestream
                            </RouterLink>
                        </li> */}
                    </ul>
                </div>
                <div className="footer-section">
                    <h3 className="footer-heading"><Link to="/Television" className="LinkScss">Television</Link></h3>
                    <ul className="footer-links">
                    {/* <li>
                            <RouterLink to="/Television?scrollTo=Video_Shows">
                            Shows
                            </RouterLink>
                        </li> */}
                        <li>
                            <RouterLink to="/Television?scrollTo=Video_Latest">
                            Latest Programs
                            </RouterLink>
                        </li>
                        {/* <li>
                            <RouterLink to="/Television?scrollTo=Video_Videobanner">
                            Archives
                            </RouterLink>
                        </li> */}
                        {/* <li>
                            <RouterLink to="/Television?scrollTo=Video_Behindthescenes">
                            Behind the Scenes
                            </RouterLink>
                        </li> */}
                    </ul>
                </div>
                <div className="footer-section">
                    <h3 className="footer-heading"><Link to="/magazine" className="LinkScss">Magazine</Link></h3>
                    <ul className="footer-links">
                    {/* <li>
                            <RouterLink to="/magazine?scrollTo=Magazines">
                            New Issue
                            </RouterLink>
                        </li> */}
                        <li>
                            <RouterLink to="https://issuu.com/22westmagazine">
                            Latest Issues
                            </RouterLink>
                        </li>
                        {/* <li>
                            <RouterLink to="/magazine?scrollTo=InfoCard">
                            Archived Issues
                            </RouterLink>
                        </li> */}
                        <li>
                            <RouterLink to="/magazineSearch">
                            Online Articles
                            </RouterLink>
                        </li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h3 className="footer-heading"><Link to="/about" className="LinkScss">About</Link></h3>
                    <ul className="footer-links">
                        <li><Link to="/about">22West Team</Link></li>
                        <li><Link to="https://www.asicsulb.org/corporate/resources/about-us">Associated Students, inc.</Link></li>
                        <li><Link to="/web-team">ASI Web Team</Link></li>
                        <li><Link to="/history">History</Link></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h3 className="footer-heading"><Link to="/advertise" className="LinkScss">Advertisements</Link></h3>
                    <ul className="footer-links">
                        <li><Link to="https://drive.google.com/file/d/1wddAnuDOXZDFgHJhmoXlWPgni5ItgN7x/view?usp=sharing">Advertising Kit</Link></li>
                        <li><Link to="https://drive.google.com/file/d/11cXcVG9etP-12cTp436obLnZV828WTU5/view?usp=sharing">Fillable Ad Agreement</Link></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h3 className="footer-heading">Resources</h3>
                    <ul className="footer-links">
                        <li><Link to="https://csulb.qualtrics.com/jfe/form/SV_cBfXNQ8COQGqA7k">Work Order Form</Link></li>
                        <li><Link to="https://docs.google.com/forms/d/e/1FAIpQLSdujcEYK_RapLtLxP8-dzGz6cURhcFAuZ6VRa_BG3QaqPcE3A/viewform">Grievance Form</Link></li>
                        <li><Link to="https://www.asicsulb.org/corporate/work">Apply for Volunteer/Staff</Link></li>
                    </ul>
                </div>
            </div>
            {/* <div className="footer-info">
                <p>A student-run multimedia organization at the heart of Long Beach State</p>
            </div> */}
            <div className="footer-bottom footer-content">
                <div className="footer-logo">
                    {/* Insert your logo image here */}
                    <Link to={"/"}>

                    <img src="/image/logo/22WestLogo.svg" alt="22 West Media" />
                    </Link>
                    <div className="footer-socialMediaBtns">
                        <Link to={"https://www.instagram.com/22WestLB/"} target="_blank">
                            <img
                                src="/image/logo/Insta.svg"
                                className="iconSocialMedia"
                                alt="insta"
                            />
                        </Link>
                        <Link to={"https://www.tiktok.com/@22westlb"} target="_blank">
                            <img
                                src="/image/logo/tiktok.svg"
                                alt="tiktok"
                                className="iconSocialMedia"
                            />
                        </Link>
                        <Link to={"https://www.youtube.com/@22WestVideo"} target="_blank">
                            <img
                                src="/image/logo/youtube.svg"
                                alt="youtube"
                                className="iconSocialMedia"
                            />
                        </Link>
                    </div>
                </div>
                <div className="footer-info">
                    <p>A student-run digital media center at the heart of California State University, Long Beach, 22 West Media provides students with a living, learning classroom that offers the opportunity to work and learn about the broadcast and print media industries in a professional, hands-on environment.</p>
                </div>
                <div className="footer-info">
                    <p>1212 N Bellflower Blvd USU Suite 108 & 110<br />Long Beach, CA 90815</p>
                    <p>Office: (562) 985-7021 <br />Station: (562) 985-1624</p>
                    
                </div>
                {/* <div className="footer-policy">
                    <p>Privacy Policy</p>
                    <p>Terms of Service</p>
                    <p>Site Credits</p>
                </div> */}
                <div className="footer-credits">
                    <div className="ASIimage">
                    <Link to="https://www.asicsulb.org/corporate" bis_size={"none"} target="_blank">
                        <img src="image/logo/ASI+LBSUlogo_wide_RGB-WHT.png" alt="ASI LOGO" bis_size="none" style={{}} />
                    </Link>
                    </div>
                    <p>Made possible by Associated Students, Incorporated</p>
                </div>
            </div>

        </footer>
    );
};

export default Footer;
