import '../../style/MusicPlayerTooltip/index.scss'
const MusicPlayerTooltip = ({ currentSong, isVisible }) => {
  
    return (
    <div className={`tooltip-container ${isVisible ? 'show' : ''}`}>
        {currentSong.albumArt ? (
          <img src={currentSong.albumArt} alt="Album Art" className="tooltip-album-art" />
        ) : (
          <img src="/image/DefaultAlbumArt.png" alt="Default Album Art" className="tooltip-album-art" />
        )}
        <div className="tooltip-text">
          <p className="tooltip-title">{currentSong.title}</p>
          <p className="tooltip-artist">{currentSong.artist}</p>
        </div>
      </div>
    );
  };
export default MusicPlayerTooltip;