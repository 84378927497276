import React, { useState } from "react";
import { useAtom } from "jotai";
import { DarkModeState } from "../../Jotai/index";
import "../../style/MagazineTypes/index.scss";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { MagazineData } from "../../Jotai/index";
import MagazineCards from "../MagazineCards/MagazineCards";
import "../../style/MagazineCards/index.scss"
import RecentArticle from "../RecentArticle/RecentArticle";
import { NavLink } from "react-router-dom";

// This component represents different types of magazines like "Online Articles, Arts, Cultures, Opinion" etcon magazines page. It has the tab switch functionality and can switch between any of these types.
const MagazineTypes = ({id}) => {

    const [magazineType, setMagazineType] = useState("all");
    const [darkMode, setDarkMode] = useAtom(DarkModeState);
    const [magazinedata, setMagazineData] = useAtom(MagazineData);
    const [MagazinedataFil, setMagazinedataFil] = useState(magazinedata);


    const [showMagazines, setShowMagazines] = useState(6);

    // when the type of magazine is changed i.e. tab is switched, we will call "setMagazineType" to change type, "setShowMagazines" to reset and again make it 6 and "setMagazineCounter" to reset the counter variable to 0.
    const SelectMagazineType = (name) => {
        setMagazineType(name);
        setMagazinedataFil(magazinedata.filter((data)=>{
            if(name===data.magazine_type){
                return true
            }
            if(name==="all"){
                return true
            }
        }))

        setShowMagazines(6);
        setMagazineCounter(0);
    };

    // below variable holds count of magazines/articles on screen. If it is 6, we will add 3 more and so on. We will reset it and make it 0 when the tab is switched(type of magazine is changed).
    let magazineCounter = 0;
    const setMagazineCounter = (value) => {
        magazineCounter = value;
    };

    return (
        <>
            <div id={id} className="MagazineTypes">
                <ScrollToTop />

                <div className="btnSearchField">
                    <div onClick={() => { SelectMagazineType("all"); }} className={
                        magazineType === "all"
                            ? "btnSearchField_tag btnSearchField_tag_active"
                            : "btnSearchField_tag"
                    }>
                        All
                    </div>

                    <div onClick={() => { SelectMagazineType("online articles"); }} className={
                        magazineType === "online articles"
                            ? "btnSearchField_tag btnSearchField_tag_active"
                            : "btnSearchField_tag"
                    }>
                        Online Articles
                    </div>

                    <div onClick={() => { SelectMagazineType("arts"); }} className={
                        magazineType === "arts"
                            ? "btnSearchField_tag btnSearchField_tag_active"
                            : "btnSearchField_tag"
                    }>
                        Arts
                    </div>

                    <div onClick={() => { SelectMagazineType("culture"); }} className={
                        magazineType === "culture"
                            ? "btnSearchField_tag btnSearchField_tag_active"
                            : "btnSearchField_tag"
                    }>
                        Culture
                    </div>

                    <div onClick={() => { SelectMagazineType("opinion"); }} className={
                        magazineType === "opinion"
                            ? "btnSearchField_tag btnSearchField_tag_active"
                            : "btnSearchField_tag"
                    }>
                        Opinion
                    </div>

                    <div onClick={() => { SelectMagazineType("grunion"); }} className={
                        magazineType === "grunion"
                            ? "btnSearchField_tag btnSearchField_tag_active"
                            : "btnSearchField_tag"
                    }>
                        Grunion
                    </div>

                    <div onClick={() => { SelectMagazineType("studentlife"); }} className={
                        magazineType === "studentlife"
                            ? "btnSearchField_tag btnSearchField_tag_active"
                            : "btnSearchField_tag"
                    }>
                        Student Life
                    </div>
                </div>


                {/* Below is the Recent article component which represents recent article of selected type(arts, culture, grunion etc.) */}
                {
                    MagazinedataFil.slice(0,1).map((data,index) => {
                                            return (
                                                <NavLink to={"/magazineSearch/" + data.magazine_id} className="navLinkDesign">
                                                    <RecentArticle data={data}/>
                                                </NavLink>
                                            );
                                        }
                                )
                            }
                
                <div className="MagazineCards">
                    <div className={darkMode ? "MagazineCardsMain darkmode" : "MagazineCardsMain"}>
                        <div className="MagazinesCards">
                            {
                                MagazinedataFil.slice(1,showMagazines+1).map((data) => {
                                    magazineCounter++
                                            return (
                                                <MagazineCards magazine_id={data.magazine_id} magazine_img={data.magazine_img} magazine_name={data.magazine_name} magazine_titleData={data.magazine_titleData} magazine_type={data.magazine_type} magazine_writer={data.magazine_writer} />
                                            );
                                })
                            }
                        </div>

                        <div className="MagazineCardsShowBtn">
                            {
                                // button to load more articles/magazines. It will only appear when the counter is equal to or greater than showMagazines count.
                                (showMagazines > magazineCounter) ? <></> : <div className={darkMode ? "btnShowmore" : "btnShowmore lightmodeBtn"} onClick={() => setShowMagazines(showMagazines + 3)}>More articles...</div>
                            }
                        </div>

                    </div>
                </div>

            </div>
        </>
    );
}

export default MagazineTypes;