import React, { useState } from "react";
import "../../style/History/Kbeach.scss"; // Ensure the path to the SCSS file is correct

const KbeachAccordionItem = ({ year, children, image }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`accordion-item ${isOpen ? "open" : ""} ${
        image ? "image-accordion" : ""
      }`}
    >
      <div
        className={`accordion-title ${isOpen ? "open" : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="year">{year}</div>
        <div className="accordion-icon">{isOpen ? "-" : "+"}</div>
      </div>
      {isOpen && (
        <div className={`accordion-content ${isOpen ? "open" : ""}`}>
          {children}
        </div>
      )}
    </div>
  );
};

const KBeach = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };
  return (
    <div id="radio">
      <KbeachAccordionItem year="70s">
        <h2>1970</h2>
        <p>
          <b>KBeach: </b>KSUL, was the first student-run campus radio station.
          It provided a lab for radio students, a voice for the university and
          an outlet for new music.
        </p>
        <hr
          style={{
            backgroundColor: "lightgrey",
            border: "none",
            height: "1px",
          }}
        />
        <h2>1973</h2>
        <p>
          <b>Union Weekly: </b>First issue of the Union Press, the first attempt
          of publication that only lasted one issue.
        </p>
        <hr
          style={{
            backgroundColor: "lightgrey",
            border: "none",
            height: "1px",
          }}
        />
        <h2>1977</h2>
        <p>
          <b>Union Weekly: </b>First issue of the Long Beach Union Newspaper is
          published as a response of the student&apos;s increasing contempt for
          the school&apos;s newspaper, Daily 49er.
        </p>
        <hr
          style={{
            backgroundColor: "lightgrey",
            border: "none",
            height: "1px",
          }}
        />
        <h2>1978</h2>
        <p>
          <b>Union Weekly: </b>First publication of the LBUN&apos;s satirical
          page, the Grunion
        </p>
      </KbeachAccordionItem>
      <KbeachAccordionItem year="80s">
        <h2>1980</h2>
        <p>
          <b>KBeach: </b>Former president Steve Horn decided to acquire KLON
          from Long Beach City College, saying a new “professional” station
          would bring prestige to the campus, eliminating the need for KSUL.
        </p>
        <hr
          style={{
            backgroundColor: "lightgrey",
            border: "none",
            height: "1px",
          }}
        />

        <h2>1981</h2>
        <p>
          <b>KBeach: </b>Despite a hard fight by students, KSUL leaves the air.
        </p>
      </KbeachAccordionItem>
      <KbeachAccordionItem year="90s">
        <h2>1990</h2>
        <p>
          <b>KBeach: </b>KLON becomes the premier jazz station for Southern
          California and is assisted by student interns from the renamed Film
          and Electronic Arts Department, formerly the Radio/TV/Film Department.
          Radio classes are slowly phased out and become "audio" courses
          focusing on sound for TV and film.
        </p>
        <hr
          style={{
            backgroundColor: "lightgrey",
            border: "none",
            height: "1px",
          }}
        />

        <h2>1992</h2>
        <p>
          <b>KBeach: </b>KBCH begins to air as a cable broadcast radio station
          under the direction of Professor Pounds in the Film and Electronic
          Arts Department. As an offshoot of this, student Mike Soultanian
          starts broadcasting over the Internet from his dorm room. KBeach later
          becomes a recognized student organization under the Film and
          Electronic Arts Department.
        </p>
        <hr
          style={{
            backgroundColor: "lightgrey",
            border: "none",
            height: "1px",
          }}
        />

        <h2>1998</h2>
        <p>
          <b>KBeach: </b>January 26, 1998. Kbeach signs onto the internet from
          Cerritos Residential Hall on campus. The Associated Students, Inc.
          invests $40,000 into KBeach, which becomes a department within ASI and
          moves into the University Student Union. KBeach is in direct
          competition with KBCH.
        </p>
        <p>
          <b>Union Weekly: </b>
        </p>
        <img
          src="https://asicsulb.org/22westmedia/images/about/uw-old-office.jpg"
          alt="UW - Old Office"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/uw-old-office.jpg"
            )
          }
        />
        <hr
          style={{
            backgroundColor: "lightgrey",
            border: "none",
            height: "1px",
          }}
        />

        <h2>1999</h2>
        <p>
          <b>KBeach: </b>Kbeach Grand Opening 1999.
        </p>
        <img
          src="https://asicsulb.org/22westmedia/images/about/kbeach-opening.jpg"
          alt="kbeach - image1"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/kbeach-opening.jpg"
            )
          }
        />
        <img
          src="https://asicsulb.org/22westmedia/images/about/kbeach-opening-2.jpg"
          alt="kbeach - image2"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/kbeach-opening-2.jpg"
            )
          }
        />
        <p>
          <b>Union Weekly: </b>
        </p>
        <img
          src="https://asicsulb.org/22westmedia/images/about/uw-1999-kelly-winters.jpg"
          alt="UW - 1999 Kelly Winters"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/uw-1999-kelly-winters.jpg"
            )
          }
        />
      </KbeachAccordionItem>
      <KbeachAccordionItem year="2000s">
        <h2>2000</h2>
        <p>
          <b>College Beat: </b>College Beat&apos;s first show was taped.
        </p>
        <img
          src="https://asicsulb.org/22westmedia/images/about/uw-2000.jpg"
          alt="UW - 2000"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/uw-2000.jpg"
            )
          }
        />
        <img
          src="https://asicsulb.org/22westmedia/images/about/uw-2000-staff.jpg"
          alt="UW - 2000 - Staff"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/uw-2000-staff.jpg"
            )
          }
        />
        <img
          src="https://asicsulb.org/22westmedia/images/about/uw-2000-fred-sanchez.jpg"
          alt="UW - 2000 - Fred Sanchez"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/uw-2000-fred-sanchez.jpg"
            )
          }
        />
        <hr
          style={{
            backgroundColor: "lightgrey",
            border: "none",
            height: "1px",
          }}
        />
        {/* 2004 */}
        <h2>2004</h2>
        <p>
          <b>Union Weekly: </b>Long Beach Union Newspaper goes weekly.
        </p>
        <img
          src="https://asicsulb.org/22westmedia/images/about/uw-sex-at-the-beach.jpg"
          alt="UW - Sex at the beach"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/uw-sex-at-the-beach.jpg"
            )
          }
        />
        <img
          src="https://asicsulb.org/22westmedia/images/about/uw-staff.jpg"
          alt="UW - Staff"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/uw-staff.jpg"
            )
          }
        />
         <hr
          style={{
            backgroundColor: "lightgrey",
            border: "none",
            height: "1px",
          }}
        />

        <h2>2007</h2>

        <p>
          <b>College Beat: </b>College Beat TV produces a 3-D video tour of the
          newly proposed Student Recreation and Wellness center as part of the
          marketing campaign for a student referendum. The referendum was
          passed.
        </p>
        <img
          src="https://asicsulb.org/22westmedia/images/about/cb-staff-2006.jpg"
          alt="CB - Staff"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/cb-staff-2006.jpg"
            )
          }
        />
 <hr
          style={{
            backgroundColor: "lightgrey",
            border: "none",
            height: "1px",
          }}
        />
      </KbeachAccordionItem>
      <KbeachAccordionItem year="2010s">
        <h2>2011</h2>
        <p>
          <b>KBeach:</b> KBeach launches an iPhone and Android app for
          listeners, and the ASI Senate approves funding for the KBeach HD
          project.
        </p>
        <p>
          <b>Union Weekly:</b> Over 1,000 students sign a petition to remove the
          Union Weekly Editor-in-Chief and defund the publication following the
          printing of several controversial articles.
        </p>
        <p>
          <b>Union Weekly:</b> Despite the public controversy, the ASI Media
          Board votes to continue funding the publication.
        </p>
         <hr
          style={{
            backgroundColor: "lightgrey",
            border: "none",
            height: "1px",
          }}
        />
        <h2>2012</h2>
        <p>
          <b>KBeach: </b>Marie Cathcart produces "Golden Mike" award-winning
          "Daily 49er News" on Kbeach Radio.
        </p>
        <img
          src="https://asicsulb.org/22westmedia/images/about/kbeach-program.jpg"
          alt="kbeach - smorgasport"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/kbeach-program.jpg"
            )
          }
        />
        <p>
          <b>Union Weekly: </b>UW hosts its first annual “Lovely Jams,” an
          open-mic love song competition hosted at The Nugget Grill and Pub for
          3 years.
        </p>
        <p>
          <b>College Beat: </b>“Smorgasport” video recognized by ACUI
          (Association of College Unions International).
        </p>
        <img
          src="https://asicsulb.org/22westmedia/images/about/cb-yir-2012.jpg"
          alt="cb-yir-2012"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/cb-yir-2012.jpg"
            )
          }
        />
        <img
          src="https://asicsulb.org/22westmedia/images/about/cb-2012.jpg"
          alt="cb-2012"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/cb-2012.jpg"
            )
          }
        />
         <hr
          style={{
            backgroundColor: "lightgrey",
            border: "none",
            height: "1px",
          }}
        />
        <h2>2013</h2>
         <p>
          <b>KBeach: </b>KBeach FM-HD3 hits the air. ASI Vice President Jonathan
          Bolin &amp; ASI President Jon Haberstroh live on "The John and Jon
          Show" on Kbeach.
        </p>
        <img
          src="https://asicsulb.org/22westmedia/images/about/kbeach-hd.jpg"
          alt="kbeach - hd"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/kbeach-hd.jpg"
            )
          }
        />
        <img
          src="https://asicsulb.org/22westmedia/images/about/kbeach-dj.jpg"
          alt="kbeach - image2"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/kbeach-dj.jpg"
            )
          }
        />
        <img
          src="https://asicsulb.org/22westmedia/images/about/kbeach-2013-live-on-air.JPG"
          alt="kbeach-live on air"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/kbeach-2013-live-on-air.JPG"
            )
          }
        />
        <p>
          <b>College Beat: </b>“When Disaster Strikes” video wins 2nd Place at
          BEA (Broadcast Educators Association) awards.
        </p>
        <img
          src="https://asicsulb.org/22westmedia/images/about/cb-feb-2013.jpg"
          alt="cb - image2"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/cb-feb-2013.jpg"
            )
          }
        />
        <img
          src="https://asicsulb.org/22westmedia/images/about/cb-office.jpg"
          alt="cb-office"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/cb-office.jpg"
            )
          }
        />
        <img
          src="https://asicsulb.org/22westmedia/images/about/cb-logo-2013.jpg"
          alt="cb - logo - 2013"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/cb-logo-2013.jpg"
            )
          }
        />
         <hr
          style={{
            backgroundColor: "lightgrey",
            border: "none",
            height: "1px",
          }}
        />
        <h2>2015</h2>
         <p>
          <b>KBeach: </b>Award winning talent at Intercollegiate Broadcasters
          System "Golden Microphone Trophy" awards. Kbeach receives $5000
          donation from Kbeach "Beg, Borrow Or Steal The Blues".
        </p>
        <img
          src="https://asicsulb.org/22westmedia/images/about/kbeach-2015-award.jpg"
          alt="kbeach - 2015 award"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/kbeach-2015-award.jpg"
            )
          }
        />
        <img
          src="https://asicsulb.org/22westmedia/images/about/kbeach-2015-donation.jpg"
          alt="kbeach - 2015 donation"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/kbeach-2015-donation.jpg"
            )
          }
        />
         <hr
          style={{
            backgroundColor: "lightgrey",
            border: "none",
            height: "1px",
          }}
        />
        <h2>2016</h2>
         <p>
          <b>KBeach: </b>RTNA Golden Mic winners Christian Wiseman and Arnetta
          "Miz" Lowe. "Best Public Affairs Program - 'Behind The Badge". Kbeach
          live broadcast at "Beach Streets" @ Bixby Park in Long Beach.
        </p>
        <img
          src="https://asicsulb.org/22westmedia/images/about/kbeach-2016-golden-mic.jpg"
          alt="kbeach - 2016 Golden Mic"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/kbeach-2016-golden-mic.jpg"
            )
          }
        />
        <img
          src="https://asicsulb.org/22westmedia/images/about/kbeach-2016-management.jpg"
          alt="kbeach - 2016 management"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/kbeach-2016-management.jpg"
            )
          }
        />
        <img
          src="https://asicsulb.org/22westmedia/images/about/kbeach-2016-beach-streets.jpg"
          alt="kbeach - 2016 beach streets"
          onClick={() =>
            openModal(
              "https://asicsulb.org/22westmedia/images/about/kbeach-2016-beach-streets.jpg"
            )
          }
        />
        <p>
          <b>Union Weekly: </b>Union Weekly Podcast is founded.
        </p>
        <p>
          <b>College Beat: </b>College Beat TV adds independent film production
          to its roster as “Studio 112” projects.
        </p>

         <hr
          style={{
            backgroundColor: "lightgrey",
            border: "none",
            height: "1px",
          }}
        />
        <h2>2017</h2>
        
            <p>
            <b>Union Weekly: </b>40th Anniversary of the UW.
          </p>
          <img
            src="https://asicsulb.org/22westmedia/images/about/uw-40th.jpg"
            alt="UW- 40th anniversary"
            onClick={() =>
              openModal(
                "https://asicsulb.org/22westmedia/images/about/uw-40th.jpg"
              )
            }
          />
         <p><b>College Beat: </b>College Beat TV becomes “22 West Video” and adds a website www.22westmedia.com for the first time in its history.</p>
         <hr
          style={{
            backgroundColor: "lightgrey",
            border: "none",
            height: "1px",
          }}
        />
        <h2>2018</h2>
        
          <p>22 West Radio hosts IBS Radio Regional Conference</p>
          <img
            src="/image/History/ICBConference.jpg"
            alt="IBS Radio Regional Conference"
            onClick={() => openModal("/image/History/ICBConference.jpg")}
          />
           <p>Old Union Weekly Newspaper stands are rebranded as 22 West Magazine</p>
                    <img src="/image/History/LBUnionWeekly.jpg" alt="Old Union Weekly Newspaper" onClick={() => openModal("/image/History/LBUnionWeekly.jpg")} />
             <img src="/image/History/22WestBox.jpg" alt="22WestBox" onClick={() => openModal("/image/History/22WestBox.jpg")} />

       <p>A Night At The Museum - November 26, 2018</p>
                    <img src="/image/History/MNDSGN.png" alt="A Night At The Museum Banner" onClick={() => openModal("/image/History/MNDSGN.png")} />

         <hr
          style={{
            backgroundColor: "lightgrey",
            border: "none",
            height: "1px",
          }}
        />
        <h2>2019</h2>
         <p>
          <b>March 2019</b>
        </p>
        <img
          src="/image/History/BigEvent22West.png"
          alt="A Night at the USU and the Big Event"
          onClick={() => openModal("/image/History/BigEvent22West.png")}
        />
       
         <hr
          style={{
            backgroundColor: "lightgrey",
            border: "none",
            height: "1px",
          }}
        />
      </KbeachAccordionItem>
      <KbeachAccordionItem year="2020s" image={true}>
        <h2>2021</h2>

        <p>
          <b>March 2021</b> - 22 West Media wins big at the IBS Radio Nationals.
        </p>
        <img
          src="/image/History/After_Quarantine.jpg"
          alt="One year after quarantine shutdown"
          onClick={() => openModal("/image/History/After_Quarantine.jpg")}
        />
        <hr
          style={{
            backgroundColor: "lightgrey",
            border: "none",
            height: "1px",
          }}
        />
            <h2>2024</h2>

        <p>
          <b>August 2024</b> - 22 West Video rebrands as 22 West Television.
        </p>
        <hr
          style={{
            backgroundColor: "lightgrey",
            border: "none",
            height: "1px",
          }}
        />
      </KbeachAccordionItem>

  
      {isModalOpen && (
        <div className="modal" onClick={() => setIsModalOpen(false)}>
          <img src={selectedImage} alt="Enlarged Advertisement" />
        </div>
      )}
    </div>
  );
};

export default KBeach;
