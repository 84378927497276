import React, {useEffect} from "react";
import Home from "./pages/Home/Home.jsx";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./pages/About/About.jsx";
import Radio from "./pages/Radio/Radio.jsx";
import Magazines from "./pages/Magazines/Magazines.jsx";
import Video from "./pages/Video/Video.jsx";
import MagazineAll from "./pages/MagazineAll/MagazineAll.jsx";
import MagazineView from "./pages/MagazineView/MagazineView.jsx";
import Navbar from "./components/navbar/Navbar.jsx";
import Footer from "./components/footer/Footer.jsx";
import Page404 from "./pages/Page404/Page404.jsx";
import ReactGA from 'react-ga'
import Advertisement from "./pages/Advertisement/Advertisement.jsx";
import DevTeam from "./pages/DevTeam/DevTeam.jsx";
import History from "./pages/History/History.jsx";
ReactGA.initialize("G-E2H1N8ZPH0");


const App=()=>{

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }, []);
    return(<>
<Router>
        <Navbar/>
      <Routes>
        <Route exact path="/" element={<Home/>}></Route>
        <Route exact path="/radio" element={<Radio/>}></Route>
        <Route exact path="/about" element={<About/>}></Route>
        <Route exact path="/magazine" element={<Magazines/>}></Route>
        <Route exact path="/Television" element={<Video/>}></Route>
        <Route exact path="/advertise" element={<Advertisement/>}></Route>
        <Route exact path="/magazinesearch/:magazineId" element={<MagazineView/>}></Route>
        <Route exact path="/magazinesearch" element={<MagazineAll/>}></Route>
        <Route exact path="/web-team" element={<DevTeam/>}></Route>
        <Route exact path="/history" element={<History/>}></Route>

      <Route path="*" element={<Page404/>}></Route> 
      </Routes>
        <Footer/>
    </Router>
    </>)


}

export default App